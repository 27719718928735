import { useContext } from "react";
import AuthContext from "../../../context/AuthContext";

export default function ManualPayment() {
   const { setModalManualPay } = useContext(AuthContext);

   return (
      <>
         <div
            onClick={() => setModalManualPay(true)}
            className="AsideAdmin_navlink"
            style={{ cursor: "pointer" }}
         >
            Manual Pay
         </div>
      </>
   );
}
