import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Input from "../../atoms/Input/Input";
import Btn_xl from "../../atoms/Btn_xl/Btn_xl";
import "./LoginForm.css";
import LinkAtom from "../../atoms/LinkAtom/LinkAtom";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import AuthContext from "../../../context/AuthContext";

export default function LoginForm() {
  const [errorResponse, setErrorResponse] = useState(undefined);

  const { loginUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    setErrorResponse(undefined);
    try {
      await loginUser(data);
      navigate("/dashboard");
    } catch (error) {
      if (error.response.status === 400) {
        console.log(error.response.data);
        setErrorResponse(error.response.data.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <input type="text" {...register("example", { required: "raro12" })} />
      <input
        type="password"
        {...register("example2", { required: "raro12" })}
      /> */}

      <Input
        register={register}
        label="Email"
        placeholder="Email"
        type="email"
        name="email"
        rules={{
          required: "Email is required",
          pattern: {
            value:
            /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/ ,
            message: "Enter a valid email",
          },
        }}
        error={errors.email?.message}
      />
      <Input
        label="Password"
        placeholder="Password"
        name="password"
        type="password"
        register={register}
        rules={{
          required: "password is required",
        }}
        error={errors.password?.message}
      />
      <LinkAtom className="login--forgot" to={"/ForgotPassword"}>
        Forgot Password
      </LinkAtom>
      {errorResponse && (
        <p className="h-center red-text auth-margin">{errorResponse}</p>
      )}
      {isSubmitting && (
        <div className="h-center">
          <CustomSpinner />
        </div>
      )}
      <Btn_xl className="login--btn" type="submit">
        Login
      </Btn_xl>

      <LinkAtom className="signup--btn" to={"/signup"}>
        Sign Up
      </LinkAtom>
      {/* </div> */}
    </form>
  );
}
