import "./CustomSpinner.css";

const CustomSpinner = () => {
   return (
      <div className="spinner--wrapper">
         <div className="circle"></div>
         <div className="circle"></div>
         <div className="circle"></div>
         <div className="shadow"></div>
         <div className="shadow"></div>
         <div className="shadow"></div>
      </div>
   );
};

export default CustomSpinner;
