import React, { useContext, useState } from "react";
import Button from "../atoms/Btn_xl/Button";
import { useForm } from "react-hook-form";
import AuthContext from "../../context/AuthContext";

export default function StepFourNewLeague({ setstepCurrent, leagueSelected }) {
  const { user } = useContext(AuthContext);

  const [typeSelected, settypeSelected] = useState();
  const arr = [{ id: "1", type: "Conference", price: "25" }];
  const {
    register,

    formState: { errors },
  } = useForm();

  return (
    <>
      <form
        action={`${process.env.REACT_APP_API_BASE_URL}shared/stripe/create-checkout-session/${leagueSelected?.name}/${user.user_id}/`}
        method="POST"
      >
        <div className="mt-5">
          <div className="header_form_new">
            <div className="step_number">
              <span>4</span>
            </div>
            <div className="step_text">
              <p>– Pricing –</p>
            </div>
          </div>

          <div className="m-5">
            <div className="row g-4">
              {arr.map((c) => (
                <div key={c.id} className="col-md-6">
                  <div
                    className={
                      typeSelected === c.id
                        ? "card_price_league_selected"
                        : "card_price_league "
                    }
                  >
                    <label htmlFor className="w-100 m-0 p-0 ">
                      <input
                        {...register("mode_league", {
                          required: "select a way to play",
                        })}
                        type="radio"
                        name="mode_league"
                        value={c.id}
                        hidden
                        onClick={(e) => {
                          settypeSelected(e.target.value);
                        }}
                      />
                      <p className="card_title_league">{c.type}</p>
                      <p className="">${c.price}.00/season</p>
                      <div style={{ fontSize: "10px" }}>
                        <p className="m-0">
                          + Higher chances of getting an auto bid to qualify for
                          CFL Playoffs.
                        </p>

                        <p className="m-0">
                          + Higher chances of playing in a bowl game.
                        </p>
                        <p className="m-0">
                          + Higher chances of earning the grand prize.
                        </p>
                      </div>
                    </label>
                  </div>
                </div>
              ))}
              <p className="red-text text-center">
                {errors.mode_league?.message}
              </p>
            </div>
          </div>

          <p className="notes_step">
            We will walk you through the process of placing bets on your
            favorite sports league. Please read each step’s instructions
            carefully. Here is the general information of the available and
            upcoming games, go to view more and see the calendar for detailed
            information. * The sponsor fee will depend on if they want to
            participate in a bowl and it can vary from season to season.
          </p>
          <div className="footer_form_new">
            <Button
              onClick={() => {
                setstepCurrent(3);
              }}
            >
              Back «
            </Button>

            <Button
              type={"submit"}
              onClick={() => {
                setstepCurrent(4);
              }}
            >
              Next »
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
