import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import "./AuthLayout.css";
import { useNavigate } from "react-router-dom";

const AuthLayout = ({ children, img, title }) => {
   const navigate = useNavigate();
   return (
      <main className="auth--container">
         <section className="auth--subcontainer">
            <img className="auth--img" src={img} />
         </section>
         <section className="auth--form">
            <div className="auth--back">
               <i
                  className="fa-solid fa-arrow-left auth--arrow"
                  onClick={() => {
                     navigate("/");
                  }}
               ></i>
               <PrimaryTitle>{title}</PrimaryTitle>
            </div>
            {children}
         </section>
      </main>
   );
};

export default AuthLayout;
