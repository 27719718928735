import React from "react";
import HeroKFL from "../organisms/HeroKFL/HeroKFL";
import InfoTableKFL from "../organisms/InfoTableKFL/InfoTableKFL";

const KFLHome = () => {
   return (
      <>
         <HeroKFL />
         <InfoTableKFL />
      </>
   );
};

export default KFLHome;
