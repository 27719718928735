import React from "react";
import QuaternaryTitle from "../../atoms/QuaternaryTitle/QuaternaryTitle";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import "./AdminUserCar.css";
import TertiarySubtitle from "../../atoms/TertiarySubtitle/TertiarySubtitle";

const AdminUserCard = () => {
   return (
      <>
         <DefaultCard className={"AdminUserCard_Card"}>
            <div className="AdminUserCard_UserInfo">
               <QuaternaryTitle className={"AdminUserCard_h4"}>
                  Admin
               </QuaternaryTitle>
            </div>
            <div className="AdminUserCard_Membership">
               <TertiarySubtitle className={"AdminUserCard_TertiarySubtitle"}>
                  Admin Panel
               </TertiarySubtitle>
            </div>
         </DefaultCard>
      </>
   );
};

export default AdminUserCard;
