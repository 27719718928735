import React from "react";

import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import SecondaryTitle from "../../atoms/SecondaryTitle/SecondaryTitle";
import TertiaryTitle from "../../atoms/TertiaryTitle/TertiaryTitle";
import Pharagraph from "../../atoms/Paragraph/Paragraph";
import SecondarySubTitle from "../../atoms/SecondarySubtitle/SecondarySubtitle"

export const TermsAndConditions = () => {
  return (
    <>
      <PrimaryTitle>Terms & Conditions – Sports World Services</PrimaryTitle>
      <SecondarySubTitle>Effective date: August 10, 2022</SecondarySubTitle>
      <SecondaryTitle>In General</SecondaryTitle>
      <Pharagraph>
        <strong>Asti Enterprises (<a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a>)</strong> owns and operates this Website. This document governs your relationship with <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> (“Website”). Access to and use of this Website and the products and services available through this Website (collectively, the "<strong>Terms And Conditions</strong>") are subject to the following terms, conditions, and notices (the "<strong>Terms And Conditions</strong>"). By using these services, you are agreeing to all the <strong>Terms And Conditions</strong>, as may be updated by us from time to time. You should check this page regularly to take notice of any changes we may have made to the <strong>Terms And Conditions</strong>.
      </Pharagraph>

      <Pharagraph>
        Access to this Website is permitted on a temporary basis, and we reserve the right to withdraw or amend the services without notice. We will not be liable if for any reason this Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts or all of this Website.
      </Pharagraph>

      <Pharagraph>
        This Website may contain links to other websites (the "<strong>Linked Sites</strong>"), which are not operated by <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a>. <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> has no control over the Linked Sites and accepts no responsibility for them or for any loss or damage that may arise from your use of them. Your use of the Linked Sites will be subject to the terms of use and service contained within each such site.
      </Pharagraph>
      <PrimaryTitle>Privacy Policy</PrimaryTitle>
      <Pharagraph>Our privacy policy, which sets out how we will use your information, can be found at <a href="https://sportsworldservices.com/PrivacyPolicy">https://sportsworldservices.com/PrivacyPolicy</a>. By using this Website, you consent to the processing described therein and warrant that all data provided by you is accurate.</Pharagraph>

      <SecondaryTitle>Prohibitions</SecondaryTitle>
      <Pharagraph>You must not misuse this Website. You will not:</Pharagraph>
      <ul>
        <li>commit or encourage a criminal offense;</li>
        <li>transmit or distribute a virus, trojan, worm, logic bomb, or any other material which is malicious, technologically harmful, in breach of confidence, or in any way offensive or obscene;</li>
        <li>hack into any aspect of the service;</li>
        <li>corrupt data;</li>
        <li>cause annoyance to other users;</li>
        <li>infringe upon the rights of any other person's proprietary rights;</li>
        <li>send any unsolicited advertising or promotional material, commonly referred to as "spam" or “junk”;</li>
        <li>attempt to affect the performance or functionality of any computer facilities of or accessed through this Website.</li>
      </ul>
      <Pharagraph>
        Breaching this provision would constitute a criminal offense and <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> will report any such breach to the relevant law enforcement authorities and disclose your identity to them.
      </Pharagraph>

      <Pharagraph>
        We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses, or other technologically harmful material that may infect your computer equipment, computer programs, data, or other proprietary material due to your use of this Website or to your downloading of any material posted on it, or on any website linked to it.
      </Pharagraph>
      <SecondaryTitle>Intellectual Property, Software, and Content</SecondaryTitle>
      <Pharagraph>The intellectual property rights in all software and content (including photographic images and videos) made available to you on or through this Website remains the property of <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> or its licensors and are protected by copyright laws and treaties around the world. All such rights are reserved by <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> and its licensors. You may print and display the content supplied solely for your own personal use. You are not permitted to publish, manipulate, distribute, or otherwise reproduce, in any format, any of the content or copies of the content supplied to you or which appears on this Website, nor may you use any such content in connection with any business or commercial enterprise without permission.</Pharagraph>

      <SecondaryTitle>Terms of Sale</SecondaryTitle>
      <Pharagraph>
        When you sign up for any contests, challenges, leagues, or events on the <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> website, you are offering to purchase a product on and subject to the following terms and conditions. All sign ups are subject to availability and confirmation of the order price.
      </Pharagraph>
      <Pharagraph>
        Dispatch times may vary according to availability and subject to any delays resulting from postal delays, technology issues, or force majeure for which we will not be responsible.
      </Pharagraph>
      <Pharagraph>
        To contract with <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> you must be over 18 years of age and possess a valid credit or debit card issued by a bank acceptable to us. Payments can still be made from PayPal, Venmo, and CashApp accounts. <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> retains the right to refuse any request made by you. If your payment is accepted, we will inform you by email and we will confirm the identity of the party which you have contracted with. This will usually be <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> or may in some cases be a third party. Where a contract is made with a third party <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> is not acting as either agent or principal and the contract is made between yourself and that third party and will be subject to the terms of sale which they supply you. When signing up, you undertake that all details you provide to us are true and accurate, that you are an authorized user of the credit or debit card used to place your payment and that there are sufficient funds to cover the cost of the goods. The cost of foreign products and services may fluctuate. All prices advertised are subject to such changes. If you are not paying in US dollars, then you may be subject to pay conversion fees due to using a different currency.
      </Pharagraph>
      <TertiaryTitle>(a) Our Contract</TertiaryTitle>
      <Pharagraph>When you sign up, you will receive an acknowledgement e-mail confirming receipt of your payment: this email will only be an acknowledgement and will not constitute acceptance of your sign up. A contract between us will not be formed until we send you confirmation by e-mail that the goods which you ordered have been dispatched to you. Only those goods listed in the confirmation e-mail sent at the time of dispatch will be included in the contract formed.</Pharagraph>

      <TertiaryTitle>(b) Pricing and Availability</TertiaryTitle>
      <Pharagraph>Whilst we try and ensure that all details, descriptions, and prices which appear on this Website are accurate, errors may occur. If we discover an error in the price of any fees which you have paid, we will inform you of this as soon as possible and give you the option of reconfirming your payment at the correct price or cancelling it. If we are unable to contact you, we will treat the payment as cancelled. If you cancel and you have already paid for the fees, you will receive a full refund.</Pharagraph>
      <Pharagraph>Delivery costs will be charged in addition; such additional charges are clearly displayed where applicable and included in the 'Total Cost'.</Pharagraph>

      <TertiaryTitle>(c) Payment</TertiaryTitle>
      <Pharagraph>When you sign up, we carry out a standard authorization check on your payment card to ensure there are sufficient funds to fulfil the transaction. Your card will be debited upon authorization being received. The monies received upon the debiting of your card shall be treated as a deposit against the value of the fees you wish to purchase. Once the fees have been dispatched and you have been sent a confirmation email, the monies paid as a deposit shall be used as consideration for the value of fees you have purchased as listed in the confirmation email.</Pharagraph>
      <SecondaryTitle>Disclaimer of Liability</SecondaryTitle>
      <Pharagraph>The material displayed on this Website is provided without any guarantees, conditions, or warranties as to its accuracy. Unless expressly stated to the contrary to the fullest extent permitted by law <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> and its suppliers, content providers and advertisers hereby expressly exclude all conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity and shall not be liable for any damages whatsoever, including but without limitation to any direct, indirect, special, consequential, punitive or incidental damages, or damages for loss of use, profits, data or other intangibles, damage to goodwill or reputation, or the cost of procurement of substitute goods and services, arising out of or related to the use, inability to use, performance or failures of this Website or the Linked Sites and any materials posted thereon, irrespective of whether such damages were foreseeable or arise in contract, tort, equity, restitution, by statute, at common law or otherwise. This does not affect <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a>'s liability for death or personal injury arising from its negligence, fraudulent misrepresentation, misrepresentation as to a fundamental matter or any other liability which cannot be excluded or limited under applicable law.</Pharagraph>

      <SecondaryTitle>Linking to this Website</SecondaryTitle>
      <Pharagraph>You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part where none exists. You must not establish a link from any website that is not owned by you. This Website must not be framed on any other site, nor may you create a link to any part of this Website other than the home page. We reserve the right to withdraw linking permission without notice.</Pharagraph>

      <SecondaryTitle>Disclaimer as to ownership of trademarks, images of personalities and third-party copyright</SecondaryTitle>
      <Pharagraph>Except where expressly stated to the contrary, all persons (including their names and images), third party trademarks and content, services and/or locations featured on this Website are in no way associated, linked, or affiliated with <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> and you should not rely on the existence of such a connection or affiliation. Any trademarks/names featured on this Website are owned by the respective trademark owners. Where a trademark or brand name is referred to, it is used solely to describe or identify the products and services and is in no way an assertion that such products or services are endorsed by or connected to <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a>.</Pharagraph>

      <SecondaryTitle>Indemnity</SecondaryTitle>
      <Pharagraph>You agree to indemnify, defend, and hold harmless <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a>, its directors, officers, employees, contractors, consultants, agents, and affiliates, from any and all third-party claims, liability, damages and/or costs (including, but not limited to, legal fees) arising from your use of this Website or your breach of the Terms of Service.</Pharagraph>

      <SecondaryTitle>Variation</SecondaryTitle>
      <Pharagraph><a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> shall have the right in its absolute discretion at any time and without notice to amend, remove, or vary the services and/or any page of this Website.</Pharagraph>

      <SecondaryTitle>Invalidity</SecondaryTitle>
      <Pharagraph>If any part of the Terms And Conditions service is unenforceable (including any provision in which we exclude our liability to you) the enforceability of any other part of the Terms And Conditions will not be affected, all other clauses remaining in full force and effect. So far as possible where any clause/sub-clause or part of a clause/sub-clause can be severed to render the remaining part valid, the clause shall be interpreted accordingly. Alternatively, you agree that the clause shall be rectified and interpreted in such a way that closely resembles the original meaning of the clause/sub-clause as is permitted by law.</Pharagraph>

      <SecondaryTitle>Complaints</SecondaryTitle>
      <p>We operate a complaint handling procedure which we will use to try to resolve disputes when they first arise, please let us know if you have any complaints or comments.</p>

      <SecondaryTitle>Waiver</SecondaryTitle>
      <Pharagraph>If you breach these conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these conditions.</Pharagraph>

      <SecondaryTitle>Live Updates</SecondaryTitle>
      <Pharagraph>The host will do the best to his ability to provide “Live Updates” on very short notice. If in the event games are going to be finalized late (on the Eastern Daylight/Standard Time Zone) at night or the host is having, not limited to, technology issues, not present at the office, and/or emergencies, then the host will update those games and standings (if applicable) at some point in the following morning. If the host is not able to update the following morning, then he will update in the earliest convenience possible.</Pharagraph>

      <SecondaryTitle>Entire Agreement</SecondaryTitle>
      <Pharagraph>The above Terms And Conditions constitute the entire agreement of the parties and supersede all preceding and contemporaneous agreements between you and <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a>. Any waiver of any provision of the Terms of Service will be effective only if in writing and signed by a director of <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a>.</Pharagraph>

      <SecondaryTitle>Terms Of Sign Ups And Prizes</SecondaryTitle>
      <Pharagraph>The host is Scott Asti, owner and sole proprietor of Asti Enterprises, headquartered at 249 North Saint Marys Street, Saint Marys, PA 15857.</Pharagraph>
      <Pharagraph>Any competition is open to residents of the United States Of America, Canada, and any other countries where engaging in gambling activities are legal. Must be at least 18 years of age to enter any contests and events held on <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> website. There is no entry fee and no purchase necessary to enter any competition.</Pharagraph>
      <Pharagraph>By entering any competition, an entrant (or player) is indicating his/her agreement to be bound by these terms and conditions.</Pharagraph>
      <Pharagraph>Route to entry for the competition and details of how to enter are via <a href="https://sportsworldservices.com/Rulebooks">https://sportsworldservices.com/Rulebooks</a>.</Pharagraph>
      <Pharagraph>For any Pick ‘em Leagues only one (1) entry will be accepted per person. There are no limits on the number of pick ‘em leagues any player can participate. No more than two (2) entries for any bracket challenges and bowl challenges. Any person can purchase an unlimited number of blocks in any.</Pharagraph>
      <h2>Square events or other contests</h2>
      <p>Any person who fails to comply with this rule will result in disqualification, and any monies will not be refunded if the deadline has passed.</p>

      <SecondaryTitle>Closing date for entry</SecondaryTitle>
      <Pharagraph>The closing date for entry will be determined and set in writing on the “Home” page of <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a>. Once the deadline passes, no further entries to the competition will be permitted.</Pharagraph>

      <Pharagraph>No responsibility can be accepted for entries not received for whatever reason.</Pharagraph>

      <Pharagraph>To view the rules of the competition and/or leagues, please go to the “Rulebooks” page of the Website. The rules for the leagues cannot be changed at any time during its respective season. However, you should check the “Rulebooks” page regularly to take notice of any changes we may have made for the upcoming season.</Pharagraph>

      <Pharagraph>The host reserves the right to cancel or amend the competition and these terms and conditions without notice in the event of a catastrophe, war, civil or military disturbance, act of God, or any actual or anticipated breach of any applicable law or regulation or any other event outside of the host’s control. Any changes to the competition will be notified to entrants (or players) as soon as possible by the host.</Pharagraph>

      <Pharagraph>The host is not responsible for inaccurate prize details supplied to any entrant (or players) by any third party connected with this competition.</Pharagraph>

      <SecondaryTitle>The prize(s)</SecondaryTitle>
      <Pharagraph>The prize(s) is/are as stated, in league “Rulebooks” listed under the Payouts category and for any Challenges and Squares it will be listed on the Leaderboard page of the Live Updates Excel file. No cash or other alternatives will be offered. The prizes are not transferable. Prizes are subject to availability, and we reserve the right to substitute any prize with another of equivalent value without giving notice.</Pharagraph>

      <Pharagraph>Winners will be determined as set forth in the rulebooks for leagues, scoring system for challenges, and correct final scores for games, innings, and total scores, and not limited to, for squares.</Pharagraph>

      <Pharagraph>The winner will be notified by email and/or cellphone within 48 hours of the closing date of a season or event. If the winner cannot be contacted or do not claim the prize within 14 days of notification, we reserve the right to withdraw the prize from the winner and pick a replacement winner in which would be the runner up (or 2nd place) in any contests.</Pharagraph>

      <Pharagraph>The host will notify the winner(s) when and where the prize(s) can be paid. There will be no cash transactions. The host must prove, electronically, in accordance with the IRS where the prize(s) are being paid out. The prizes can be paid to, and not limited to, PayPal, Venmo, CashApp, and/or checking or savings accounts (bank route number and bank account number will be required for checking and savings accounts).</Pharagraph>

      <Pharagraph>Any prizes at $601 or over (US Dollars) will be issued an IRS (Internal Revenue Service) 1099 Form by Asti Enterprises and anyone who wins the said prize will be required and responsible to report on his/her taxes in the year they won the prize(s).</Pharagraph>

      <Pharagraph>The host’s decision in respect of all matters to do with the competition will be final and no correspondence will be entered into.</Pharagraph>

      <Pharagraph>By entering this competition, an entrant (or player) is indicating his/her agreement to be bound by these terms and conditions.</Pharagraph>

      <Pharagraph>The competition and these terms and conditions will be governed by United States law and any disputes will be subject to the exclusive jurisdiction of the courts of United States.</Pharagraph>

      <Pharagraph>The winner agrees to the use of his/her name and/or image in any publicity material, as well as their entry. Any personal data relating to the winner(s), or any other entrants (or players) will be used solely in accordance with current United States Of America data protection legislation and will not be disclosed to a third party without the entrant’s (or player’s) prior consent.</Pharagraph>

      <Pharagraph>The winner’s name will be available 7 days after the closing date on the “Website”.</Pharagraph>

      <Pharagraph>Entry into any competition will be deemed as acceptance of these terms and conditions.</Pharagraph>

      <Pharagraph>This promotion is in no way sponsored, endorsed or administered by, or associated with, Facebook, Twitter or any other Social Network. You are providing your information to <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> and not to any other party. The information provided will be used in conjunction with the following Privacy Policy found at <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a>.</Pharagraph>

      <SecondaryTitle>Additional Terms and Conditions:</SecondaryTitle>
      <ul>
        <li><a href="https://www.sportsworldservices.com/">Asti Enterprises</a> will judge the competition and decide on the winner(s) each week.</li>
        <li><a href="https://www.sportsworldservices.com/">Asti Enterprises</a>’ decision as to those able to take part and selection of winners is final. No correspondence relating to the competition will be entered into.</li>
        <li><a href="https://www.sportsworldservices.com/">Asti Enterprises</a> shall have the right, at its sole discretion and at any time, to change or modify these terms</li>
      </ul>
    </>
  )
}