import Upgames from "../../molecules/Upgames/Upgames";
import "./Upgames_Section.css";

const Upgames_Section = () => {
   return (
      <section className="upgames--section h-center">
         <Upgames title="Thu" subtitle="Aug 24" sport="cfl" />
         <Upgames title="Thu" subtitle="Aug 24" sport="cfl" />
         <Upgames title="Thu" subtitle="Aug 24" sport="cfl" />
         <Upgames title="Thu" subtitle="Aug 24" sport="cfl" />
         <Upgames title="Thu" subtitle="Aug 24" sport="cfl" />
      </section>
   );
};

export default Upgames_Section;
