import React, { useContext, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { useState } from "react";
import { getLeagueActivesByUserService } from "../../services/user.services";
import { getAllLeagueService } from "../../services/league.services";
import CustomSpinner from "../atoms/CustomSpinner/CustomSpinner";
import { toast } from "react-hot-toast";

export default function CFLProtectedRouteRouter({ children, ...rest }) {
  const { user } = useContext(AuthContext);

  const [isLoading, setisLoading] = useState(true);
  const [isPayLeague, setIsPayLeague] = useState(undefined);
  const { idLeague } = useParams();

  useEffect(() => {
    console.log(idLeague);
    validaPAgo(idLeague);
  }, [idLeague, user]);

  const validaPAgo = async (id) => {
    setisLoading(true);
    try {
    if( !user){
        setisLoading(false);
        setIsPayLeague(false);
    }
      const re = await getLeagueActivesByUserService(user.user_id);
      const result2 = await getAllLeagueService();
      const ligas = await result2.data;
      var ligasActivas = await re.data;
      if (ligasActivas[0].message) {
        setisLoading(false);
        setIsPayLeague(false);
        toast.error(`error: you have not paid for this league.`);
      }
      ligasActivas = ligasActivas.map((l) => {
        return { ...l, league: ligas.find((le) => (le.name === l.league)) };
      });
      const arr = ligasActivas.map((c) => c.league.id === parseInt(id));
      if (arr.includes(true)) {
        setisLoading(false);
        setIsPayLeague(true);
      } else {
        setisLoading(false);
        setIsPayLeague(false);
        toast.error(`error: you have not paid for this league. 222`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return (
      <div className="overlay">
        <CustomSpinner />
      </div>
    );
  }
  if (isPayLeague === false) {
    return <Navigate to="/dashboard" replace />;
  }
  return children;
}
