import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../atoms/Logo/Logo";
import { NavIcons } from "../../molecules/Nav_Icons";
import { Nav_Link } from "../../atoms/Nav_Link/Nav_Link";
import "./Nav.css";
import Nav_Btns from "../../molecules/Nav_Btns/Nav_Btns";

export default function Navbar() {
   const [open, setOpen] = useState(false);

   return (
      <nav className="Navbar">
         <div
            className={`nav--social ${
               open ? "slide-in-top " : "slide-out-top"
            }`}
         >
            {/* <NavIcons /> */}
            <Nav_Link to="/home">Home</Nav_Link>
            <Nav_Link to="/about">About</Nav_Link>
            <Nav_Link to="/Contact">Contact</Nav_Link>
         </div>

         <Link to={"/Home"} className="nav--logo">
            <Logo />
         </Link>

         <div className="nav--menu">
            <Nav_Btns />
         </div>
         <div className="nav--icoContainer" onClick={() => setOpen(!open)}>
            <div className={`nav--ico ${open ? "pulsate-fwd" : ""}`} />
         </div>
      </nav>
   );
}
