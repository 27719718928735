import React, { useEffect, useState } from "react";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import Overline from "../../atoms/Overline/Overline";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import SixthTitle from "../../atoms/SixthTitle/SixthTitle";
import "./RankingsPage.css";
import { getTotalScoresCFLService } from "../../../services/user.services";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";

export default function RankingsPageKFL() {
   const [RakingUser, setRakingUser] = useState(undefined);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      getTotalScores();
   }, []);

   const getTotalScores = async () => {
      try {
         setIsLoading(true);
         const result = await getTotalScoresCFLService();
         console.log(result.data);
         setRakingUser(result.data);
         setIsLoading(false);
      } catch (error) {}
   };

   return (
      <>
         <div className={`banner_header_dasboard_cfl`}>
            {/* <img src={iomg} alt="" className="img_banner_dasboard" /> */}
            <div className="banner_text_dasboard">
               <PrimaryTitle>Ranking</PrimaryTitle>
               <Overline className={"m-0"}>total weeks: 0</Overline>
               <Overline className={"text-green"}>REGULAR</Overline>

               <DefaultCard className={"p-2"}>
                  <SixthTitle className={"m-0"}>Overall </SixthTitle>
               </DefaultCard>
            </div>
         </div>

         <div className={`section_LEADERBOARD`}>
            <div className="container_LEADERBOARD">
               <PrimaryTitle>LEADERBOARD</PrimaryTitle>

               <div className="row g-4 mt-4">
                  {isLoading && (
                     <div className="h-center">
                        <CustomSpinner></CustomSpinner>
                     </div>
                  )}
                  {RakingUser &&
                     Array.isArray(RakingUser) &&
                     RakingUser.length === 0 && (
                        <Overline> No users in the ranking yet.</Overline>
                     )}
                  {RakingUser &&
                     RakingUser.map((c) => (
                        <div className="col-md-6">
                           <DefaultCard className={"px-4 py-3"}>
                              <SixthTitle className={"m-0 text-center"}>
                                 {`${c.first_name} ${c.last_name}`}
                              </SixthTitle>
                              <div className="row mx-2 text-center">
                                 <div className="col-md-4">
                                    <Overline className={"m-0 text-green"}>
                                       {c.total_points}
                                    </Overline>
                                    <Overline className={"m-0 text-green"}>
                                       {" "}
                                       TOTAL
                                    </Overline>
                                 </div>

                                 <div className="col-md-4">
                                    <Overline className={"m-0"}>
                                       {c.total_wins}
                                    </Overline>
                                    <Overline className={"m-0"}>Wins</Overline>
                                 </div>

                                 <div className="col-md-4">
                                    <Overline className={"m-0"}>
                                       {c.total_losses}
                                    </Overline>
                                    <Overline className={"m-0"}>
                                       Losses
                                    </Overline>
                                 </div>
                              </div>
                              <hr className="border w-50 mx-auto"></hr>
                              <div className="row mx-2 text-start">
                                 <div className="col-6">
                                    <div className="av_pick_team ">
                                       <Overline className={"m-0"}>
                                          {c.division_losses}
                                       </Overline>
                                       <Overline className={"m-0"}>
                                          Division losses
                                       </Overline>
                                    </div>
                                 </div>

                                 <div className="col-6">
                                    <div className="av_pick_team ">
                                       <Overline className={"m-0"}>
                                          {c.division_wins}
                                       </Overline>
                                       <Overline className={"m-0"}>
                                          Division Wins
                                       </Overline>
                                    </div>
                                 </div>
                              </div>
                           </DefaultCard>
                        </div>
                     ))}
               </div>
            </div>
         </div>
      </>
   );
}
