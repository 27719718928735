import React, { Suspense, useContext, useState } from "react";
import "./CFLDashBoard.css";
import Aside from "../../organisms/Aside/Aside";
import { Route, Routes } from "react-router-dom";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import CflHome from "../../pages/CFLHome";
import TeamsPage from "../../pages/TeamsPage/TeamsPage";
import RankingsPage from "../../pages/RankingsPage/RankingsPage";
import { Toaster } from "react-hot-toast";
import History from "../../pages/History/History";
import RulesCFL from "../../organisms/RulesCFL/RulesCFL";
import AuthContext from "../../../context/AuthContext";
import Modal from "../../atoms/Modal/Modal";
import MatchUpDetail from "../../molecules/MatchUpDetail/MatchUpDetail";
import StandingsCFL from "../../pages/StandingsCFL/StandingsCFL";
import { getMatchUpDetailCFLService } from "../../../services/matches.services";

export default function CFLDashboard() {
   const { modalManualPay, setModalManualPay, IdMatch } =
      useContext(AuthContext);
   const [openedAside, setOpenedAside] = useState(true);
   const handleData = (dataFromChild) => {
      setOpenedAside(dataFromChild);
      console.log(openedAside);
   };

   return (
      <>
         <div className="CFL">
            <Modal show={modalManualPay} setShow={setModalManualPay}>
               <MatchUpDetail id={IdMatch && IdMatch}  getMatchUp={getMatchUpDetailCFLService}/>
            </Modal>
            <Aside division={"division"} sendDataToParent={handleData} />
            <div
               className={`CFL-content default-margin ${
                  openedAside ? "opened-aside" : "closed-aside"
               }`}
            >
               <Suspense fallback={<CustomSpinner />}>
                  <Routes>
                     <Route
                        path={"/"}
                        exact={true}
                        name={"Home"}
                        element={<CflHome />}
                     />

                     <Route
                        path={"/Rankings"}
                        name={"Rankings"}
                        element={<RankingsPage />}
                     />
                     <Route
                        path={"/History"}
                        exact={true}
                        name={"History"}
                        element={<History />}
                     />
                     <Route
                        path={"/Teams"}
                        exact={true}
                        name={"Teams"}
                        element={<TeamsPage />}
                     />
                     <Route
                        path={"/Rules"}
                        exact={true}
                        name={"Rules"}
                        element={<RulesCFL />}
                     />
                     <Route
                        path={"/Standings"}
                        exact={true}
                        name={"Rules"}
                        element={<StandingsCFL />}
                     />
                     <Route
                        path={"/General"}
                        exact={true}
                        name={"General"}
                        element={<p>Rules Page</p>}
                     />
                  </Routes>
               </Suspense>
            </div>
            <Toaster
               toastOptions={{
                  className: "",
                  style: {
                     border: "1px solid #008285",
                     padding: "16px",
                     backgroundColor: "var(--bg-color)",
                     color: "var(--global-font",
                  },
               }}
               position="top-center"
               reverseOrder={false}
            />
         </div>
      </>
   );
}
