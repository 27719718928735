import React from "react";
/* import "./InfoPanelCFL.css"; */

import WeeksKFL from "../WeeksKFL/WeeksKFL";
import TeamsKFL from "../../molecules/TeamsKFL/TeamsKFL";
import GamesKFLList from "../GamesKFLList/GamesKFLList";
import SeasonKFL from "../../molecules/SeasonKFL/SeasonKFL";
import CoferenceKFL from "../../molecules/CoferenceKFL/CoferenceKFL";

const InfoPanelKFL = () => {
  return (
    <div className="InfoPanelCFL">
      <div className="infoPanelCFL_Col">
        {/* <SeasonSetForm /> */}
        <SeasonKFL />
        <WeeksKFL />
        {/* <ConferenceForm /> */}
        <CoferenceKFL /> 
      </div>
      <div className="infoPanelCFL_Col">
        {/* <PlayersMatchesCFL /> */}
        <TeamsKFL />
      </div>
      <div className="infoPanelCFL_Col">
        <GamesKFLList />
      </div>
    </div>
  );
};

export default InfoPanelKFL;
