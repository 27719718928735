import React, { useState } from "react";
import "./FormNewPackLeague.css";
import StepOneNewLeague from "../../molecules/StepOneNewLeague";
import StepTwoNewLeague from "../../molecules/StepTwoNewLeague";
import StepThreeNewLeague from "../../molecules/StepThreeNewLeague";
import StepFourNewLeague from "../../molecules/StepFourNewLeague";

export default function FormNewPackLeague() {
  const [leagueSelected, setLeagueSelected] = useState(undefined);

  const [stepCurrent, setstepCurrent] = useState(1);
  switch (stepCurrent) {
    case 1:
      return (
        <>
          <StepOneNewLeague setstepCurrent={setstepCurrent} />
        </>
      );
    case 2:
      return (
        <>
          <StepTwoNewLeague
            setLeagueSelected={setLeagueSelected}
            leagueSelected={leagueSelected}
            setstepCurrent={setstepCurrent}
          />
        </>
      );

    case 3:
      return (
        <>
          <StepThreeNewLeague setstepCurrent={setstepCurrent} />
        </>
      );
    case 4:
      return (
        <>
          <StepFourNewLeague 
            leagueSelected={leagueSelected}
            setstepCurrent={setstepCurrent} />
        </>
      );
    default:
      return (
        <>
          <StepOneNewLeague setstepCurrent={setstepCurrent} />
        </>
      );
  }
}
