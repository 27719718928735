import React, { Suspense, useContext, useState } from "react";
import "./AdminPanel.css";
import { Route, Routes } from "react-router-dom";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import AsideAdmin from "../../organisms/AsideAdmin/AsideAdmin";
import CFLPanel from "../../pages/CFLPanel/CFLPanel.jsx";
import { Toaster } from "react-hot-toast";
import AuthContext from "../../../context/AuthContext";
import Modal from "../../atoms/Modal/Modal";
import FormManualPayment from "../../molecules/Manual Payment/FormManualPayment";
import KFLPanel from "../../pages/KFLPanel/KFLPanel";

export default function AdminPanel() {
   const { modalManualPay, setModalManualPay } = useContext(AuthContext);
   const [openedAside, setOpenedAside] = useState(true);
   const handleData = (dataFromChild) => {
      setOpenedAside(dataFromChild);
      console.log(openedAside);
   };

   return (
      <>
         <div className="CFL">
            <AsideAdmin sendDataToParent={handleData} />
            <div
               className={`CFL-content--admin default-margin--admin ${
                  openedAside ? "opened-aside" : "closed-aside"
               }`}
            >
               <Modal show={modalManualPay} setShow={setModalManualPay}>
                  <FormManualPayment setModal={setModalManualPay} />
               </Modal>
               <Suspense fallback={<CustomSpinner />}>
                  <Routes>
                     <Route
                        path={"/CFL"}
                        exact={true}
                        name={"CFL"}
                        element={<CFLPanel />}
                     />

                     <Route
                        path={"/KFL"}
                        exact={true}
                        name={"KFL"}
                        element={<KFLPanel />}
                     />
                  </Routes>
               </Suspense>
            </div>
         </div>
         <Toaster
            toastOptions={{
               className: "",
               style: {
                  border: "1px solid #008285",
                  padding: "16px",
                  backgroundColor: "var(--bg-color)",
                  color: "var(--global-font",
               },
            }}
            position="top-center"
            reverseOrder={false}
         />
      </>
   );
}
