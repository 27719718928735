import React from "react";
import PasswordResetForm from "../../organisms/PasswordResetForm/PasswordResetForm";
import AuthLayout from "../../templates/AuthLayout/AuthLayout";
import ForgotPasswordForm from "../../organisms/ForgotPasswordForm/ForgotPasswordForm";
import loginImg from "../../../assets/img/login.png";

export default function PasswordResetPage() {
   document.title = "Sports World Services – Reset Password";

   return (
      <>
         <AuthLayout img={loginImg} title="Change Your Password">
            <PasswordResetForm />
         </AuthLayout>
         {/* <div
            className="w-100 d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "#142338" }}
         >
            <div className="w-100">
               <div className="row">
                  <div className="col-md-6">
                     <div className="container__login">
                        <img src={login_img} className="img_login" alt="AS" srcset="" />
                        <div className="img_register"></div>
                     </div>
                  </div>

                  <div className="col-md-6 align-self-center">
                  </div>
               </div>
            </div>
         </div> */}
      </>
   );
}
