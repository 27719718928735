import React, { useContext, useEffect, useState } from "react";
import Button from "../atoms/Btn_xl/Button";
import Accordion from "../atoms/Accordion/Accordion";
import { getAllLeagueService } from "../../services/league.services";
import CustomSpinner from "../atoms/CustomSpinner/CustomSpinner";
import { getAllSportsService } from "../../services/sports.services";
import { toast } from "react-hot-toast";
import { getLeagueActivesByUserService } from "../../services/user.services";
import AuthContext from "../../context/AuthContext";

export default function StepTwoNewLeague({
  setstepCurrent,
  setLeagueSelected,
  leagueSelected,
}) {
  const [leagueAll, setleagueAll] = useState(undefined);

  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    getSportAll();
  }, []);

  const getSportAll = async () => {
    try {
      setIsLoading(true);
      const leages = (await getAllLeagueService()).data;
      const sports = (await getAllSportsService()).data;

      const l = sports.map((s) => {
        return {
          ...s,
          leages: leages.filter((l) => l.sport === s.name),
        };
        /* console.log( leages.filter(  l => l.sport ===s.name)); */
      });
      console.log(l);
      setIsLoading(false);

      setleagueAll(l);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="mt-5">
        <div className="header_form_new">
          <div className="step_number">
            <span>2</span>
          </div>
          <div className="step_text">
            <p> – Choose a League –</p>
          </div>
        </div>

        <div className="w-75 my-4 mx-auto">
          {isLoading && (
            <div className="h-center">
              <CustomSpinner />
            </div>
          )}
          {leagueAll &&
            leagueAll.map((l) => (
              <Accordion
                title={l.name}
                content={l.leages}
                setLeagueSelected={setLeagueSelected}
                leagueSelected={leagueSelected}
              />
            ))}

          {/* <Accordion title={"Baseball"} content={["MLB"]} />
          <Accordion title={"Basketball"} content={["NBA"]} />
          <Accordion title={"Hockey"} />
          <Accordion title={"Soccer"} content={["LaLiga", "Seria A"]} /> */}
        </div>

        <p className="notes_step">
          We will walk you through the process of placing bets on your favorite
          sports league. Please read each step’s instructions carefully. You can
          choose the sport you like and then the league you are interested in.
        </p>

        <div className="footer_form_new">
          <Button
            onClick={() => {
              setstepCurrent(1);
            }}
          >
            Back «
          </Button>

          <Button
            onClick={async () => {
              if (leagueSelected) {
                var ligasActivas = (
                  await getLeagueActivesByUserService(user.user_id)
                ).data;
                if (ligasActivas[0].message) {
                  setstepCurrent(3);
                  return false
                }
                const ligas = (await getAllLeagueService()).data;
                ligasActivas = ligasActivas.map((l) => {
                  return {
                    ...l,
                    league: ligas.find((le) => (le.name === l.league)),
                  };
                });
                const arr = ligasActivas.map(
                  (c) => c.league.id === parseInt(leagueSelected.id)
                );
                if (arr.includes(true)) {
                  toast.error(`You have already paid for the selected league`);
                } else {
                  setstepCurrent(3);
                }
              } else {
                toast.error("Select a leagues");
              }
            }}
          >
            Next »
          </Button>
        </div>
      </div>
    </>
  );
}
