import React from "react";
import loginImg from "../../../assets/img/login.png";
import ForgotPasswordForm from "../../organisms/ForgotPasswordForm/ForgotPasswordForm";
import AuthLayout from "../../templates/AuthLayout/AuthLayout";

export default function ForgotPassword() {
   document.title = "Sports World Services – Forgot Password";
   return (
      <>
         <AuthLayout img={loginImg} title="Forgot Password">
            <ForgotPasswordForm />
         </AuthLayout>
         {/* <div
            className="w-100 d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "#142338" }}
         >
            <div className="w-100">
               <div className="row">
                  <div className="col-md-6">
                     <div className="container__login">
                        <img src={login_img} className="img_login" alt="AS" srcset="" />
                        <div className="img_login"></div>
                     </div>
                  </div>

                  <div className="col-md-6 align-self-center">
                     <ForgotPasswordForm />
                  </div>
               </div>
            </div>
         </div> */}
      </>
   );
}
