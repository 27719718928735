import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import Overline from "../../atoms/Overline/Overline";

export default function ListWeek({ weeks, handleClickGameSeleted }) {
  return (
    <>
      {weeks && Array.isArray(weeks) && weeks.length === 0 && (
        <Overline className={"m-0 fs-6"}> {`NO HAY WEEKS `}</Overline>
      )}
      {weeks &&
        weeks.map((w) => (
          <div className="col-12">
            <DefaultCard className={" p-3 "}>
              <div className="weekCFL_weekCard">
                <div className="weekCFL_statusContent">
                  <Overline className={"m-0 fs-6"}>
                    {`WEEK ${w.week}`}
                  </Overline>
                  <Overline className={"m-0 fs-7"}>
                    {`START : ${w.date_start}`}
                  </Overline>
                  <Overline className={"m-0 fs-7"}>
                    {`END : ${w.date_end}`}
                  </Overline>
                </div>
                <button
                  onClick={() => {
                    handleClickGameSeleted(w);
                  }}
                  style={{
                    width: "2em",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0.4em",
                    margin: "0",
                  }}
                >
                  <i className="fa-regular fa-pen-to-square fa-1x"></i>
                </button>
              </div>
            </DefaultCard>
          </div>
        ))}
    </>
  );
}
