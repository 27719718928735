import axios from "axios";

export const getAllGamesService = async () => {
   return await axios.get("/cfl/games/");
};

export const getGamesOfWeekService = async () => {
   return await axios.get("/cfl/gamesofweek/");
};
export const getGamesOfWeekKFLService = async () => {
   return await axios.get("/kfl/gamesofweek/");
};

export const createCFLGamesService = async (data) => {
   return await axios.post("/cfl/games/", data);
};

export const updateCFLGamesService = async (data) => {
   return await axios.put(`/cfl/games/${data.id}/`, data);
};

export const getLastGameByUserCFLService = async (id, league) => {
   return await axios.get(`/cfl/currentmatchuser/${id}/`);
};

export const getLastGameByUserKFLService = async (id, league) => {
   return await axios.get(`/kfl/currentmatchuser/${id}/`);
};

export const getAllKFLGamesService = async () => {
   return await axios.get("/kfl/games/");
};

export const createKFLGamesService = async (data) => {
   return await axios.post("/kfl/games/", data);
};

export const updateKFLGamesService = async (data) => {
   return await axios.put(`/kfl/games/${data.id}/`, data);
};
