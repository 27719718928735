import { useContext, useState } from "react";
import "./Aside.css";
import AsideUser from "../../molecules/Aside_User/Aside_User";
import AsideLastGame from "../../molecules/Aside_LastGame/Aside_LastGame";
import { NavIcons } from "../../molecules/Nav_Icons";
import { NavLink, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";

export default function Aside({ division, sendDataToParent }) {
   const [isOpen, setOpen] = useState(false);
   const { logoutUser } = useContext(AuthContext);
   const navigate = useNavigate();
   const handleOpen = () => {
      sendDataToParent(isOpen);
      setOpen(!isOpen);
   };

   return (
      <>
         <div className={`Aside ${isOpen ? "-open" : "-close"}`}>
            <div className={`Aside_AsideTop`}>
               <div
                  style={{ cursor: "pointer", color: "var(--main-green)" }}
                  className={`Aside_navlink arrow ${
                     isOpen ? "slide-out-left" : "slide-in-left"
                  }`}
                  onClick={() => {
                     navigate("/dashboard");
                  }}
               >
                  <i className="fa-solid fa-arrow-left"></i>
               </div>
               <div
                  className={`Aside_Logo ${
                     isOpen ? "slide-out-left" : "slide-in-left"
                  }`}
               />
               <div
                  className={`Aside_ico ${isOpen ? "mar-e" : ""}`}
                  onClick={handleOpen}
               />
            </div>
            <div
               className={`Aside_AsideMid ${
                  isOpen ? "slide-out-left" : "slide-in-left"
               }`}
            >
               <AsideUser division={division} />
               <AsideLastGame league={division} />
               <div className="Aside_Nav">
                  <NavLink className="Aside_navlink " to=" ">
                     Home
                  </NavLink>
                  <NavLink className="Aside_navlink " to="Rankings">
                     Rankings
                  </NavLink>
                  <NavLink className="Aside_navlink " to="Standings">
                     Standings
                  </NavLink>
                  <NavLink className="Aside_navlink " to="History">
                     History
                  </NavLink>
                  <NavLink className="Aside_navlink " to="Teams">
                     Teams
                  </NavLink>
                  <NavLink className="Aside_navlink " to="Rules">
                     Rules
                  </NavLink>
               </div>
            </div>
            <div
               className={`Aside_AsideBot ${
                  isOpen ? "slide-out-left" : "slide-in-left"
               }`}
            >
               {/* <NavIcons className={"Aside_navIcons"} /> */}
               {/* <nav_btn className="Aside_navlink">Leagues</nav_btn> */}
               <div
                  style={{ cursor: "pointer" }}
                  className="Aside_navlink"
                  onClick={() => {
                     logoutUser();
                     navigate("/Login");
                  }}
               >
                  Log Out
               </div>
            </div>
         </div>
      </>
   );
}
