import React from "react";
import "./Button.css";

export default function Button({ children, onClick, type }) {
  return (
    <button
      type={type ? type : "button"}
      className="button-primary"
      onClick={onClick}
    >
      {children}
    </button>
  );
}
