import React, { useEffect, useState } from "react";
import HeroCFLSeasonInfo from "../../molecules/HeroCFL_SeasonInfo/HeroCFL_SeasonInfo";
import HeroCFLInfoDeadline from "../../molecules/HeroCFL_InfoDeadline/HeroCFL_infoDeadline";
import { getAllKFLSeasonService } from "../../../services/season.services";
import { isCurrent } from "../../../utils/validation";
import { getKFLWeeksNowService } from "../../../services/weeks.services";
import CountDownPickWindow from "../../atoms/CountDownPickWindow/CountDownPickWindow";

const HeroKFL = () => {
   const [infoSeason, setinfoSeason] = useState(undefined);
   const [weekNow, setWeekNow] = useState(undefined);

   useEffect(() => {
      getSeason();
      getWeekNow();
   }, []);

   const getWeekNow = async () => {
      try {
         const result = await getKFLWeeksNowService();
         console.log(result.data);
         setWeekNow(result.data);
      } catch (error) {
         console.log(error);
      }
   };
   const getSeason = async () => {
      try {
         const result = await getAllKFLSeasonService();
         const array = result.data.filter((s) => isCurrent(s));
         /* console.log(array.filter((s) => isCurrent(s))); */

         setinfoSeason(array.length > 0 ? array[0] : undefined);
      } catch (error) {
         console.log(error);
      }
   };

   return (
      <div className={`HeroCFL`}>
         <HeroCFLSeasonInfo
            seasonNum={infoSeason && infoSeason.title}
            weekNum={weekNow && weekNow.week}
            SeasonType={infoSeason && infoSeason.type}
         />
         <HeroCFLInfoDeadline
            nameDeadline={"Season length"}
            initDate={infoSeason && infoSeason.date_start}
            endDate={infoSeason && infoSeason.date_end}
         />
         <HeroCFLInfoDeadline
            nameDeadline={"Picks Deadlines"}
            initDate={
               weekNow &&
               weekNow.pick_window &&
               weekNow.pick_window.substring(0, 11)
            }
            endDate={
               weekNow &&
               weekNow.pick_window &&
               weekNow.pick_window.substring(11, 16)
            }
         />
         <CountDownPickWindow pick_window={weekNow && weekNow.pick_window} />
         {/* <HeroCFLInfoDeadline
        nameDeadline={"Resignation period"}
        initDate={"17/02/2023"}
        endDate={"26/06/2023"}
      /> */}
      </div>
   );
};

export default HeroKFL;
