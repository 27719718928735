import React from 'react';
import "./PlayerCard.css"
import DefaultCard from '../../atoms/DefaultCard/DefaultCard';
import Pharagraph from '../../atoms/Paragraph/Paragraph';


const PlayerCard = ({name, mod,division}) => {

    return (
        <>
            <DefaultCard className={`PlayerCard ${mod}`}>
                <Pharagraph className={"PlayerCard__name"}>{name}</Pharagraph>
                <Pharagraph className={"PlayerCard__name"}>{division?.name}</Pharagraph>

                <div className={"PlayerCard__delete"}>
                    <Pharagraph className={"PlayerCard__deletText"}>DELETE</Pharagraph>
                </div>
            </DefaultCard>
        </>

    )
}

export default PlayerCard;