import Pharagraph from "../../atoms/Paragraph/Paragraph";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";

export default function SuccessPage() {
  return (
    <>
      <div className="text-center">
        <PrimaryTitle className={"mt-5"}>PAYMENT SUCCESS.</PrimaryTitle>
        <i
          style={{ color: "var(--main-green)" }}
          className="fa-solid fa-thumbs-up fa-5x mb-3"
        ></i>
        <Pharagraph>the payment has been registered successfully.</Pharagraph>
      </div>
    </>
  );
}
