import React from "react";
// import LoginForm from "../organisms/Login";
import LoginForm from "../../organisms/LoginForm/LoginForm";
import "./Login.css";
import AuthLayout from "../../templates/AuthLayout/AuthLayout";
import loginImg from "../../../assets/img/login.png";

export default function LoginPage() {
   document.title = "Sports World Services – Login";
   return (
      <>
         <AuthLayout img={loginImg} title="Sign In">
            <LoginForm />
         </AuthLayout>
      </>
   );
}
