import axios from "axios";

export const getAllCFLWeeksService = async () => {
  return await axios.get("/cfl/weeks/");
};

export const CreateCFLWeeksService = async (data) => {
  return await axios.post("/cfl/weeks/", data);
};
export const ClosePickWindowCFLWeeksService = async () => {
  return await axios.get("/cfl/weeklyscores/closePickWindow/");
};

export const ClosePickWindowKFLWeeksService = async () => {
  return await axios.get("/kfl/weeklyscores/closePickWindow_kfl/");
};

export const UpdateCFLWeeksService = async (data) => {
  return await axios.patch(`/cfl/weeks/${data.id}/`, data);
};

export const getCFLWeeksNowService = async () => {
  return await axios.get("/cfl/week-now/");
};

export const getKFLWeeksNowService = async () => {
  return await axios.get("/kfl/week-now/");
};

export const getCFLLeaderBoardWeekService = async () => {
  return await axios.get("/cfl/getallweeklyscores/");
};

export const getKFLLeaderBoardWeekService = async () => {
  return await axios.get("/kfl/getallweeklyscores/");
};

export const getAllKFLWeeksService = async () => {
  return await axios.get("/kfl/weeks/");
};

export const CreateKFLWeeksService = async (data) => {
  return await axios.post("/kfl/weeks/", data);
};

export const UpdateKFLWeeksService = async (data) => {
  return await axios.patch(`/kfl/weeks/${data.id}/`, data);
};
