import { useEffect, useState } from "react"
import { getStandingCFLService } from "../../../services/user.services";
import ConferenceList from "./ConferenceList";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";

export default function StandingsCFL() {

    const [Standings, setStandings] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        getStandi()
    }, [])

    const getStandi = async () => {
        setIsLoading(true)

        try {
            setStandings((await getStandingCFLService()).data)
            setIsLoading(false)

        } catch (error) {
        }
    }

    return (
        <>
            {isLoading && (
                <div className="h-center">
                    <CustomSpinner></CustomSpinner>
                </div>
            )}
            <ConferenceList data={Standings && Standings} />
        </>
    )
}