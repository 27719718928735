import { useForm } from "react-hook-form";
import Input from "../../atoms/Input/Input";
import { toast } from "react-hot-toast";
import Overline from "../../atoms/Overline/Overline";

export default function CreateWeeksForm({
   getAllWeeksCFL,
   week,
   create,
   update,
}) {
   const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
   } = useForm();

   const onSubmit = async (data) => {
      if (week) {
         try {
            data.id = week.id;
            console.log(data);
            /* if (data.date_start === week.date_start) delete data.date_start; */
            await update(data);
            toast.success("Week updated successfully");
            getAllWeeksCFL();
         } catch (error) {
            console.log(error.response);
            if (error.response.status > 200) {
            }
         }
      } else {
         try {
            await create(data);
            toast.success("Week created successfully");
            reset();
            getAllWeeksCFL();
         } catch (error) {
            console.log(error);
         }
      }
   };

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <div className="p-5">
            <div className="row">
               <div className="col-6">
                  <Input
                     register={register}
                     type={"number"}
                     name={"week"}
                     label={"Week"}
                     defaultValue={week && week.week}
                     rules={{ required: "Week number is required" }}
                     error={errors.week?.message}
                  />
               </div>
               <div className="col-6">
                  <Input
                     register={register}
                     type={"datetime-local"}
                     name={"pick_window"}
                     label={"Pick window"}
                     defaultValue={week && week.pick_window}
                     rules={{ required: "Pick window is required" }}
                     error={errors.pick_window?.message}
                  />
               </div>
               <div className="col-6">
                  <Input
                     register={register}
                     type={"date"}
                     name={"date_start"}
                     label={"Week start"}
                     rules={{ required: "Week start is required" }}
                     error={errors.date_start?.message}
                     defaultValue={week && week.date_start}
                  />
               </div>
               <div className="col-6">
                  <Input
                     register={register}
                     type={"date"}
                     name={"date_end"}
                     label={"Week end"}
                     rules={{ required: "Week end is required" }}
                     error={errors.date_end?.message}
                     defaultValue={week && week.date_end}
                  />
               </div>
               <div className="col-md-3  col-xl-2 align-self-center">
                  <div className="form-check form-switch my-2">
                     <input  {...register("isOpen")} defaultChecked={week && week.isOpen} className="form-check-input" type="checkbox" />
                     <label className="form-check-label m-0 ms-4"> Is OPen</label>
                  </div>
               </div>
               <div className="col-md-6 align-self-center">
                  <Overline className={"my-2 "}>NOTE: if this option is disabled, players will not be able to choose their picks after this.</Overline>
               </div>
            </div>
            <div className="col-12 text-center">
               <button>Save</button>
            </div>
         </div>
      </form>
   );
}
