import { Link } from "react-router-dom";
import "./LinkAtom.css";

const LinkAtom = ({ to, className, children }) => {
   return (
      <Link className={`link-atom ${className}`} to={to}>
         {children}
      </Link>
   );
};

export default LinkAtom;
