import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import Overline from "../../atoms/Overline/Overline";

export default function ListSeason({ seasons, handleEditSeason }) {
  if (Array.isArray(seasons) && seasons.length === 0) {
    return (
      <>
        <div className="col-12 text-center">
          <Overline>There are no Seasons</Overline>
        </div>
      </>
    );
  }

  if (Array.isArray(seasons) && seasons.length !== 0) {
    return (
      <>
        {seasons.map((s) => (
          <div className="col-12">
            <DefaultCard
              className={"d-flex  px-3 py-2 w-100 position-relative"}
            >
              {s.isCurrent === true && (
                <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
                  CURRENT
                </span>
              )}

              <div className="w-100 row mt-2">
                <Overline className={"m-0 col-6"}> {s.title}</Overline>
                <Overline className={"m-0 col-6"}> {s.type}</Overline>
                <Overline className={"m-0 col-6"}>
                  {`Start : ${s.date_start}`}
                </Overline>
                <Overline className={"m-0 col-6"}>
                  {`End : ${s.date_end}`}
                </Overline>
              </div>
              <div className="">
                <button
                  onClick={() => {
                    console.log(s);
                    handleEditSeason(s);
                  }}
                  className="m-0 p-2"
                >
                  <i className="fa-regular fa-pen-to-square"></i>
                </button>
              </div>
            </DefaultCard>
          </div>
        ))}
      </>
    );
  }
}
