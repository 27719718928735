import React, { useEffect, useState } from "react";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import Modal from "../../atoms/Modal/Modal";
import GamesCFCForm from "../../molecules/GamesCFCForm/GamesCFCForm";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import ListGames from "../../molecules/ListGames/ListGames";
import {
  createKFLGamesService,
  updateKFLGamesService,
  getAllKFLGamesService,
} from "../../../services/games.services";
import { getAllKFLTeamsService } from "../../../services/teams.services";

export default function GamesKFLList() {
  const [gamesAll, setGamesAll] = useState(undefined);
  const [selectedGame, setSelectedGame] = useState(undefined);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllGame();
  }, []);

  const handleClickGameSeleted = (e) => {
    setSelectedGame(e);
    setShowEdit(true);
  };

  

  const getAllGame = async () => {
    setGamesAll(undefined);
    try {
      setIsLoading(true);
      const result = await getAllKFLGamesService();
      setGamesAll(result.data);
      setIsLoading(false);
    } catch (error) {}
  };

  return (
    <>
      <div className="DefaultCardContainerGames" title={"KFL Games"}>
        <PrimaryTitle className={"DefaultCardContainer_Title"}>
            KFL Games
        </PrimaryTitle>
        <div className="mx-2">
          <div className="playerCFL__modal " onClick={() => setShow(true)}>
            <DefaultCard className={"py-3 h-center"}>
              <div className="CardNew_icon" />
            </DefaultCard>
          </div>
          {isLoading && (
            <div className="h-center">
              <CustomSpinner></CustomSpinner>
            </div>
          )}
          {gamesAll && (
            <ListGames
              games={gamesAll}
              handleClickGameSeleted={handleClickGameSeleted}
            />
          )}
        </div>
      </div>
      <Modal show={show} setShow={setShow}>
        <GamesCFCForm
          create={createKFLGamesService}
          setShow={setShow}
          getAllGame={getAllGame}
          getTeamsAll={getAllKFLTeamsService}
        />
      </Modal>
      <Modal show={showEdit} setShow={setShowEdit}>
        <GamesCFCForm
          update={updateKFLGamesService}
          game={selectedGame}
          setShow={setShowEdit}
          getAllGame={getAllGame}
          getTeamsAll={getAllKFLTeamsService}
        />
      </Modal>
    </>
  );
}
