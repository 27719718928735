import React, { useState } from "react";
import "./Accordion.css";
const Accordion = ({
  title,
  content,
  children,
  className,
  setLeagueSelected,
  leagueSelected,
}) => {
  const [isActive, setIsActive] = useState(false);
  console.log(content);

  return (
    <div className={`accordion_item ${className && className}`}>
      <div className="accordion_title" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>
          <i
            className={
              !isActive ? "fa-solid fa-chevron-down" : "fa-solid fa-chevron-up"
            }
          ></i>
        </div>
      </div>
      {isActive && (
        <div className="accordion_content">
          {content &&
            Array.isArray(content) &&
            content.map((o) => (
              <div className="px-2 my-1">
                <p
                  onClick={() => setLeagueSelected(o)}
                  className={
                    o.id === leagueSelected?.id
                      ? "accordion_content_item_selected"
                      : "accordion_content_item"
                  }
                >
                  {`${o.name} - ${o.year}`}
                </p>
              </div>
            ))}

          {content && Array.isArray(content) && content.length === 0 && (
            <p className="content_item px-2 my-1"> THERE ARE NO LEAGUES IN THIS SPORT</p>
          )}
          {!content  && children}
        </div>
      )}
    </div>
  );
};

export default Accordion;
