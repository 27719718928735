import React, { useContext, useEffect, useRef, useState } from "react";
import CardContainer from "../../molecules/CardContainer/CardContainer";
import GameOfTheWeek from "../../molecules/GameOfTheWeek/GameOfTheWeek";
import "./InfoTableCFL.css";
import LeaderBoard from "../../molecules/LeaderBoard/LeaderBoar";
import CardTeams from "../../molecules/CardTeams/CardTeams";
import { getGamesOfWeekService } from "../../../services/games.services";
import { getPickDetailCFLByUserService } from "../../../services/picks.services";
import AuthContext from "../../../context/AuthContext";
import PicksDetailByUser from "../../molecules/PicksDetailByUser/PicksDetailByUser";
import Overline from "../../atoms/Overline/Overline";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import {
   getCFLLeaderBoardWeekService,
   getCFLWeeksNowService,
} from "../../../services/weeks.services";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import Modal from "../../atoms/Modal/Modal";
import FormPickSelect from "../../molecules/FormPickSelect/FormPickSelect";
import Btn_xl from "../../atoms/Btn_xl/Btn_xl";

const InfoTableCFL = () => {
   const [gamesAll, setGamesAll] = useState(null);
   const [pickDetails, setPickDetails] = useState(undefined);
   const [pickDetailsError, setPickDetailsError] = useState(undefined);
   const [leadeBoardWeek, setLeadeBoardWeek] = useState(undefined);
   const [isLoading, setIsLoading] = useState({ pick: true, games: true });
   const [showModal, setshowModal] = useState(false);
   const [showModalEdit, setshowModalEdit] = useState(false);
   const [errorLeader, setErrorLeader] = useState(undefined);
   const [weekNow, setWeekNow] = useState(undefined);
   const [isScrolled, setIsScrolled] = useState({
      games: false,
      leaderboard: false,
   });
   const table = useRef();
   const tableLB = useRef();

   const { user } = useContext(AuthContext);

   useEffect(() => {
      getPicksCurrent();
      getLeaderBoardWeek();
      getAllGames();
      getWeekNow();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const getPicksCurrent = async () => {
      setPickDetailsError(undefined);
      try {
         setIsLoading((status) => {
            return { ...status, pick: true };
         });
         const result = await getPickDetailCFLByUserService(user.user_id);
         setPickDetails(result.data[0]);
         setIsLoading((status) => {
            return { ...status, pick: false };
         });
      } catch (error) {
         console.log(error.response.data.message);
         setPickDetailsError(error.response.data?.message);
      } finally {
         setIsLoading((status) => {
            return { ...status, pick: false };
         });
      }
   };

   const getWeekNow = async () => {
      try {
         setIsLoading((status) => {
            return { ...status, pick: true };
         });
         const response = await getCFLWeeksNowService();
         setWeekNow(response.data);
         // console.log(response.data);
         setIsLoading((status) => {
            return { ...status, pick: false };
         });
      } catch (error) {
         setIsLoading((status) => {
            return { ...status, pick: false };
         });
      }
   };

   const getLeaderBoardWeek = async () => {
      try {
         setIsLoading((status) => {
            return { ...status, leader: true };
         });
         const result = await getCFLLeaderBoardWeekService();
         setLeadeBoardWeek(result.data);
         setIsLoading((status) => {
            return { ...status, leader: false };
         });
      } catch (error) {
         setErrorLeader(error);
         setIsLoading((status) => {
            return { ...status, leader: false };
         });
      }
   };

   const getAllGames = async () => {
      setGamesAll(undefined);
      try {
         setIsLoading((status) => {
            return { ...status, games: true };
         });
         const result = await getGamesOfWeekService();
         setGamesAll(result.data);
         setIsLoading((status) => {
            return { ...status, games: false };
         });
      } catch (error) {}
   };

   const checkScroll = () => {
      if (table.current.scrollTop >= 24) {
         setIsScrolled((prevState) => ({ ...prevState, games: true }));
      } else {
         setIsScrolled((prevState) => ({ ...prevState, games: false }));
      }
      if (tableLB.current.scrollTop >= 24) {
         setIsScrolled((prevState) => ({ ...prevState, leaderboard: true }));
      } else {
         setIsScrolled((prevState) => ({ ...prevState, leaderboard: false }));
      }
   };

   const checkScrollLB = () => {
      if (tableLB.current.scrollTop >= 24) {
         setIsScrolled((prevState) => ({ ...prevState, leaderboard: true }));
      } else {
         setIsScrolled((prevState) => ({ ...prevState, leaderboard: false }));
      }
   };

   useEffect(() => {
      const div = table.current;
      if (div) {
         div.addEventListener("scroll", checkScroll);
         return () => div.removeEventListener("scroll", checkScroll);
      }
   }, []);

   useEffect(() => {
      const div2 = tableLB.current;
      if (div2) {
         div2.addEventListener("scroll", checkScrollLB);
         return () => div2.removeEventListener("scroll", checkScrollLB);
      }
   }, []);

   return (
      <div className={`infoTableCfl`}>
         <CardContainer title={`GAMES OF THE WEEK`} ref={table}>
            <div
               className="scrollShadow"
               style={{ visibility: isScrolled.games ? "visible" : "hidden" }}
            ></div>
            <div className="margin-t">
               {isLoading.games && (
                  <div className="h-center">
                     <CustomSpinner></CustomSpinner>
                  </div>
               )}
               {gamesAll &&
                  Array.isArray(gamesAll) &&
                  gamesAll.length === 0 && (
                     <Overline className={"px-4 Overline-2"}>
                        No games are available for the current week at the
                        moment.
                     </Overline>
                  )}
               {gamesAll &&
                  gamesAll.map((card) => (
                     <GameOfTheWeek
                        key={card.id}
                        date={new Date(`${card.date_time}`)
                           .toDateString()
                           .substring(0, 10)}
                        status={card.status}
                        hour={card.date_time.substring(11, 16)}
                        type={card.type}
                        teamA={card.away_team.name}
                        teamAs={card.score_away}
                        teamB={card.home_team.name}
                        teamBs={card.score_home}
                        player={card.player}
                     />
                  ))}
            </div>

            <div className="separatorLB"></div>
         </CardContainer>
         <CardContainer title={"LEADERBOARD"} ref={tableLB}>
            <div
               className="scrollShadow"
               style={{
                  visibility: isScrolled.leaderboard ? "visible" : "hidden",
               }}
            ></div>

            <div className="margin-t">
               {isLoading.leader && isLoading.leader != undefined && (
                  <div className="h-center">
                     <CustomSpinner></CustomSpinner>
                  </div>
               )}
               {/* {leadeBoardWeek === undefined && !isLoading.leader && (
               <div>
                  <Overline className="Overline-2">
                     Leaderboard is not available for the current week at the
                     moment.
                  </Overline>
               </div>
            )} */}

               {console.log(leadeBoardWeek)}

               {weekNow && weekNow.isOpen === true && (
                  <Overline className="mt-4">
                     Until the picks deadline, you may edit your picks. However,
                     you cannot view your opponent’s picks or the weekly
                     leaderboard.
                  </Overline>
               )}
               {leadeBoardWeek &&
                  weekNow &&
                  !weekNow.isOpen &&
                  leadeBoardWeek.map((card) => (
                     <LeaderBoard
                        key={card.id}
                        name={`${card?.first_name} ${card?.last_name}`}
                        season={"Regular"}
                        points={card.score}
                        defTeam={card.defensive_pick}
                        defTeamS={"Defensive"}
                        TeamMain1={card.pick1}
                        TeamMain1s={"Pick # 1 "}
                        TeamMain2={card.pick2}
                        TeamMain2s={"Pick # 2"}
                        TeamMain3={card.pick3}
                        TeamMain3s={"Pick # 3"}
                     />
                  ))}
            </div>
         </CardContainer>
         <CardContainer title={"PICKS"} className="gamesOfTheWeekCFL-2">
            <div className="scrollShadow"></div>
            <div className="margin-t">
               <div
                  style={{
                     width: "95%",
                     display: "flex",
                     flexDirection: "column",
                     gap: "0.8em",
                  }}
               >
                  {pickDetailsError && weekNow && weekNow.isOpen && (
                     <>
                        <Overline className="m-0">{pickDetailsError}</Overline>
                        {/* <div className="mx-4"> */}
                        <Btn_xl
                           onClick={() => setshowModal(true)}
                           className="add--btn"
                        >
                           <div className="CardNew_icon" />
                           Add picks
                        </Btn_xl>
                        {/* </div> */}
                     </>
                  )}
                  {isLoading.pick && (
                     <div className="h-center">
                        <CustomSpinner></CustomSpinner>
                     </div>
                  )}

                  {pickDetails && weekNow && weekNow.isOpen === true && (
                     <Btn_xl
                        className="add--btn"
                        onClick={() => {
                           setshowModalEdit(true);
                        }}
                     >
                        Change Picks
                     </Btn_xl>
                  )}

                  <PicksDetailByUser
                     cardStyle={"card_picks_2"}
                     pickDetails={pickDetails}
                  />
               </div>
               {/* {teamsCards.map((card) => (
          <CardTeams
            key={card.id}
            name={card.name}
            type={card.type}
            points={card.points}
            acronym={card.acronym}
          />
        ))} */}
            </div>
         </CardContainer>
         <Modal show={showModal} setShow={setshowModal}>
            <FormPickSelect
               getPicksCurrent={getPicksCurrent}
               setshowModal={setshowModal}
            />
         </Modal>
         <Modal show={showModalEdit} setShow={setshowModalEdit}>
            <FormPickSelect
               pick={pickDetails}
               getPicksCurrent={getPicksCurrent}
               setshowModal={setshowModalEdit}
            />
         </Modal>
      </div>
   );
};
export default InfoTableCFL;
