import { NavLink } from "react-router-dom";
import "./Nav_Link.css";

const Nav_Link = ({ to, children }) => {
   return (
      <NavLink to={to} className="nav--item" activeClassName="active">
         {children}
      </NavLink>
   );
};

export { Nav_Link };
