import React from 'react';
import "./CardNew.css"
import DefaultCard from '../../atoms/DefaultCard/DefaultCard';


const CardNew = () => {

    return (
        <>
            <DefaultCard className={"CardNew"}>
                <div className='CardNew_icon' />
            </DefaultCard>
        </>

    )
}

export default CardNew;