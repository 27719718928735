import { createContext, useState, useEffect } from "react";
import { loginUserService } from "../services/user.services";
import { useLocalStorage } from "../hooks/useLocalStorage";
import jwt_decode from "jwt-decode";
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [tokenAccess, setTokenAccess] = useLocalStorage("tokenAccess", "");
  const [authTokens, setAuthTokens] = useState(
    tokenAccess ? tokenAccess : null
  );
  const [user, setUser] = useState(
    tokenAccess ? jwt_decode(tokenAccess.token) : null
  );

  const [modalManualPay, setModalManualPay] = useState(false);
  const [IdMatch, setIdMatch] = useState(undefined);

  useEffect(() => { }, []);

  const loginUser = async (data) => {
    const result = await loginUserService(data);
    setTokenAccess({
      token: result.data.token,
      refresh_token: result.data.refresh_token,
    });
    setAuthTokens({
      token: result.data.token,
      refresh_token: result.data.refresh_token,
    });
    setUser(jwt_decode(result.data.token));
  };

  const logoutUser = async () => {
    localStorage.removeItem("tokenAccess");
    setUser(null)
  };

  let contextData = {
    user,
    loginUser,
    authTokens,
    modalManualPay,
    setModalManualPay,
    logoutUser,
    IdMatch,
    setIdMatch
  };
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
