import React from 'react';
function ConferenceList({ data }) {
    return (
        <div>
            {data && Object.keys(data).map((conferenceName, index) => (
                <div className='default-card  mb-2 p-2' key={index}>
                    <p className='fs-5 m-0'> Conference : {conferenceName}</p>
                    {Object.keys(data[conferenceName]).map((divisionName, index) => (
                        <div className='default-card p-2 border-success mb-1' key={index}>
                            <p className='m-0'>{divisionName}</p>
                            <ul className='m-0'>
                                {data[conferenceName][divisionName].map((user, index) => (
                                    <li className='' key={user}>{user}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
export default ConferenceList;