import React from "react";
import "./TeamCard.css";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import Pharagraph from "../../atoms/Paragraph/Paragraph";

const TeamCard = ({ name, acronym, team, setModalEdit, setTeamSelected }) => {
  return (
    <>
      <DefaultCard className={"PlayerCard"}>
        <Pharagraph className={"PlayerCard__name"}>{name}</Pharagraph>
        <div className={"PlayerCard__delete"}>
          <Pharagraph className={"PlayerCard__deletText"}>{acronym}</Pharagraph>
        </div>
        <div className="h-center">
          <button
            onClick={() => {
              /* handleClickGameSeleted(g); */
              setModalEdit(true);
              setTeamSelected(team);
            }}
            style={{
              padding: "0.5em 1em ",
              width: "2em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0",
              marginRight: "1em",
            }}
          >
            <i className="fa-regular fa-pen-to-square"></i>
          </button>
        </div>
      </DefaultCard>
    </>
  );
};

export default TeamCard;
