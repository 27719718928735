import React from "react";
import SignUpForm from "../../organisms/SignUpForm/SignUpForm";
import AuthLayout from "../../templates/AuthLayout/AuthLayout";
import registerImg from "../../../assets/img/register.jpg";

export default function SignUp() {
   document.title = "Sports World Services – Sign Up";

   return (
      <>
         <AuthLayout img={registerImg} title="Sign Up">
            <SignUpForm />
         </AuthLayout>
      </>
   );
}
