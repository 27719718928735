import React, { useContext, useState } from "react";
import "./TeamsPage.css";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import Overline from "../../atoms/Overline/Overline";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import SixthTitle from "../../atoms/SixthTitle/SixthTitle";
import TertiarySubtitle from "../../atoms/TertiarySubtitle/TertiarySubtitle";
import Modal from "../../atoms/Modal/Modal";
import { useEffect } from "react";
import AuthContext from "../../../context/AuthContext";
import { getPickDetailKFLByUserService } from "../../../services/picks.services";
import { getAllKFLTeamsService } from "../../../services/teams.services";
import PicksDetailByUser from "../../molecules/PicksDetailByUser/PicksDetailByUser";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import FormPickSelect from "../../molecules/FormPickSelect/FormPickSelect";

export default function TeamsPageKFL() {
   const [showModal, setshowModal] = useState(false);
   const [showModalEdit, setshowModalEdit] = useState(false);

   const [pickDetails, setPickDetails] = useState(undefined);
   const [pickDetailsError, setPickDetailsError] = useState(undefined);

   const [isLoading, setIsLoading] = useState({
      teams: true,
      games: true,
      pick: true,
   });

   const [teamsAll, setTeamsAll] = useState(undefined);

   const { user } = useContext(AuthContext);

   useEffect(() => {
      getAllTeams();
      getPicksCurrent();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const getPicksCurrent = async () => {
      setPickDetailsError(undefined);
      try {
         setIsLoading((status) => {
            return { ...status, pick: true };
         });
         const result = await getPickDetailKFLByUserService(user.user_id);
         setPickDetails(result.data[0]);
      } catch (error) {
         setPickDetailsError(error.response.data?.message);
         setshowModal(true);
      } finally {
         setIsLoading((status) => {
            return { ...status, pick: false };
         });
      }
   };

   const getAllTeams = async () => {
      setTeamsAll(undefined);
      try {
         setIsLoading((status) => {
            return { ...status, teams: true };
         });
         const result = await getAllKFLTeamsService();
         setTeamsAll(result.data);
         setIsLoading((status) => {
            return { ...status, teams: false };
         });
      } catch (error) {}
   };

   return (
      <>
         <div className={`banner_header_dasboard_cfl`}>
            {/*  <img src={iomg} alt="" className="img_banner_dasboard" /> */}
            <div className="banner_text_dasboard ">
               <PrimaryTitle>Teams</PrimaryTitle>
               <Overline className={"m-0"}>Choice period</Overline>
               <Overline className={"text-green"}>REGULAR</Overline>

               <DefaultCard className={"p-2"}>
                  <SixthTitle className={"m-0"}>Game Schedule</SixthTitle>
               </DefaultCard>
            </div>
         </div>

         <div className={`container_team_page`}>
            <div className="section_pick text-center">
               <PrimaryTitle>PICKS</PrimaryTitle>
               <div className="container_card_picks">
                  {/* {pickDetails && (
                     <button
                        onClick={() => {
                           setshowModalEdit(true);
                        }}
                     >
                        Change Picks
                     </button>
                  )} */}
                  <PicksDetailByUser
                     cardStyle={"card_picks"}
                     pickDetails={pickDetails}
                  />
                  {isLoading.pick && (
                     <div className="h-center">
                        <CustomSpinner></CustomSpinner>
                     </div>
                  )}
                  <Overline className={"text-green fs-5"}>
                     {pickDetailsError}
                  </Overline>
               </div>
            </div>

            <div className="section_team ">
               <PrimaryTitle>TEAMS</PrimaryTitle>
               <div className="container_card_picks">
                  {isLoading.teams && (
                     <div className="h-center">
                        <CustomSpinner></CustomSpinner>
                     </div>
                  )}
                  {teamsAll &&
                     teamsAll.map((c) => (
                        <DefaultCard className={"card_picks"}>
                           <div className="card_pick_content">
                              <TertiarySubtitle className={"mb-1"}>
                                 {c.name}
                              </TertiarySubtitle>

                              <div className="av_pick_team">{c.acronym}</div>
                           </div>
                        </DefaultCard>
                     ))}
               </div>
            </div>
         </div>

         <Modal show={showModal} setShow={setshowModal}></Modal>

         <Modal show={showModalEdit} setShow={setshowModalEdit}></Modal>
      </>
   );
}
