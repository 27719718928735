import React, { useContext } from "react";
import LinkAtom from "../../atoms/LinkAtom/LinkAtom";
import "./Nav_Btns.css";
import AuthContext from "../../../context/AuthContext";
import { Link } from "react-router-dom";

const Nav_Btns = () => {
   const { user, logoutUser } = useContext(AuthContext);

   return (
      <>
         {!user ? (
            <>
               <LinkAtom to={"/Login"} className="nav--btn __left">
                  Login
               </LinkAtom>
               <LinkAtom to={"/signup"} className="nav--btn __right bg--color">
                  Sign Up
               </LinkAtom>
            </>
         ) : (
            <>
               <Link to={"/dashboard"} className="nav--btn __left">
                  Dashboard
               </Link>
               <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                     logoutUser();
                  }}
                  className="nav--btn __right"
               >
                  Logout
               </div>
            </>
         )}
      </>
   );
};

export default Nav_Btns;
