export const isOlder = (date) => {
  let birth_date = new Date(date);
  let current_date = new Date();
  current_date.setFullYear(current_date.getFullYear() - 18);
  if (birth_date < current_date) {
    return true;
  } else {
    return false;
  }
};

export function isCurrent(s) {
  return (
    new Date(s.date_start) < new Date() && new Date(s.date_end) > new Date()
  );
}
