import React from "react";

const Home = React.lazy(() => import("./components/pages/Home/Home"));
const About = React.lazy(() => import("./components/pages/About"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Football = React.lazy(() => import("./components/pages/Football"));

const routes = [
   { path: "/Home", name: "Dashboard", element: Home },
   { path: "/About", name: "Bets", element: About },
   { path: "/Contact", name: "Tracking", element: Contact },
   { path: "/Football", name: "Football", element: Football },
];

export default routes;
