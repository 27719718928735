import { useEffect, useState } from "react";
import SelectReact from "../../atoms/SelectReact/SelectReact";
import { getAllUsersService } from "../../../services/user.services";
import { getAllSportsService } from "../../../services/sports.services";
import { Controller, useForm } from "react-hook-form";
import { getAllLeagueService } from "../../../services/league.services";
import {
  postPaymentFilterUserByLeagueServices,
  postPaymentManualServices,
} from "../../../services/payments.services";
import { toast } from "react-hot-toast";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import Overline from "../../atoms/Overline/Overline";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";

export default function FormManualPayment({ setModal }) {
  const [UsersAll, setUsersAll] = useState(undefined);
  const [SportAll, setSportAll] = useState(undefined);
  const [LeagueAll, setLeagueAll] = useState(undefined);
  const [LeagueFilter, setLeagueFilter] = useState(undefined);
  const [UserFilter, setUserFilter] = useState(undefined);
  const [isLoading, setisLoading] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getAllUser();
    getAllSport();
    getAllLeague();
  }, []);

  useEffect(() => {
    if (LeagueFilter) {
      filterUser(LeagueFilter);
    }
  }, [LeagueFilter]);

  const filterUser = async (idLeague) => {
    setisLoading(true);
    setUserFilter(undefined);
    try {
      const result = await postPaymentFilterUserByLeagueServices({
        league: idLeague,
      });
      setUserFilter(result.data);
      setisLoading(false);
    } catch (error) {
      console.log(error);
      setisLoading(false);
    }
  };

  const onSubmit = async (data) => {
    console.log(data);
    data.payment_complete = true;

    try {
      await postPaymentManualServices(data);
      setModal(false);
      toast.success("payment made");
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(Object.values(error.response.data)[0]);
      }
    }
  };
  const getAllUser = async () => {
    try {
      const result = await getAllUsersService();

      setUsersAll(
        result.data.map((c) => {
          return {
            label: c.username,
            value: c.id,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getAllSport = async () => {
    try {
      const result = await getAllSportsService();

      setSportAll(
        result.data.map((c) => {
          return {
            label: c.name,
            value: c.id,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getAllLeague = async () => {
    try {
      const result = await getAllLeagueService();
      setLeagueAll(
        result.data.map((c) => {
          return {
            label: `${c.name} ${c.year}`,
            value: c.id,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            {SportAll && (
              <Controller
                control={control}
                name="sport"
                rules={{ required: "sport is required" }}
                render={({ field: { onChange, name, ref } }) => (
                  <SelectReact
                    inputRef={ref}
                    onChange={onChange}
                    name={name}
                    label={"Select Sport"}
                    options={SportAll && SportAll}
                    error={errors.sport?.message}
                  />
                )}
              />
            )}

            {LeagueAll && (
              <Controller
                control={control}
                name="league"
                rules={{ required: "League is required" }}
                render={({ field: { onChange, name, ref } }) => (
                  <SelectReact
                    isDisabled={isLoading}
                    inputRef={ref}
                    onChange={(e) => {
                      console.log(e);
                      setLeagueFilter(e);
                      setValue(name, e);
                    }}
                    name={name}
                    label={"Select League"}
                    options={LeagueAll && LeagueAll}
                    error={errors.league?.message}
                  />
                )}
              />
            )}

            {UsersAll && (
              <Controller
                control={control}
                name="user"
                rules={{ required: "user is required" }}
                render={({ field: { onChange, name, ref } }) => (
                  <SelectReact
                    inputRef={ref}
                    onChange={onChange}
                    name={name}
                    label={"Select User"}
                    options={UsersAll && UsersAll}
                    error={errors.user?.message}
                  />
                )}
              />
            )}

            {LeagueAll && UsersAll && SportAll && (
              <div className="h-center">
                <button type="submit">Save</button>
              </div>
            )}
          </form>
        </div>
        <div className="col-6">
          <div className="row g-2">
            {isLoading && (
              <div className="h-center">
                <CustomSpinner></CustomSpinner>
              </div>
            )}
            {UserFilter &&
              UserFilter.map((u) => (
                <div className="col-12">
                  <DefaultCard className={"w-100 px-4 py-2"}>
                    <Overline className={"m-0"}> {u.username}</Overline>
                  </DefaultCard>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
