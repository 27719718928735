import React from "react";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import FifthTitle from "../../atoms/FifthTitle/FifthTitle";
import Overline from "../../atoms/Overline/Overline";
import Pharagraph from "../../atoms/Paragraph/Paragraph";
import "./LeaderBoar.css";

const LeaderBoard = ({
   name,
   season,
   points,
   defTeam,
   defTeamS,
   TeamMain1,
   TeamMain1s,
   TeamMain2,
   TeamMain2s,
   TeamMain3,
   TeamMain3s,
}) => {
   return (
      <>
         <DefaultCard className={"LeaderBoard card-bottom"}>
            <div className="LeaderBoard_column">
               <div className="LeaderBoard_playerData">
                  <FifthTitle className={"LeaderBoard_name text-capitalize"}>{name}</FifthTitle>

                  <div className="LeaderBoard_pts">
                     <Overline className={"-noMargin"}>{season}</Overline>
                     <Overline className={"-active"}>{points}PTS</Overline>
                  </div>
               </div>
               <div className="LeaderBoard_defTeam">
                  <Pharagraph className={"LeaderBoard_body bold"}>
                     {defTeam}
                  </Pharagraph>
                  <Overline className={"LeaderBoard_body Overline-2"}>
                     {defTeamS}
                  </Overline>
               </div>
            </div>

            <div className="line_game-h">.</div>

            <div className="row mx-2">
               <div className="col-12 LeaderBoard_team">
                  <Pharagraph className={"LeaderBoard_body"}>
                     {TeamMain1}
                  </Pharagraph>
                  <Pharagraph className={"LeaderBoard_body"}>
                     {TeamMain1s}
                  </Pharagraph>
               </div>
               <div className="col-12 LeaderBoard_team">
                  <Pharagraph className={"LeaderBoard_body"}>
                     {TeamMain2}
                  </Pharagraph>
                  <Pharagraph className={"LeaderBoard_body"}>
                     {TeamMain2s}
                  </Pharagraph>
               </div>
               <div className="col-12 LeaderBoard_team">
                  <Pharagraph className={"LeaderBoard_body"}>
                     {TeamMain3}
                  </Pharagraph>
                  <Pharagraph className={"LeaderBoard_body"}>
                     {TeamMain3s}
                  </Pharagraph>
               </div>
            </div>
         </DefaultCard>
      </>
   );
};

export default LeaderBoard;
