import Select from "react-select";
import SenaryTitle from "../SenaryTitle/SenaryTitle";
import "./SelectReact.css";
import Caption from "../Caption/Caption";

export default function SelectReact({
  onChange,
  defaultValue,
  options,
  name,
  label,
  isDisabled,
  value,
  inputRef,
  error,
}) {
  return (
    <>
      <label htmlFor={name}>
        <SenaryTitle>{label}</SenaryTitle>
        <br />
      </label>
      <Select
        className="select_react_sws"
        ref={inputRef}
        isDisabled={isDisabled ? isDisabled : false}
        defaultValue={defaultValue}
        value={value}
        name={name}
        options={options}
        onChange={(e) => onChange(e.value)}
        styles={{
          option: (provided) => ({
            ...provided,
            color: "white",
            backgroundColor: "#142740",
            border: "none",
          }),
          control: (provided) => ({
            ...provided,
            color: "white",
            backgroundColor: "var(--section)",
            border: "solid 1px #305385",
          }),
          singleValue: (provided) => ({
            ...provided,
            zIndex: "2",
            color: "white",
          }),
          menu: (provided) => ({
            ...provided,
            color: "white",
            backgroundColor: "var(--section)",
            overflow: "hidden",
            borderRadius: "1rem",
            border: "solid 1px #305385",
            boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            zIndex: "3",

          }),
          input: (provided) => ({
            ...provided,
            color: "var(--global-font)",
            backgroundColor: "var(--section)",
          }),
          container: (provided) => ({
            ...provided,
          }),
          menuList: (provided) => ({
            ...provided,
            "::-webkit-scrollbar": {
              width: "6px",
              height: "0px",
            },
            "::-webkit-scrollbar-track": {
              background: "#var(--stroke-card)",
            },
            "::-webkit-scrollbar-thumb": {
              background: "var(--stroke-card)",
              borderRadius: "5px",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
    

          }),
        }}
      />
      {error && <Caption className="red-text error_text">{error}</Caption>}
    </>
  );
}
