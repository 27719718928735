import React from "react";
import TertiarySubtitle from "../../atoms/TertiarySubtitle/TertiarySubtitle";
import Input from "../../atoms/Input/Input";
import { useForm } from "react-hook-form";
import "./CreateTeamsForm.css";

import { toast } from "react-hot-toast";

const CreateTeamForm = ({ team, getAllTeams, setShow, create, update }) => {
   const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
   } = useForm();

   const onSubmit = async (data) => {
      if (team) {
         data.id = team.id;
         console.log(data);

         try {
            await update(data);
            setShow(false);
            toast.success("Team updated successfully");
            await getAllTeams();
         } catch (error) {}
         console.log("editar");
      } else {
         try {
            await create(data);
            setShow(false);
            toast.success("Team created successfully");
            await getAllTeams();
            reset();
         } catch (error) {}
      }
   };

   return (
      <>
         <form onSubmit={handleSubmit(onSubmit)} className="CreateTeamForm">
            <TertiarySubtitle>Name Conference</TertiarySubtitle>
            <div className="line_game-h"></div>
            <Input
               label={"New Team Name"}
               id={"name"}
               name={"name"}
               register={register}
               placeholder={"name"}
               type="text"
               rules={{
                  required: "Name is required",
               }}
               error={errors.name?.message}
               defaultValue={team && team.name}
            />
            <Input
               label={"acronym"}
               id={"acronym"}
               name={"acronym"}
               register={register}
               placeholder={"acronym"}
               type="text"
            />
            <button type="submit" className="CreateTeamForm">
               Create
            </button>
         </form>
      </>
   );
};

export default CreateTeamForm;
