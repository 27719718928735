import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

export default function AdminPanelRouter({ children, ...rest }) {
  const { user } = useContext(AuthContext);

  if (!user || user.is_superuser === false) {
    return <Navigate to="/dashboard" replace />;
  }
  return children;
}
