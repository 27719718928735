import { useState } from "react";
import DefaultCard from "../DefaultCard/DefaultCard";
import "./GameResultBadge.css"

export default function GameResultBadge({ MatchUpDetail, pick,player }) {
  const [isHover, setisHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setisHover(true)}
      onMouseLeave={() => setisHover(false)}
    >
      <DefaultCard
        className={`py-2 px-4 mb-2 position-relative ${
          MatchUpDetail?.[player] &&
          MatchUpDetail?.[player][`pick${pick.id}`] ===
            MatchUpDetail?.[player][`pick${pick.id}_game_winner`] &&
          "green-border"
        }  ${
          MatchUpDetail?.[player] &&
          MatchUpDetail?.[player][`pick${pick.id}`] !==
            MatchUpDetail?.[player][`pick${pick.id}_game_winner`] &&
          MatchUpDetail?.[player][`pick${pick.id}_game_winner`] !== "Tie" &&
          "border-danger"
        }`}
      >
        {MatchUpDetail &&
          MatchUpDetail?.[player] &&
          MatchUpDetail?.[player]?.[`pick${pick.id}`]}
        {isHover && (
          <span className="w-100 position-absolute top-0 start-0 translate-middle text-nowrap p-2 GameResultBadge">
           {`${MatchUpDetail?.[player]?.[`pick${pick.id}_game_info`]?.home_team} - ${MatchUpDetail?.[player]?.[`pick${pick.id}_game_info`]?.away_team_score} VS ${MatchUpDetail[player]?.[`pick${pick.id}_game_info`]?.away_team} - ${MatchUpDetail?.[player]?.[`pick${pick.id}_game_info`]?.home_team_score}`}
          </span>
        )}
      </DefaultCard>
    </div>
  );
}
