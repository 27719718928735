import React from "react";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import FifthTitle from "../../atoms/FifthTitle/FifthTitle";
import Overline from "../../atoms/Overline/Overline";
import Pharagraph from "../../atoms/Paragraph/Paragraph";
import TertiarySubtitle from "../../atoms/TertiarySubtitle/TertiarySubtitle";
import "./GameOfTheWeek.css";

const GameOfTheWeek = ({
   date,
   status,
   hour,
   type,
   teamA,
   teamAs,
   teamB,
   teamBs,
   player,
}) => {
   return (
      <>
         <DefaultCard className={"GameOfTheWeek card-bottom"}>
            <div className="GameOfTheWeek_column">
               <FifthTitle className={"GameOfTheWeek_Date"}>{date}</FifthTitle>

               <div className="GameOfTheWeek_Status">
                  <Overline className={"-active"}>{status}</Overline>
                  <Overline>{hour}</Overline>
               </div>

               <Overline className={"GameOfTheWeek_type"}>{type}</Overline>
            </div>

            <div className="line_game"></div>
            <div className="GameOfTheWeek_column-c">
               <div className="GameOfTheWeek_Team">
                  <Pharagraph className={"GameOfTheWeek_body"}>
                     {teamA} - {teamAs}
                  </Pharagraph>
                  {/* <span className={"GameOfTheWeek_body"}></span> */}
               </div>
               <Overline className={"GameOfTheWeek_vs"}>VS</Overline>
               <div className="GameOfTheWeek_Team">
                  <Pharagraph className={"GameOfTheWeek_body"}>
                     {teamB} - {teamBs}
                  </Pharagraph>
                  {/* <span className={"GameOfTheWeek_body"}></span> */}
               </div>
            </div>
            <div className="separator"></div>

            {/* <div className='GameOfTheWeek_Player'>
          <TertiarySubtitle className={"GameOfTheWeek_PlayerTitle"}>{player}</TertiarySubtitle>
        </div>   */}
         </DefaultCard>
      </>
   );
};

export default GameOfTheWeek;
