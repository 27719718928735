import { useEffect, useState } from "react";
import Overline from "../../atoms/Overline/Overline";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";

import rules from "./rules.json";
import Pharagraph from "../../atoms/Paragraph/Paragraph";

export default function RulesKFL({ openedAside }) {
   const [rulesSelected, setrulesSelected] = useState("How To Play");
   const [contenRule, setcontenRule] = useState(undefined);

   useEffect(() => {
      console.log(rules.rules.find((r) => r.title === rulesSelected));
      setcontenRule(rules.rules.find((r) => r.title === rulesSelected));
   }, [rulesSelected]);

   return (
      <div className={`container mt-5`}>
         <PrimaryTitle> RULES </PrimaryTitle>
         <div className="row">
            <div className="col-md-5 col-lg-4">
               <Overline className={"fs-5"}> {rules && rules.name}</Overline>
               <hr></hr>

               {rules &&
                  rules.rules.map((r, i) => (
                     <div
                        style={{ cursor: "pointer", marginBottom: "0.8em" }}
                        onClick={() => setrulesSelected(r.title)}
                     >
                        <Overline
                           className={`Overline-3 ${
                              rulesSelected === r.title
                                 ? "m-1 text-green"
                                 : "m-1"
                           }`}
                        >
                           {" "}
                           {`${++i}. ${r.title}`}
                        </Overline>
                     </div>
                  ))}
            </div>

            <div className="col-md-7 col-lg-8 h-75">
               <Overline className={"fs-5"}>General Rules</Overline>
               <hr></hr>
               {contenRule &&
                  contenRule.rules.map((r) => (
                     <>
                        <Pharagraph className={"fs-6"}>
                           • {r.content}
                        </Pharagraph>
                        {r.item &&
                           r.item.map((c) => (
                              <Pharagraph className={"ms-5 fs-7"}>
                                 {c}
                              </Pharagraph>
                           ))}
                     </>
                  ))}

               <Pharagraph className={"fs-6 text-green"}>
                  {" "}
                  {contenRule && contenRule.note}
               </Pharagraph>
            </div>
         </div>
      </div>
   );
}
