import axios from "axios";

export const getAllPlayerMatchesServices = async () => {
  return await axios.get("/cfl/playermatches/");
};

export const getAllPlayerMatchesKFLServices = async () => {
  return await axios.get("/kfl/playermatches/");
};

export const getGeneratePlayersMatchesServices = async () => {
  return await axios.get("/cfl/playermatches/post_matches/");
};

export const deletePlayerMatchesServices = async (id) => {
  return await axios.delete(`/cfl/playermatches/${id}/`);
};

export const getMatchUpDetailCFLService = async (id) => {
  return await axios.get(`/cfl/moreinfopicks/${id}/`);
};

export const getMatchUpDetailHistoryCFLService = async (id) => {
  return await axios.get(`/cfl/infomatchhistory/${id}/`);
};

export const getMatchUpDetailKFLService = async (id) => {
  return await axios.get(`/kfl/moreinfopicks/${id}/`);
};
export const getMatchUpDetailHistoryKFLService = async (id) => {
  return await axios.get(`/kfl/infomatchhistory/${id}/`);
};

