import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { isOlder } from "../../../utils/validation";
import { registerUserService } from "../../../services/user.services";
import { Link } from "react-router-dom";
import AuthLayout from "../../templates/AuthLayout/AuthLayout";
import Input from "../../atoms/Input/Input";
import "./SignUpForm.css";
import Btn_xl from "../../atoms/Btn_xl/Btn_xl";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import SenaryTitle from "../../atoms/SenaryTitle/SenaryTitle";
import Overline from "../../atoms/Overline/Overline";

export default function SignUpForm() {
   const [errorResponse, setErrorResponse] = useState(undefined);
   const [isRegisterded, setisRegisterded] = useState(undefined);

   const {
      register,
      handleSubmit,
      reset,
      watch,
      formState: { errors, isSubmitting },
   } = useForm();

   const password = useRef();
   password.current = watch("password", "");
   const onSubmit = async (data) => {
      setErrorResponse(undefined);
      console.log(data);

      try {
         const result = await registerUserService(data);
         console.log(result);
         setisRegisterded(true);
         reset();
      } catch (error) {
         if (error.response.status === 400) {
            setErrorResponse(error.response.data);
            console.log(Object.keys(error.response.data));
            console.log(Object.values(error.response.data));
         }
      }
   };
   return (
      <>
         <form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="singup--form">
               <div className="signup--subcontainer">
                  <div className="signup--col">
                     <Input
                        register={register}
                        placeholder="Enter first name"
                        type="text"
                        name="first_name"
                        label="First Name"
                        rules={{
                           required: "First name is required",
                        }}
                        error={errors.first_name?.message}
                     />
                  </div>
                  <div className="signup--col">
                     <Input
                        register={register}
                        placeholder="Enter last name"
                        type="text"
                        name="last_name"
                        label="Last Name"
                        rules={{
                           required: "Last name is required",
                        }}
                        error={errors.last_name?.message}
                     />
                  </div>
               </div>
               <Input
                  register={register}
                  placeholder="Enter email"
                  type="text"
                  name="email"
                  label="Email"
                  rules={{
                     required: "Email is required",
                     pattern: {
                        value: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                        message: "Enter a valid email",
                     },
                  }}
                  error={errors.email?.message}
               />
               <div className="signup--subcontainer">
                  <div className="signup--col">
                     <Input
                        register={register}
                        placeholder="Enter username"
                        type="text"
                        name="username"
                        label="Username"
                        rules={{
                           required: "username is required",
                        }}
                        error={errors.username?.message}
                     />
                  </div>
                  <div className="signup--col">
                     <Input
                        register={register}
                        type="date"
                        name="birthdate"
                        label="Birthdate"
                        rules={{
                           required: "Date of birth is required",
                           validate: {
                              isOlder: (v) =>
                                 isOlder(v) || "you must be of legal age",
                           },
                        }}
                        error={errors.birthdate?.message}
                     />
                  </div>
               </div>
               <div className="signup--subcontainer">
                  <div className="signup--col">
                     <Input
                        register={register}
                        placeholder="Enter password"
                        type="password"
                        name="password"
                        label="Password"
                        rules={{
                           required: "Password is required",
                           minLength: {
                              value: 8,
                              message:
                                 "Password must have at least 8 characters",
                           },
                        }}
                        error={errors.password?.message}
                     />
                  </div>
                  <div className="signup--col">
                     <Input
                        register={register}
                        placeholder="Enter password"
                        type="password"
                        name="password_confirm"
                        label="Confirm Password"
                        rules={{
                           validate: (value) =>
                              value === password.current ||
                              "The passwords do not match",
                        }}
                        error={errors.password_confirm?.message}
                     />
                  </div>
               </div>
               <div className="w-100 my-3">
                  {isSubmitting && (
                     <div className="h-center">
                        <CustomSpinner />
                     </div>
                  )}
                  {isRegisterded && (
                     <>
                        <Overline className="text-center">
                           successfully registered to our system
                        </Overline>
                        <Overline className="text-center m-0">
                           <Link
                              style={{ color: "var(--main-green)" }}
                              to={"/login"}
                           >
                              {" "}
                              go to login
                           </Link>
                        </Overline>
                     </>
                  )}
                  {errorResponse &&
                     Object.values(errorResponse)
                        .flat()
                        .map((c) => (
                           <Overline className={"text-danger"}>{c}</Overline>
                        ))}
               </div>
            </div>

            <div class="form-check">
               <input
                  class="form-check-input p-0"
                  type="checkbox"
                  id="accept"
                  name="accept"
                  {...register("accept", {
                     required:
                        "Please make sure to read and accept the terms and conditions before proceeding.",
                  })}
               />
               <label class="form-check-label m-0" for="flexCheckChecked">
                  I have read and accept the{" "}
                  <a
                     href="/terms-and-conditions"
                     style={{ color: "var(--main-green)" }}
                     target="_blank"
                  >
                     Terms and Conditions.
                  </a>
               </label>
            </div>
            <Btn_xl className="login--btn __submit" to={"/signup"}>
               Sign Up
            </Btn_xl>
         </form>

         {/* <div className="justify-content-center row">
            <Form
               onSubmit={handleSubmit(onSubmit)}
               className="col-10 col-md-9 col-xl-7"
               autoComplete="off"
               noValidate
            >
               <div className="row justify-content-start">
                  <div className="col-12"> */}
         {/* <Form.Group className="mb-3" controlId="formFullName">
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                           {...register("name", {
                              required: "Full name is required",
                              // minLength: {
                              //    value: 15,
                              //    message:
                              //       "Full name cannot be longer than 15 characters", // JS only: <p>error message</p> TS only support string
                              // },
                           })}
                           placeholder="Enter full name"
                           feedback={errors.name}
                           feedbacktype="invalid"
                           isInvalid={errors.name}
                        />
                        <Form.Text className="ms-3 text-danger">
                           {errors.name?.message}
                        </Form.Text>
                     </Form.Group> */}

         {/* <FloatingLabel controlId="name" label="Full Name" className="mb-3">
              <Form.Control
                type="text"
                {...register("name", {
                  required: "Full name is required",
                  minLength: {
                    value: 15,
                    message: "Full name cannot be longer than 15 characters", // JS only: <p>error message</p> TS only support string
                  },
                })}
                placeholder="name"
                name="name"
                feedback={errors.name}
                feedbacktype="invalid"
                required
                isInvalid={errors.name}
              />

              <Form.Control.Feedback type="invalid">
                {errors.name?.message}
              </Form.Control.Feedback>
            </FloatingLabel> */}
         {/* </div>

                  <div className="col-12">
                     <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                           type="text"
                           placeholder="Enter email"
                           {...register("email", {
                              required: "email is required",
                              pattern: {
                                 value: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                                 message: "Enter a valid email", // JS only: <p>error message</p> TS only support string
                              },
                           })}
                           feedback={errors.email}
                           feedbacktype="invalid"
                           required
                           isInvalid={errors.email}
                        />
                        <Form.Text className="ms-3 text-danger">
                           {errors.email?.message}
                        </Form.Text>
                     </Form.Group> */}
         {/* <FloatingLabel controlId="email" label="Email" className="mb-3">
              <Form.Control
                type="text"
                placeholder=" "
                {...register("email", {
                  required: "email is required",
                  pattern: {
                    value:
                      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                    message: "Enter a valid email", // JS only: <p>error message</p> TS only support string
                  },
                })}
                feedback={errors.email}
                feedbacktype="invalid"
                required
                isInvalid={errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </FloatingLabel> */}
         {/* </div>
                  <div className="col-12 col-md-6">
                     <Form.Group
                        controlId="username"
                        label="Username"
                        className="mb-3"
                     >
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                           type="text"
                           placeholder=" "
                           {...register("username", {
                              required: "username is required",
                           })}
                           feedback={errors.username}
                           feedbacktype="invalid"
                           isInvalid={errors.username}
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.username?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                  </div>

                  <div className="col-12 col-md-6">
                     <Form.Group className="mb-3" controlId="formage">
                        <Form.Label>Date of birth</Form.Label>
                        <Form.Control
                           type="date"
                           placeholder=" "
                           {...register("age", {
                              required: "Date of birth is required",
                              validate: {
                                 isOlder: (v) =>
                                    isOlder(v) || "you must be of legal age",
                              },
                           })}
                           required
                           feedback={errors.age}
                           feedbacktype="invalid"
                           isInvalid={errors.age}
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.age?.message}
                        </Form.Control.Feedback>
                     </Form.Group> */}

         {/* <FloatingLabel
              controlId="age"
              label="Date of birth"
              className="mb-3"
            >
              <Form.Control
                type="date"
                placeholder=" "
                {...register("age", {
                  required: "Date of birth is required",
                  validate: {
                    isOlder: (v) => isOlder(v) || "you must be of legal age",
                  },
                })}
                required
                feedback={errors.age}
                feedbacktype="invalid"
                isInvalid={errors.age}
              />
              <Form.Control.Feedback type="invalid">
                {errors.age?.message}
              </Form.Control.Feedback>
            </FloatingLabel> */}
         {/* </div>

                  <div className="col-12 col-md-6">
                     <Form.Group
                        controlId="password"
                        label="Password"
                        className="mb-3"
                     >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                           type="password"
                           placeholder=" "
                           ref={password}
                           {...register("password", {
                              required: "password is required",
                              minLength: {
                                 value: 8,
                                 message:
                                    "Password must have at least 8 characters",
                              },
                           })}
                           required
                           feedback={errors.password}
                           feedbacktype="invalid"
                           isInvalid={errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.password?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                  </div>

                  <div className="col-12 col-md-6">
                     <Form.Group
                        controlId="password_confirm"
                        label="Confirm Password"
                        className="mb-3"
                     >
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                           type="password"
                           placeholder=" "
                           feedback={errors.password_confirm}
                           feedbacktype="invalid"
                           isInvalid={errors.password_confirm}
                           {...register("password_confirm", {
                              validate: (value) =>
                                 value === password.current ||
                                 "The passwords do not match",
                           })}
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.password_confirm?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                  </div>
                  <div className="col-12 mb-3">
                     {isSubmitting && (
                        <div className="">
                           <div className="my-1 pt-3 text-center">
                              <div
                                 className="spinner-border"
                                 style={{ color: "#008285" }}
                                 role="status"
                              >
                                 <span className="visually-hidden">
                                    Loading...
                                 </span>
                              </div>
                           </div>
                        </div>
                     )}
                     {isRegisterded && (
                        <div className="alert alert-secondary" role="alert">
                           successfully registered to our system
                           <p className="text-center m-0">
                              <Link to={"/login"}> go to login</Link>
                           </p>
                        </div>
                     )}
                     {errorResponse &&
                        Object.values(errorResponse)
                           .flat()
                           .map((c) => (
                              <p className="text-danger fw-semibold my-1 ">
                                 {" "}
                                 {c}{" "}
                              </p>
                           ))}
                  </div>
                  <div className="col-12">
                     <div class="form-check">
                        <input
                           class="form-check-input"
                           type="checkbox"
                           {...register("accept", {
                              required:
                                 "you must accept the terms and conditions ",
                              validate: (value) =>
                                 value === true || "The passwords do not match",
                           })}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                           I have read and accept the{" "}
                           <span className="terms"> Terms and Conditions.</span>
                        </label>
                     </div>
                     <Form.Text className="ms-3 text-danger">
                        {errors.accept?.message}
                     </Form.Text> */}
         {/* <Form.Check
              label="I have read and accept the Terms and Conditions."
              name="accept"
              {...register("accept", {
                required: "you must accept the terms and conditions ",
                validate: (value) =>
                  value === true || "The passwords do not match",
              })}
            /> */}
         {/* </div>
                  <div className="col-12">
                     <div className="text-center">
                        <button
                           type="submit"
                           className="btn btn_register  mt-4 w-100 fw-semibold  fs-5 py-2 px-5"
                        >
                           Sign Up
                        </button>
                     </div>
                  </div>
               </div>
            </Form>
         </div> */}
      </>
   );
}
