import axios from "axios";

export const registerUserService = async (data) => {
  data.is_superuser = false;
  return await axios.post("/users/", data);
};

export const loginUserService = async (data) => {
  return await axios.post("/login/", data);
};

export const RequestPasswordResetUserService = async (data) => {
  return await axios.post("/request-reset-email/", data);
};

export const PasswordResetUserService = async (data) => {
  return await axios.patch(`/password-reset-complete/`, data);
};

export const PayCFLService = async (id) => {
  return await axios.post(`/stripe/create-checkout-session/CFL/${id}/`);
};

export const getAllUsersService = async () => {
  return await axios.get("/shared/users/");
};

export const getUsersByIdService = async (id) => {
  return await axios.get(`/shared/users/${id}/`);
};

export const getStandingCFLService = async () => {
  return await axios.get(`/standings-cfl/`);
};
export const getStandingKFLService = async () => {
  return await axios.get(`/standings-kfl/`);
};



export const putDivisionToPlayer = async (id, division) => {
  return await axios.patch(`/users/${id}/`, { division: division });
};

export const putDivisionToPlayerKFL = async (id, division) => {
  return await axios.patch(`/users/${id}/`, { division_kfl: division });
};

export const getTotalScoresService = async () => {
  return await axios.get(`/cfl/totalscores/`);
};

export const getTotalScoresCFLService = async () => {
  return await axios.get(`/kfl/totalscores/`);
};

export const getLeagueActivesByUserService = async (user_id) => {
  return await axios.get(`/shared/get_active_league_packs/${user_id}`);
};
