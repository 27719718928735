import { useEffect, useState } from "react";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import {
  UpdateKFLSeasonService,
  getAllKFLSeasonService,
  getCreatekflSeasonService,
} from "../../../services/season.services";
import ListSeason from "../ListSeason/ListSeason";
import Modal from "../../atoms/Modal/Modal";
import SeasonForm from "../SeasonForm/SeasonForm";
import { isCurrent } from "../../../utils/validation";

export default function SeasonKFL() {
  const [SeasonAll, setSeasonAll] = useState(undefined);
  const [show, setShow] = useState(false);
  const [seasonSeleted, setseasonSeleted] = useState(undefined);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    getAllSeason();
  }, []);

  const getAllSeason = async () => {
    try {
      console.log((await getAllKFLSeasonService()).data);
      var aa = (await getAllKFLSeasonService()).data;
      aa = aa.map((c) => {
        if (isCurrent(c)) {
          return { ...c, isCurrent: true };
        } else {
          return { ...c, isCurrent: false };
        }
      });
      console.log(aa);
      setSeasonAll(aa)
      /* setSeasonAll((await getAllKFLSeasonService()).data); */
    } catch (error) {}
  };

  const handleEditSeason = (g) => {
    setseasonSeleted(g);
    console.log(g);
    setShowEdit(true);
  };

  return (
    <>
      <div className="me-3">
        <PrimaryTitle className={"DefaultCardContainer_Title"}>
          KFL Season
        </PrimaryTitle>

        <div className="row g-3 px-1">
          <div className="col-12">
            <DefaultCard className={" p-1 "}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShow(true);
                }}
                className="d-flex gap-4 justify-content-center"
              >
                <div class="CardNew_icon"></div>
              </div>
            </DefaultCard>
          </div>

          {SeasonAll && (
            <ListSeason
              handleEditSeason={handleEditSeason}
              seasons={SeasonAll}
            />
          )}
        </div>

        <Modal show={show} setShow={setShow}>
          <SeasonForm
            getAllSeason={getAllSeason}
            create={getCreatekflSeasonService}
          />
        </Modal>

        <Modal show={showEdit} setShow={setShowEdit}>
          <SeasonForm
            season={seasonSeleted}
            update={UpdateKFLSeasonService}
            getAllSeason={getAllSeason}
          />
        </Modal>
      </div>
    </>
  );
}
