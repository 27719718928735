import React, { useEffect } from "react";
import "./TeamsCFL.css";
import DefaultCardContainer from "../../molecules/DefaultCardContainer/DefaultCardcontainer";
import TeamCard from "../TeamCard/TeamCard";
import CreateTeamForm from "../CreateTeamsForm/CreateTeamsForm";
import Modal from "../../atoms/Modal/Modal";
import { useState } from "react";
import { getAllTeamsService } from "../../../services/teams.services";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import {
  createCFLTeamsService,
  updateCFLTeamsService,
} from "../../../services/teams.services";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";

const TeamsCFL = ({ handleOpen }) => {
  const [teamsCFL, setTeamsCFL] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  const [teamsAll, setTeamsAll] = useState(undefined);
  const [teamSelected, setTeamSelected] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllTeams();
  }, []);

  const getAllTeams = async () => {
    setTeamsAll(undefined);
    try {
      setIsLoading(true);
      const result = await getAllTeamsService();
      setTeamsAll(result.data);
      setIsLoading(false);
    } catch (error) {}
  };
/* 
  let teamCards = [
    { name: "Indianapolis Colts", acronym: "IND" },
    { name: "Navy Midshipmen", acronym: "NAV" },
    { name: "New England Patriots", acronym: "NE" },
    { name: "New York Jets", acronym: "NYJ" },
    { name: "Philadelphia Eagles", acronym: "PHI" },
    { name: "Pittsburgh Steelers", acronym: "PIT" },
    { name: "San Francisco 49ers", acronym: "SF" },
    { name: "Seattle Seahawks", acronym: "SEA" },
    { name: "Tampa Bay Buccaneers", acronym: "TB" },
    { name: "Washington Redskins", acronym: "WAS" },
    { name: "Chicago Bears", acronym: "CHI" },
    { name: "Cincinnati Bengals", acronym: "CIN" },
    { name: "Cincinnati Bengals", acronym: "CIN" },
    { name: "Cleveland Browns", acronym: "CLE" },
    { name: "Dallas Cowboys", acronym: "DAL" },
  ];
 */
  return (
    <div className="playerCFL">
      <DefaultCardContainer title={"CFL Teams"} modifier={"-heigth"}>
        <div className="playerCFL__modal" onClick={() => setTeamsCFL(true)}>
        <DefaultCard className={"PlayerCard"}>
            <div className="CardNew_icon" />
          </DefaultCard>
        </div>
        {isLoading && (
          <div className="h-center">
            <CustomSpinner></CustomSpinner>
          </div>
        )}
        {teamsAll &&
          teamsAll.map((card) => (
            <TeamCard
              team={card}
              setModalEdit={setModalEdit}
              setTeamSelected={setTeamSelected}
              name={card.name}
              acronym={card.acronym}
            />
          ))}
        <Modal show={teamsCFL} setShow={setTeamsCFL}>
          <CreateTeamForm create={createCFLTeamsService} getAllTeams={getAllTeams} setShow={setTeamsCFL} />
        </Modal>
        <Modal show={modalEdit} setShow={setModalEdit}>
          <CreateTeamForm
            team={teamSelected}
            getAllTeams={getAllTeams}
            setShow={setModalEdit}
            update={updateCFLTeamsService}
          />
        </Modal>
      </DefaultCardContainer>
    </div>
  );
};

export default TeamsCFL;
