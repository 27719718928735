import { useForm } from "react-hook-form";
import Input from "../../atoms/Input/Input";
import { toast } from "react-hot-toast";

export default function SeasonForm({ season, getAllSeason, create, update }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);

    if (season) {
      console.log("editar", data);
      try {
        data.id = season.id;
        await update(data);
        await getAllSeason();
        toast.success("Season Updated!");
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await create(data);
        await getAllSeason();
        reset();
        toast.success("Season Created!");
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <form className="SeasonSetForm_form" onSubmit={handleSubmit(onSubmit)}>
        <div className="SeasonSetForm_block">
          <Input
            label={"Title"}
            register={register}
            name={"title"}
            type={"text"}
            defaultValue={season && season.title}
            rules={{
              required: "seasonal title is required",
            }}
            error={errors.title?.message}
          />
          <Input
            label={"Type"}
            register={register}
            name={"type"}
            type={"text"}
            defaultValue={season && season.type}
            rules={{
              required: "type of season is required ",
            }}
            error={errors.type?.message}
          />
        </div>
        <div className="SeasonSetForm_block">
          <Input
            label={"Start Date:"}
            register={register}
            name={"date_start"}
            type={"date"}
            defaultValue={season && season.date_start}
            rules={{
              required: "start date is required",
            }}
            error={errors.date_start?.message}
          />
          <Input
            label={"End Date:"}
            register={register}
            name={"date_end"}
            type={"date"}
            defaultValue={season && season.date_end}
            rules={{
              required: "end date is required",
            }}
            error={errors.date_end?.message}
          />
        </div>
        {/* <label className="SeasonSetForm_label">
          Start Date:
          <input type="date" name="startDate" className="SeasonSetForm_date" />
        </label>
        <label className="SeasonSetForm_label">
          End Date:
          <input type="date" name="endDate" className="SeasonSetForm_date" />
        </label> */}
        <div className="SeasonSetForm_btnContainer">
          <button type="submit" className="SeasonSetForm_button">
            Submit
          </button>
        </div>
      </form>
    </>
  );
}
