import QuaternaryTitle from "../QuaternaryTitle/QuaternaryTitle";

export default function NameUserAside({ firstName, lastName }) {


    return (
        <>
            {
                firstName && lastName && (

                    <QuaternaryTitle className={"AsideUser_h4 text-capitalize"}>
                        {`${firstName && firstName.split(' ')[0]} ${lastName && lastName.substring(0, 1)}`}
                    </QuaternaryTitle>
                )
            }
        </>
    )
}