import React from "react";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import SecondaryTitle from "../../atoms/SecondaryTitle/SecondaryTitle";
import Pharagraph from "../../atoms/Paragraph/Paragraph";
import SecondarySubtitle from "../../atoms/SecondarySubtitle/SecondarySubtitle";

export const RefundPolicy = () => {
    return (
        <>
            <PrimaryTitle>Refund Policy – Sports World Services</PrimaryTitle>
            <SecondarySubtitle>Effective of as August 10, 2023</SecondarySubtitle>
            <SecondaryTitle>Refund Policy</SecondaryTitle>
            <Pharagraph>This policy is meant to explain the manner and the cases in which Asti Enterprises (“Company”) refunds payments of its clients from the <a href="https://sportsworldservices.com/">Website</a>.</Pharagraph>

            <Pharagraph>The Company refunds its clients’ payments solely via the same payment system, to the same credit/debit card or bank account from which the funds have been originally received.</Pharagraph>

            <Pharagraph>The “Client”, (any entrant or player), is liable for all the refund fees.</Pharagraph>

            <Pharagraph>The full refund is made within 5 business days if the deadline for any contest has not passed and no questions will be asked. Contests can either be, and not limited to, pick ‘em leagues, bracket challenges, bowl challenges, and squares.</Pharagraph>

            <Pharagraph>According to the Company’s policy, the Company has the right to make a refund and to freeze the Client’s account without notification if the Company believes the Client’s activities to be connected with money laundering, terrorism financing, or other criminal activities.</Pharagraph>

            <Pharagraph>By becoming the Client of the Company, the Client agrees not to request or demand a refund or chargeback from their bank or credit card provider at any time while or after using the Company’s services.</Pharagraph>

            <Pharagraph>Any attempt to do so may be considered a breach of this policy. If the Company, however, receives a chargeback for any transaction, it reserves the right to freeze the Client's current balance with the Company and to send the funds back after all fees have been charged.</Pharagraph>

            <Pharagraph>The Company reserves the right to review and/or amend the Refund Policy, at its sole discretion, so please check this page regularly. If the Client continues to use the website services or visits it, they agree automatically with changes introduced to the Refund Policy.</Pharagraph>

            <Pharagraph>If in the event a season or event gets cancelled, for any reason, and does not meet the minimal requirement of a shortened season or event, then a full refund will be issued to all the impacting clients. If any contests do meet the minimal requirements, then no refunds will be made to any of the impacting clients, and the prizes will be paid out in full. You can go to the <a href="https://sportsworldservices.com/LeagueRulebooks">League Rulebooks</a> on the <a href="https://sportsworldservices.com/">Website</a> for more information regarding the minimal requirements in the event of a shortened season. As for the challenges, you can view the requirements of a shortened event on the “Live Updates” Leaderboard page. If any events get cancelled for any of the squares contests, then all the impacting clients will get a full refund. Please be advised that the criteria for each league and challenge varies from contest to contest. However, all contests must have at least a 50% completion rate for it to be official.</Pharagraph>
        </>
    )
}