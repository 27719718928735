import React from "react";
import HeroCFL from "../organisms/HeroCFL/HeroCFL";
import InfoTableCFL from "../organisms/InfoTableCFL/InfoTableCFL";

const CflHome = () => {
   return (
      <>
         <HeroCFL />
         <InfoTableCFL />
      </>
   );
};

export default CflHome;
