import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import LoginPage from "./components/pages/Login/Login";
import DefaultLayout from "./components/templates/DefaultLayout/DefaultLayout";
import HomeLayout from "./components/templates/HomeLayout";
import ForgotPassword from "./components/pages/ForgotPassword/ForgotPassword";
import PasswordResetPage from "./components/pages/PasswordReset/PasswordReset";
import CFLDashboard from "./components/templates/CFLDashBoard/CFLDashBoard";
import PrivateRouter from "./components/utils/PrivateRouter";
import SignUp from "./components/pages/SignUp/SignUp";
import CustomSpinner from "./components/atoms/CustomSpinner/CustomSpinner";
import AdminPanel from "./components/templates/AdminPanel/AdminPanel";
import AdminPanelRouter from "./components/utils/AdminPanelRouter";
import CFLProtectedRouteRouter from "./components/utils/CFLProtectedRouteRouter";
import KFLDashBoard from "./components/templates/KFLDashBoard/KFLDashBoard";

function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="h-center mt-5">
            <CustomSpinner></CustomSpinner>
          </div>
        }
      >
        <Routes>
          <Route
            exact
            path="/login"
            name="Login Page"
            element={<LoginPage />}
          />
          <Route
            exact
            path="/ForgotPassword"
            name="Forgot Password"
            element={<ForgotPassword />}
          />
          <Route
            exact
            path="/password-reset/:uid/:token/"
            name="Forgot Password"
            element={<PasswordResetPage />}
          />
          <Route
            exact
            path="/signup"
            name="Sign Up Page"
            element={<SignUp />}
          />
          <Route
            path="/dashboard/*"
            name="Home"
            element={
              <PrivateRouter>
                <HomeLayout />
              </PrivateRouter>
            }
          />

          <Route
            path="/cfl/:idLeague/*"
            name="Home"
            element={
              <CFLProtectedRouteRouter>
                <CFLDashboard />
              </CFLProtectedRouteRouter>
            }
          />
          <Route
            path="/kfl/:idLeague/*"
            name="Home"
            element={
              <CFLProtectedRouteRouter>
                <KFLDashBoard />
              </CFLProtectedRouteRouter>
            }
          />
          <Route
            path="/general-panel/*"
            name="Home"
            element={
              <AdminPanelRouter>
                <AdminPanel />
              </AdminPanelRouter>
            }
          />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
