import axios from "axios";

export const createCFLDivisionService = async (dataName, datId) => {
  return await axios.post("/cfl/divisions/", {
    name: dataName,
    conference: datId,
  });
};

export const getCFLDivisionService = async () => {
  return await axios.get("/cfl/divisions/");
};

export const updateCFLDivisionService = async (data) => {
  return await axios.put(`/cfl/divisions/${data.id}/`, data);
};

export const createKFLDivisionService = async (data) => {
  return await axios.post("/kfl/divisions/", data);
};

export const updateKFLDivisionService = async (data) => {
  return await axios.put(`/kfl/divisions/${data.id}/`, data);
};

export const getKFLDivisionService = async () => {
  return await axios.get("/kfl/divisions/");
};
