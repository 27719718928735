import React from "react";
import TerteariSubtitle from "../../atoms/TertiarySubtitle/TertiarySubtitle";
import Input from "../../atoms/Input/Input";
import { useForm } from "react-hook-form";
import "./CreateConferenceForm.css";
import { toast } from "react-hot-toast";
import { createCFLConferenceService } from "../../../services/conference.services";

const CreateConferenceForm = ({ getAllConference, create }) => {
   const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
   } = useForm();

   const onSubmit = async (data) => {
      console.log(data);
      try {
         // Make the POST request to the API endpoint
         await create(data);
         toast.success("Conference created successfully");
         await getAllConference();
         reset();
         // Clear the form after successful submission
         // You can add additional logic here to show a success message, etc.
      } catch (error) {
         // Handle errors here if needed
         console.error("Error creating conference:", error);
      }
   };

   return (
      <>
         <form
            className="CreateConferenceForm"
            onSubmit={handleSubmit(onSubmit)}
         >
            <TerteariSubtitle>Name Conference</TerteariSubtitle>
            <div className="line_game-h"></div>
            <Input
               label={"Conference"}
               id={"name"}
               name={"name"}
               register={register}
               placeholder={"name"}
               type="text"
               rules={{
                  required: "name is required",
               }}
               error={errors.name?.message}
            />
            <button className="CreateConferenceForm_Button" type="submit">
               Create
            </button>
         </form>
      </>
   );
};

export default CreateConferenceForm;
