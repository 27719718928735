import { useEffect, useState } from "react";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import Modal from "../../atoms/Modal/Modal";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import { ClosePickWindowKFLWeeksService, CreateKFLWeeksService, UpdateKFLWeeksService, getAllKFLWeeksService } from "../../../services/weeks.services";
import CreateWeeksForm from "../../molecules/CreateWeeksForm/CreateWeeksForm";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
/* import "./WeeksCFL.css" */
import ListWeek from "../../molecules/ListWeek/ListWeek";
import toast from "react-hot-toast";

const WeeksKFL = () => {
  const [showModalCreate, setshowModalCreate] = useState(false);
  const [showModalUpdate, setshowModalUpdate] = useState(false);
  const [weekSeleted, setWeekSeleted] = useState(undefined);

  const [isLoading, setIsLoading] = useState(true);

  const [weeksAll, setWeeksAll] = useState(undefined);
  useEffect(() => {
    getAllWeeksCFL();
  }, []);

  const getAllWeeksCFL = async () => {
    try {
      setIsLoading(true);
      const result = await getAllKFLWeeksService();
      const items = result.data;
      items.sort(function (a, b) {
        if (a.week > b.week) {
          return 1;
        }
        if (a.week < b.week) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      setWeeksAll(items);
      setIsLoading(false);
    } catch (error) { }
  };

  const handleClickGameSeleted = (w) => {
    setshowModalUpdate(true);
    setWeekSeleted(w);
  };

  return (
    <>
      <div className="weekCFL me-3">
        <PrimaryTitle className={"DefaultCardContainer_Title"}>
          KFL week
        </PrimaryTitle>
        <div className="row g-3">
        <div className="col-12">
            <button className="w-100" onClick={async () => {
              try {
                const result = await ClosePickWindowKFLWeeksService()
                console.log(result);
                toast.success(result.data.message)
                await getAllWeeksCFL()
              } catch (error) {

              }
            }}>
              Close Pick Window
            </button>
          </div>
          <div className="col-12">
            <DefaultCard className={" p-1 "}>
              <div
                onClick={() => setshowModalCreate(true)}
                className="d-flex gap-4 justify-content-center"
              >
                <div class="CardNew_icon"></div>
              </div>
            </DefaultCard>
          </div>

          {isLoading && (
            <div className="h-center">
              <CustomSpinner></CustomSpinner>
            </div>
          )}
          {
            weeksAll && ( 
              <ListWeek weeks={weeksAll}  handleClickGameSeleted={handleClickGameSeleted}/>
            )
          }
          {/* {weeksAll &&
            weeksAll.map((w) => (
              <div className="col-12">
                <DefaultCard className={" p-3 "}>
                  <div className="weekCFL_weekCard">
                    <div className="weekCFL_statusContent">
                      <Overline className={"m-0 fs-6"}>
                        {" "}
                        {`WEEK ${w.week}`}
                      </Overline>
                      <Overline className={"m-0 fs-7"}>
                        {" "}
                        {`START : ${w.date_start}`}
                      </Overline>
                      <Overline className={"m-0 fs-7"}>
                        {" "}
                        {`END : ${w.date_end}`}
                      </Overline>
                    </div>
                    <button
                      onClick={() => {
                        console.log("handleClickGameSeleted(g);");
                        handleClickGameSeleted(w);
                      }}
                      style={{
                        width: "2em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0.4em",
                        margin: "0",
                      }}
                    >
                      <i className="fa-regular fa-pen-to-square fa-1x"></i>
                    </button>
                  </div>
                </DefaultCard>
              </div>
            ))} */}
        </div>
      </div>
      <Modal show={showModalCreate} setShow={setshowModalCreate}>
        <CreateWeeksForm create={CreateKFLWeeksService}  getAllWeeksCFL={getAllWeeksCFL} />
      </Modal>

      <Modal show={showModalUpdate} setShow={setshowModalUpdate}>
        <CreateWeeksForm week={weekSeleted} update={UpdateKFLWeeksService} getAllWeeksCFL={getAllWeeksCFL} />
      </Modal>
    </>
  );
};

export default WeeksKFL;
