import React from "react";
import InfoPanelKFL from "../../organisms/InfoPanelKFL/InfoPanelKFL";






const KFLPanel = () => {
    return (
    <>
        <InfoPanelKFL/>
    </>
  );
}

export default KFLPanel;