import React from "react";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import SecondarySubtitle from "../../atoms/SecondarySubtitle/SecondarySubtitle";
import "./SeasonSetForm.css";
import Input from "../../atoms/Input/Input";
import { useForm } from "react-hook-form";
import {
  getAllSeasonService,
  getCreateSeasonService,
} from "../../../services/season.services";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useState } from "react";

const SeasonSetForm = () => {
  const [seasonCurrent, setSeasonCurrent] = useState(undefined);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getSeasonCurrent();
  }, []);

  const getSeasonCurrent = async () => {
    try {
      const result = await getAllSeasonService();
      console.log(result.data[0]);
      setSeasonCurrent(result.data[0]);
    } catch (error) {}
  };

  const onSubmit = async (data) => {
    console.log(data);

    try {
      await getCreateSeasonService(data);
      toast.success("the season was successfully created");
      reset();
      getSeasonCurrent();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="SeasonSetForm">
      <div className=" SeasonSetForm_title">
        <PrimaryTitle>CFL Season</PrimaryTitle>
      </div>
      <form className="SeasonSetForm_form" onSubmit={handleSubmit(onSubmit)}>
          <div className="SeasonSetForm_block">
            <Input
              label={"Title"}
              register={register}
              name={"title"}
              type={"text"}
              rules={{
                required: "seasonal title is required",
              }}
              error={errors.title?.message}
              value={seasonCurrent && seasonCurrent.title}
              disabled={seasonCurrent ? true : false}
            />
            <Input
              label={"Type"}
              register={register}
              name={"type"}
              type={"text"}
              rules={{
                required: "type of season is required ",
              }}
              error={errors.type?.message}
              value={seasonCurrent && seasonCurrent.type}
              disabled={seasonCurrent ? true : false}
            />
          </div>
          <div className="SeasonSetForm_block">
            <Input
              label={"Start Date:"}
              register={register}
              name={"date_start"}
              type={"date"}
              rules={{
                required: "start date is required",
              }}
              error={errors.date_start?.message}
              value={seasonCurrent && seasonCurrent.date_start}
              disabled={seasonCurrent ? true : false}
            />
            <Input
              label={"End Date:"}
              register={register}
              name={"date_end"}
              type={"date"}
              rules={{
                required: "end date is required",
              }}
              error={errors.date_end?.message}
              value={seasonCurrent && seasonCurrent.date_end}
              disabled={seasonCurrent ? true : false}
            />
          </div>
        {/* <label className="SeasonSetForm_label">
          Start Date:
          <input type="date" name="startDate" className="SeasonSetForm_date" />
        </label>
        <label className="SeasonSetForm_label">
          End Date:
          <input type="date" name="endDate" className="SeasonSetForm_date" />
        </label> */}
        <div className="SeasonSetForm_btnContainer">
          <button type="submit" className="SeasonSetForm_button">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default SeasonSetForm;
