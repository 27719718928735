import React, { Suspense } from "react";
import Navbar from "../../organisms/Nav/Nav";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "../../../routes";
import Footer from "../../organisms/Footer";
import "./DefaultLayout.css";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import { TermsAndConditions } from "../../pages/TermsAndConditions/TermsAndConditions";

const DefaultLayout = () => {
  return (
    <section className="wrapper">
      <Navbar />

      <main>
        <Suspense
          fallback={
            <div className="h-center mt-5">
              <CustomSpinner></CustomSpinner>
            </div>
          }
        >
          <Routes>
            {routes.map((route, idx) => {
              return (
                route.element && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={true}
                    name={route.name}
                    element={<route.element />}
                  />
                )
              );
            })}
            <Route path="/" element={<Navigate to="home" replace />} />
            <Route path="/terms-and-conditions/*" name="Terms and Conditions" element={<TermsAndConditions/>} />
          </Routes>
        </Suspense>
      </main>

      <Footer />
    </section>
  );
};

export default DefaultLayout;
