import "./Input.css";
import SenaryTitle from "../SenaryTitle/SenaryTitle";
import Caption from "../../atoms/Caption/Caption";

const Input = ({
  label,
  register,
  placeholder,
  value,
  onChange,
  type,
  error,
  name,
  rules,
  onKeyDown,
  defaultValue,
  disabled,
}) => {
  return (
    <div className="Input_general">
      <label htmlFor={name}>
        <SenaryTitle>{label}</SenaryTitle>
        <br />
      </label>
      <input
        defaultValue={defaultValue && defaultValue}
        {...register(name, rules && rules)}
        type={type}
        value={value}
        placeholder={placeholder}
        /* onChange={onChange && onChange} */
        id={name}
        onKeyDown={onKeyDown}
        disabled={disabled ? disabled : false}
      />
      {error && <Caption className="red-text error_text">{error}</Caption>}
    </div>
  );
};

export default Input;
