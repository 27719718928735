import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import Overline from "../../atoms/Overline/Overline";
import Pharagraph from "../../atoms/Paragraph/Paragraph";

export default function ListGames({ games, handleClickGameSeleted }) {
  if (Array.isArray(games) && games.length === 0) {
    return (
      <>
        <p>NO GAMES</p>
      </>
    );
  }
  if (Array.isArray(games)) {
    return <>
      {
        games.map(g => (
          <DefaultCard key={g.id} className={"w-100 mt-3"}>
            <div className="container_info_card_games ">
              <div className="info_card_games_date">
                <p className="m-0 info_card_games_detail">
                  {`Date : ${g.date_time.substring(0, 11)}`}
                </p>
                <p className="m-0 info_card_games_detail">
                  {`Time : ${g.date_time.substring(11, 16)}`}
                </p>
                <p className="m-0 info_card_games_detail">{g.type}</p>
              </div>
              <div className="info_card_games_teams border_separador w-100">
                <div className="d-flex justify-content-between align-items-center mb-2 -margin-top-2">
                  <Pharagraph className={"info_games_teams m-0"}>
                    {g.home_team.name}
                  </Pharagraph>
                  <Overline className={"fs-5 m-0 text-green"}>{g.score_home}</Overline>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-2">
                  <Pharagraph className={"info_games_teams m-0"}>
                    {g.away_team.name}
                  </Pharagraph>
                  <Overline className={"fs-5 m-0 text-green"}>{g.score_away}</Overline>
                </div>



                <div className="h-center">
                  <button
                    onClick={() => {
                      handleClickGameSeleted(g);
                    }}
                    style={{
                      padding: "0.5em 1em ",
                      width: "2em",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0",
                      marginRight: "1em",
                    }}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <button
                    style={{
                      padding: "0.5em 1em ",
                      width: "2em",
                      display: "flex",
                      justifyContent: "center",
                      margin: "0",
                      alignItems: "center",
                    }}
                  >
                    <i className="fa-regular fa-trash-can"></i>
                  </button>
                </div>
              </div>
            </div>
          </DefaultCard>
        ))
      }
    </>;
  }
}
