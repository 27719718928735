import { useEffect, useState } from "react";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";

export default function MatchUpDetailHistory({ id, getMatchUp }) {
  const [MatchUpDetail, setMatchUpDetail] = useState(undefined);
  const a = [
    { id: 1, p: 16 },
    { id: 2, p: 13 },
    { id: 3, p: 10 },
    { id: 4, p: 7 },
    { id: 5, p: 6 },
    { id: 6, p: 3 },
    { id: 7, p: 2 },
  ];

  useEffect(() => {
    getMatchUpDetail(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMatchUpDetail = async (id) => {
    try {
        
        const re = await getMatchUp(id);
        setMatchUpDetail(re.data);
    } catch (error) {
        
    }
  };

  return (
    <div className="matchup-detail">
      {/* MatchUpDetail.player1 && */}
      {MatchUpDetail && Object.keys(MatchUpDetail).length > 0 && (
        <>
          <div className="row g-3 px-5">
            <div className="col-2 fw-bold">Pts.</div>
            <div className="col-5 fw-bold text-capitalize">
              {MatchUpDetail.player1 &&
                `${MatchUpDetail?.player1?.user?.first_name} ${MatchUpDetail?.player1?.user?.last_name}`}
            </div>
            {/* <div className="col">VS</div> */}
            <div className="col-5 fw-bold text-capitalize">
              {MatchUpDetail.player2 &&
                `${MatchUpDetail?.player2?.user?.first_name} ${MatchUpDetail?.player2?.user?.last_name}`}
            </div>
            <hr />
          </div>
          <div className="row g-3 px-5">
            <div className="col-2">
              {a.map((pick) => (
                <p className="mb-2 py-2 mt-1">
                  {" "}
                  {pick.p}
                  {console.pick}
                </p>
              ))}
            </div>
            <div className="col-5">
              {MatchUpDetail && !MatchUpDetail.player1 && <p> NO PICKS</p>}
              {MatchUpDetail &&
                MatchUpDetail.player1 &&
                a.map((pick) => (
                  <DefaultCard
                    className={`py-2 px-4 mb-2 `}
                  >
                    {MatchUpDetail &&
                      MatchUpDetail.player1 &&
                      MatchUpDetail.player1?.[`pick${pick.id}`]}
                  </DefaultCard>
                ))}
            </div>
            <div className="col-5 mb-2">
              {MatchUpDetail && !MatchUpDetail.player2 && <p> NO PICKS</p>}
              {MatchUpDetail &&
                MatchUpDetail.player2 &&
                a.map((pick) => (
                  <DefaultCard
                    className={`py-2 px-4 mb-2`}
                  >
                    {" "}
                    {MatchUpDetail &&
                      MatchUpDetail.player2 &&
                      MatchUpDetail.player2?.[`pick${pick.id}`]}
                  </DefaultCard>
                ))}
            </div>
          </div>
          <div className="row g-3 px-5 mb-2">
            <hr />
            <div className="col-2">DEF</div>
            <div className="col-5">
              <DefaultCard className="py-2 px-4">
                {MatchUpDetail.player1 && MatchUpDetail?.player1?.defensive}
              </DefaultCard>
            </div>
            <div className="col-5">
              <DefaultCard className="py-2 px-4">
                {MatchUpDetail.player2 && MatchUpDetail?.player2?.defensive}
              </DefaultCard>
            </div>
          </div>
          <div className="row g-3 px-5">
            <div className="col-2">TIE</div>
            <div className="col-5">
              <DefaultCard className="py-2 px-4">
                {MatchUpDetail.player1 && MatchUpDetail?.player1?.tiebreaker}
              </DefaultCard>
            </div>
            <div className="col-5">
              <DefaultCard className="py-2 px-4">
                {MatchUpDetail.player2 && MatchUpDetail?.player2?.tiebreaker}
              </DefaultCard>
            </div>
          </div>
        </>
      )}

      {/* {(MatchUpDetail && !MatchUpDetail?.player1) ||
            (!MatchUpDetail?.player2 && (
               <div className="row">
                  <div className="col-12 text-center">
                     THERE IS STILL MISSING ONE PLAYER TO SELECT THEIR PICKS
                  </div>
               </div>
            ))} */}
    </div>
  );
}
