import { Controller, useForm } from "react-hook-form";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import SelectReact from "../../atoms/SelectReact/SelectReact";
import { useEffect, useState } from "react";
import {
  getAllKFLConferenceService,
  createKFLConferenceService,
} from "../../../services/conference.services";
import CardNew from "../NewCard/CardNew";
import Modal from "../../atoms/Modal/Modal";
import CreateConferenceForm from "../CreateConferenceForm/CreateConferenceForm";
import Overline from "../../atoms/Overline/Overline";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import CreateDivisionForm from "../CreateDivisionForm/CreateDivisionForm";
import {
  createKFLDivisionService,
  getKFLDivisionService,
  updateKFLDivisionService,
} from "../../../services/divisions.services";
import PlayerKFL from "../../organisms/PlayersKFL/PlayersKFL";

export default function CoferenceKFL() {
  const [conferenceAll, setConferenceAll] = useState(undefined);
  const [conference, setConference] = useState(false);

  const [isLoading, setIsLoading] = useState({
    division: false,
  });

  const [actualConference, setActualConference] = useState(null);
  const [divisionAll, setDivisionAll] = useState(undefined);

  const [showDivisions, setShowDivisions] = useState(undefined);
  const [division, setDivision] = useState(false);
  const [divisionEdit, setDivisionEdit] = useState(false);

  const [divisionSelected, setDivisionSelected] = useState(undefined);
  const { control, errors } = useForm();
  useEffect(() => {
    getAllConference();
    getAllDivisions();
  }, []);

  useEffect(() => {
    if (divisionAll && conferenceAll) {
      console.log("filtar Vivisiones");
      filterDivision(actualConference);
    }
  }, [actualConference]);

  const filterDivision = async (id) => {
    setIsLoading((state) => {
      return { ...state, division: true };
    });
    setShowDivisions(undefined);
    const divisiones = (await getKFLDivisionService()).data;
    console.log(actualConference);
    console.log(divisiones.filter((d) => d.conference === actualConference));
    setShowDivisions(
      divisiones.filter((d) => d.conference === actualConference)
    );
    setIsLoading((state) => {
      return { ...state, division: undefined };
    });
  };

  const getAllConference = async () => {
    setConferenceAll(undefined);
    try {
      const result = await getAllKFLConferenceService();

      setConferenceAll(
        result.data.map((value) => ({
          value: value.id,
          label: value.name,
        }))
      );
    } catch (error) {
      // Handle error
    }
  };

  const getAllDivisions = async () => {
    try {
      const result = await getKFLDivisionService();
      setDivisionAll(result.data);
    } catch (error) {
      // Handle error
    }
  };

  return (
    <>
      <div className="ConferenceForm">
        <PrimaryTitle>Create Conference</PrimaryTitle>
        <div className="ConferenceForm__row">
          <div className="ConferenceForm__col">
            <Controller
              control={control}
              name="Conference_select"
              render={({ field: { onChange, name, ref } }) => (
                <SelectReact
                  onChange={(value) => {
                    onChange(value);
                    setActualConference(value);
                  }}
                  isDisabled={isLoading.division}
                  name={name}
                  label={"Conference"}
                  options={conferenceAll && conferenceAll}
                />
              )}
            />
          </div>
          <div
            className="ConferenceForm__addBtn"
            onClick={() => setConference(true)}
            style={{ cursor: "pointer" }}
          >
            <CardNew />
          </div>
        </div>

        <div className="row mx-2 g-2">
          <div className="col-12">
            <div
              className=""
              style={{ cursor: "pointer" }}
              onClick={() => setDivision(true)}
            >
              <DefaultCard className={"py-1 h-center"}>
                <div className="CardNew_icon " />
              </DefaultCard>
            </div>
          </div>
          {isLoading.division === true && <Overline> Loading. . .</Overline>}
          {showDivisions &&
            Array.isArray(showDivisions) &&
            showDivisions.length === 0 && (
              <div className="col-12">
                <Overline> No hay divisiones en esta conference</Overline>
              </div>
            )}

          {showDivisions &&
            Array.isArray(showDivisions) &&
            showDivisions.length !== 0 &&
            showDivisions.map((d) => (
              <div className="col-12">
                <DefaultCard className={"px-2  py-2"}>
                  <div className="d-flex justify-content-between align-items-center">
                    <Overline className={"m-0 fs-6"}> {d.name}</Overline>
                    <button
                      onClick={() => {
                        console.log(d);

                        setDivisionSelected(d);
                        setDivisionEdit(true);
                      }}
                      style={{
                        padding: "0.5em 1em ",
                        width: "2em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0",
                        marginRight: "1em",
                      }}
                    >
                      <i className="fa-regular fa-pen-to-square"></i>
                    </button>
                  </div>
                </DefaultCard>
              </div>
            ))}
        </div>

        <PlayerKFL divisionAll={divisionAll}
          getAllConferences={conferenceAll}
        />

        <Modal show={conference} setShow={setConference}>
          <CreateConferenceForm
            create={createKFLConferenceService}
            getAllConference={getAllConference}
          />
        </Modal>

        <Modal show={division} setShow={setDivision}>
          <CreateDivisionForm
            conferenceData={conferenceAll && conferenceAll}
            create={createKFLDivisionService}
            filterDivision={filterDivision}
          />
        </Modal>
        <Modal show={divisionEdit} setShow={setDivisionEdit}>
          <CreateDivisionForm
            setShow={setDivisionEdit}
            conferenceData={conferenceAll && conferenceAll}
            update={updateKFLDivisionService}
            division={divisionSelected}
            filterDivision={filterDivision}
          />
        </Modal>
      </div>
    </>
  );
}
