import React from "react";
import pass from "../../../assets/img/pass.svg";
import "./CardLeaguePack.css";
import { Link } from "react-router-dom";

export default function CardLeaguePack({ league }) {
   return (
      <Link
         to={`/${league.league.name}/${league.league.id}`}
         className="card_packs_league"
      >
         {/* <div className="card_packs_league"> */}
         <img src={pass} alt="" />
         <div className="text_card_league">
            <span className="text_card_title">{league.league.name}</span>
            {league.conference && (
               <span className="text_card_confe">
                  {" "}
                  {`${league.conference} - ${league.division}`}
               </span>
            )}
            <span className="text_card_week">
               {" "}
               {`Week ${league.week}`} – REGULAR
            </span>
         </div>
         <div className="info_card_league">
            <span className="info_status"> ACTIVE</span>
            <span className="info_points"> {league.score} PTS</span>
         </div>
         {/* </div> */}
      </Link>
   );
}
