import axios from "axios";

export const getCreatePickCFLService = async (data) => {
  return await axios.post("/cfl/picks/", data);
};
export const getCreatePickKFLService = async (data) => {
  return await axios.post("/kfl/picks/", data);
};

export const getUpdatePickKFLService = async (data) => {
  return await axios.patch(`/kfl/picks/${data.id}/`, data);
};

export const getUpdatePickCFLService = async (data) => {
  return await axios.put(`/cfl/picks/${data.id}/`, data);
};

export const getPickDetailCFLByUserService = async (id) => {
  return await axios.get(`/cfl/picksbyuser/${id}/`);
};

export const getPickDetailKFLByUserService = async (id) => {
  return await axios.get(`/kfl/picksbyuser/${id}/`);
};


