import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import Overline from "../../atoms/Overline/Overline";
import TertiarySubtitle from "../../atoms/TertiarySubtitle/TertiarySubtitle";

export default function PicksDetailByUser({ pickDetails, cardStyle }) {
   return (
      <>
         {pickDetails && (
            <>
               {[
                  { id: 1, p: 16 },
                  { id: 2, p: 13 },
                  { id: 3, p: 10 },
                  { id: 4, p: 7 },
                  { id: 5, p: 6 },
                  { id: 6, p: 3 },
                  { id: 7, p: 2 },
               ].map((p) => (
                  <DefaultCard className={`${cardStyle}`}>
                     <div className="card_pick_content">
                        {/* <div className=""> */}
                        <TertiarySubtitle className={"mb-1 text-center"}>
                           {pickDetails[`pick${p.id}`].team.name}
                        </TertiarySubtitle>
                        <div className="d-flex justify-content-center">
                           <Overline className={"mb-1"}>
                              {" "}
                              {pickDetails[`pick${p.id}`].game.type}
                           </Overline>
                           <Overline className={"m-0 text-green ms-2"}>
                              {p.p}Pts
                           </Overline>
                           {/* </div> */}
                        </div>
                        {/* <div className="av_pick_team">NAV</div> */}
                     </div>
                  </DefaultCard>
               ))}

               {/* 
          <DefaultCard className={cardStyle}>
            <div className="card_pick_content">
              <div className="">
                <TertiarySubtitle className={"mb-1"}>
                  {pickDetails.pick2.team}
                </TertiarySubtitle>
                <div className="d-flex">
                  <Overline className={"mb-1"}>main</Overline>
                  <Overline className={"m-0 text-green ms-2"}>13Pts</Overline>
                </div>
              </div>
              <div className="av_pick_team">NAV</div>
            </div>
          </DefaultCard>

          <DefaultCard className={cardStyle}>
            <div className="card_pick_content">
              <div className="">
                <TertiarySubtitle className={"mb-1"}>
                  {pickDetails.pick3.team}
                </TertiarySubtitle>
                <div className="d-flex">
                  <Overline className={"mb-1"}>main</Overline>
                  <Overline className={"m-0 text-green ms-2"}>10Pts</Overline>
                </div>
              </div>
              <div className="av_pick_team">NAV</div>
            </div>
          </DefaultCard>

          <DefaultCard className={cardStyle}>
            <div className="card_pick_content">
              <div className="">
                <TertiarySubtitle className={"mb-1"}>
                  {pickDetails.pick4.team}
                </TertiarySubtitle>
                <div className="d-flex">
                  <Overline className={"mb-1"}>main</Overline>
                  <Overline className={"m-0 text-green ms-2"}>7Pts</Overline>
                </div>
              </div>
              <div className="av_pick_team">NAV</div>
            </div>
          </DefaultCard>

          <DefaultCard className={cardStyle}>
            <div className="card_pick_content">
              <div className="">
                <TertiarySubtitle className={"mb-1"}>
                  {pickDetails.pick5.team}
                </TertiarySubtitle>
                <div className="d-flex">
                  <Overline className={"mb-1"}>main</Overline>
                  <Overline className={"m-0 text-green ms-2"}>6Pts</Overline>
                </div>
              </div>
              <div className="av_pick_team">NAV</div>
            </div>
          </DefaultCard>

          <DefaultCard className={cardStyle}>
            <div className="card_pick_content">
              <div className="">
                <TertiarySubtitle className={"mb-1"}>
                  {pickDetails.pick6.team}
                </TertiarySubtitle>
                <div className="d-flex">
                  <Overline className={"mb-1"}>main</Overline>
                  <Overline className={"m-0 text-green ms-2"}>3Pts</Overline>
                </div>
              </div>
              <div className="av_pick_team">NAV</div>
            </div>
          </DefaultCard>

          <DefaultCard className={cardStyle}>
            <div className="card_pick_content">
              <div className="">
                <TertiarySubtitle className={"mb-1"}>
                  {pickDetails.pick7.team}
                </TertiarySubtitle>
                <div className="d-flex">
                  <Overline className={"mb-1"}>main</Overline>
                  <Overline className={"m-0 text-green ms-2"}>2Pts</Overline>
                </div>
              </div>
              <div className="av_pick_team">NAV</div>
            </div>
          </DefaultCard> */}

               <DefaultCard className={cardStyle}>
                  <div className="card_pick_content">
                     <div className="">
                        <TertiarySubtitle className={"mb-1"}>
                           {pickDetails?.defensive_pick?.name}
                        </TertiarySubtitle>
                        <div className="d-flex">
                           <Overline className={"mb-1"}>DEFENSIVE</Overline>
                           <Overline className={" m-0 text-green ms-2"}>
                              tiebreaker : {pickDetails.tiebreaker}
                           </Overline>
                        </div>
                     </div>
                  </div>
               </DefaultCard>
            </>
         )}
         <div className="separator"></div>

         {/* {[1, 2, 3, 4, 5, 6, 7, 8].map((c) => (
        <DefaultCard className={cardStyle}>
          <div className="card_pick_content">
            <div className="">
              <TertiarySubtitle className={"mb-1"}>
                Navy Midshipmen
              </TertiarySubtitle>
              <div className="d-flex">
                <Overline className={"mb-1"}>main</Overline>
                <Overline className={"m-0 text-green ms-2"}>16 trust</Overline>
              </div>
            </div>
            <div className="av_pick_team">NAV</div>
          </div>
        </DefaultCard>
      ))} */}
      </>
   );
}
