import React, { Suspense, useContext, useEffect, useState } from "react";
import "../templates/HomeLayout.css";
import { Logo } from "../atoms/Logo/Logo";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import FormNewPackLeague from "../organisms/FormNewPackLeague/FormNewPackLeague";
import AuthContext from "../../context/AuthContext";
import CustomSpinner from "../atoms/CustomSpinner/CustomSpinner";
import CancelPage from "../pages/Payments/CancelPage";
import SuccessPage from "../pages/Payments/SuccessPage";
import LeagueActive from "../pages/LeagueActive/LeagueActive";
import { Toaster } from "react-hot-toast";
import { getUsersByIdService } from "../../services/user.services";
import Btn_xl from "../atoms/Btn_xl/Btn_xl";

export default function HomeLayout() {
   const { user, logoutUser } = useContext(AuthContext);
   const navigate = useNavigate();
   const [userD, setUserD] = useState(undefined);

   useEffect(() => {
      getUseIn();
   }, []);

   const getUseIn = async () => {
      try {
         setUserD((await getUsersByIdService(user.user_id)).data);
      } catch (error) {
         console.log(error);
      }
   };

   return (
      <>
         <div className="nav_home_layout container container-cx mx-auto pt-3">
            {/* <NavIcons /> */}
            <Logo />
            <div className="nav_menu_home_layout">
               {user && user.is_superuser === true && (
                  <Link
                     className="border rounded-3 p-2 me-2"
                     to={"/general-panel/"}
                  >
                     <i class="fa-solid fa-gear me-2"></i>AdminPanel
                  </Link>
               )}
               <Btn_xl
                  className="logout--btn"
                  onClick={async () => {
                     await logoutUser();
                     navigate("/Login");
                  }}
               >
                  Logout{" "}
                  <i className="fa-solid fa-arrow-right-from-bracket ms-1"></i>
               </Btn_xl>
            </div>
         </div>
         <div className="container container-cx vh-75 d-flex">
            <div
               className="card_home w-100 position-relative"
               //  style={{ minHeight: "66vh" }}
            >
               <div className="card-body">
                  <h2
                     className="text-white position-absolute top-0 start-50 translate-middle px-5 py-2 text-capitalize text-center"
                     style={{ background: "#008285", borderRadius: "9px" }}
                  >
                     {userD &&
                        `Hi ${
                           userD.first_name.split(" ")[0]
                        } ${userD.last_name.substring(0, 1)}`}
                  </h2>
                  <Suspense
                     fallback={
                        <div className="h-center">
                           <CustomSpinner></CustomSpinner>
                        </div>
                     }
                  >
                     <Routes>
                        <Route
                           path={"/"}
                           exact={true}
                           name={"Home"}
                           element={<LeagueActive />}
                        />
                        <Route
                           path={"/new"}
                           exact={true}
                           name={"New Pack"}
                           element={<FormNewPackLeague stepCurrent={1} />}
                        />

                        <Route
                           path={"/pay-canceled"}
                           exact={true}
                           name={"Canceled Pay"}
                           element={<CancelPage />}
                        />

                        <Route
                           path={"/pay-success"}
                           exact={true}
                           name={"success Pay"}
                           element={<SuccessPage />}
                        />
                     </Routes>
                  </Suspense>
               </div>
            </div>
            <Toaster
               toastOptions={{
                  className: "",
                  style: {
                     border: "1px solid #008285",
                     padding: "16px",
                     backgroundColor: "var(--bg-color)",
                     color: "var(--global-font",
                  },
               }}
               position="top-center"
               reverseOrder={false}
            />
         </div>
      </>
   );
}
