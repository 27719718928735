import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import "./CardContainer.css";
import React, { forwardRef } from "react";

const CardContainer = forwardRef(({ title, children, className }, ref) => {
   return (
      <div className={`gamesOfTheWeekCFL ${className}`}>
         <PrimaryTitle className={"gamesOfTheWeekCFL_title"}>
            {title}
         </PrimaryTitle>
         <div className="gamesOfTheWeekCFL_CardContent" ref={ref}>
            {children}
         </div>
      </div>
   );
});

export default CardContainer;
