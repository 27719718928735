import React from "react";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import SecondaryTitle from "../../atoms/SecondaryTitle/SecondaryTitle";
import TertiaryTitle from "../../atoms/TertiaryTitle/TertiaryTitle";
import Pharagraph from "../../atoms/Paragraph/Paragraph";
import QuaternaryTitle from "../../atoms/QuaternaryTitle/QuaternaryTitle";
import SecondarySubTitle from "../../atoms/SecondarySubtitle/SecondarySubtitle"
export const PrivacyPolicy = () => {
    return (
        <>
            <PrimaryTitle>Privacy Policy</PrimaryTitle>
            <SecondarySubTitle>Effective date: August 10, 2023</SecondarySubTitle>

            <SecondaryTitle>About Us</SecondaryTitle>
            <Pharagraph>Asti Enterprises ("us", "we", or "our") operates the <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> website (hereinafter referred to as the "Service").</Pharagraph>

            <Pharagraph>This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.</Pharagraph>

            <Pharagraph>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy.</Pharagraph>

            <SecondaryTitle>Definitions</SecondaryTitle>
            <ul>
                <li><strong>Service</strong> - Service is the <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> website operated by Asti Enterprises</li>
                <li><strong>Personal Data</strong> - Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</li>
                <li><strong>Usage Data</strong> - Usage Data is data collected automatically either generated using the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
                <li><strong>Cookies</strong> - Cookies are small files stored on your device (computer or mobile device).</li>
            </ul>

            <SecondaryTitle>Information Collection and Use</SecondaryTitle>
            <Pharagraph>We collect several different types of information for various purposes to provide and improve our Service to you.</Pharagraph>

            <TertiaryTitle>Types of Data Collected</TertiaryTitle>
            <QuaternaryTitle>Personal Data</QuaternaryTitle>
            <Pharagraph>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</Pharagraph>
            <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone (Cellular and/or Landline) number</li>
                <li>Cookies and Usage Data</li>
            </ul>

            <Pharagraph>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send or by contacting us.</Pharagraph>

            <QuaternaryTitle>Usage Data</QuaternaryTitle>

            <Pharagraph>
                We may also collect information on how the Service is accessed and used
                ("Usage Data"). This Usage Data may include information such as your
                computer's Internet Protocol address (for example, IP address), browser
                type, browser version, the pages of our Service that you visit, the time and
                date of your visit, the time spent on those pages, unique device identifiers
                and other diagnostic data.
            </Pharagraph>
            <QuaternaryTitle>Tracking And Cookies Data</QuaternaryTitle>
            <Pharagraph>
                We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
            </Pharagraph>
            <Pharagraph>
                Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and analyze our Service.
            </Pharagraph>
            <Pharagraph>
                You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
            </Pharagraph>
            <ul>
                <li><strong>Session Cookies.</strong> - Service is the <a href="https://sportsworldservices.com/">https://sportsworldservices.com/</a> website operated by Asti Enterprises</li>
                <li><strong>Preference Cookies.</strong> - Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</li>
                <li><strong>Usage Data</strong> - Usage Data is data collected automatically either generated using the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
            </ul>
            <SecondaryTitle>Use of Data</SecondaryTitle>
            <Pharagraph>Asti Enterprises uses the collected data for various purposes:</Pharagraph>
            <ul>
                <li>To provide and maintain the Service</li>
                <li>To notify you about changes to the Service</li>
                <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                <li>To provide customer support</li>
                <li>To gather analysis or valuable information so that we can improve our Service</li>
                <li>To monitor the usage of our Service</li>
                <li>To detect, prevent and address technical issues</li>
                <li>To provide you with news, special offers and general information about other goods, services, and events which we offer that are like those that you have already purchased or enquired about unless you have opted not to receive such information</li>
            </ul>

            <SecondaryTitle>Transfer Of Data</SecondaryTitle>
            <Pharagraph>
                Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
            </Pharagraph>
            <Pharagraph>
                If you are located outside the United States of America and choose to provide information to us, please note that we transfer the data, including Personal Data, to the United States of America and process it there.
            </Pharagraph>
            <Pharagraph>
                Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
            </Pharagraph>
            <Pharagraph>
                Asti Enterprises will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
            </Pharagraph>
            <SecondaryTitle>Disclosure of Data</SecondaryTitle>
            <TertiaryTitle>Legal Requirements</TertiaryTitle>
            <Pharagraph>Asti Enterprises may disclose your Personal Data in the good faith belief that such action is necessary to:</Pharagraph>
            <ul>
                <li>To comply with a legal obligation</li>
                <li>To protect and defend the rights or property of Asti Enterprises</li>
                <li>To protect the personal safety of users of the Service or the public</li>
                <li>To protect against legal liability</li>
            </ul>
            <SecondaryTitle>Security of Data</SecondaryTitle>
            <Pharagraph>
                The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
            </Pharagraph>
            <SecondaryTitle>Service Providers</SecondaryTitle>
            <Pharagraph>
                We may contact and/or contract third party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services, or assist us in analyzing how our Service is used.
            </Pharagraph>
            <Pharagraph>
                These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
            </Pharagraph>
            <TertiaryTitle>Analytics</TertiaryTitle>
            <Pharagraph>
                We may use third-party Service Providers to monitor and analyze the use of our Service.
            </Pharagraph>
            <ul>
                <li>
                    <QuaternaryTitle>Google Analytics</QuaternaryTitle>

                    <Pharagraph>
                        Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.
                    </Pharagraph>
                    <Pharagraph>
                        You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.
                    </Pharagraph>
                    <Pharagraph>
                        For more information on the privacy practices of Google, please visit the <a href="https://policies.google.com/privacy?hl=en">Google Privacy &amp; Terms</a> web page.
                    </Pharagraph>
                </li>
            </ul>
            <SecondaryTitle>Links to Other Sites</SecondaryTitle>
            <Pharagraph>Our Service may contain links to other sites that are not operated by us. If you click a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</Pharagraph>
            <Pharagraph>We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</Pharagraph>
            <SecondaryTitle>Children's Privacy (Under 18 years of age)</SecondaryTitle>
            <Pharagraph>
                While Our Service does not allow anyone under the age of 18 to participate in any contests and events, and not limited to, any pick ‘em leagues, bracket challenges, squares, and bowl challenges run on this website. Our Service does not address anyone under the age of 18 ("Children").
                </Pharagraph>
            <Pharagraph>
                We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
            </Pharagraph>
            <SecondaryTitle>Changes to This Privacy Policy</SecondaryTitle>
            <Pharagraph>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. 
            </Pharagraph>
            <Pharagraph>
            We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.
            </Pharagraph>
            <Pharagraph>
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            </Pharagraph>
            <SecondaryTitle>Contact Us</SecondaryTitle>
            <Pharagraph>
            If you have any questions about this Privacy Policy, please contact us via email at <strong>astienterprises@hotmail.com.</strong>
            </Pharagraph>
        </>
    );

}