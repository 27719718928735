import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { RequestPasswordResetUserService } from "../../../services/user.services";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import Input from "../../atoms/Input/Input";
import Caption from "../../atoms/Caption/Caption";
import Btn_xl from "../../atoms/Btn_xl/Btn_xl";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import "./ForgotPassword.css";

export default function ForgotPasswordForm() {
   const [errorResponse, setErrorResponse] = useState(undefined);
   const [isSuccess, setIsSuccess] = useState(false);
   const {
      register,
      handleSubmit,
      formState: { errors, isSubmitting },
   } = useForm();

   const onSubmit = async (data) => {
      console.log(data);
      setErrorResponse(undefined);
      try {
         const result = await RequestPasswordResetUserService(data);
         console.log(result.data);
         setIsSuccess(true);
      } catch (error) {
         if (error.response.status === 400) {
            console.log(error.response.data);
            setErrorResponse(error.response.data.error);
         }
      }
   };

   return (
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
         <Paragraph className="forgot--p">
            Did you forget your password? No worries, we’ve got you covered.
            Just pop in your email address below and we’ll whisk a link to reset
            your password straight to your inbox:
         </Paragraph>
         <Input
            register={register}
            label="Email"
            placeholder="Enter email"
            type="email"
            name="email"
            rules={{
               required: "Email is required",
               pattern: {
                  value: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                  message: "Enter a valid email", // JS only: <p>error message</p> TS only support string
               },
            }}
            error={errors.email?.message}
         />
         {/* <div className="mb-3">
            <label
               htmlFor="exampleFormControlInput1"
               className="form-label fw-semibold"
            >
               Email
            </label>
            <input
               type="email"
               className="form-control"
               placeholder="Email"
               {...register("email", {
                  required: "email is required",
                  pattern: {
                     value: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                     message: "Enter a valid email", // JS only: <p>error message</p> TS only support string
                  },
               })}
            />
            <div className="ms-2 form-text text-danger">
               {errors.email?.message}
            </div>
         </div> */}
         {isSuccess && (
            <Caption>
               We’ve sent a link to reset your password straight to your email
               address. If it doesn’t appear within a few minutes, take a peek
               in your spam folder or try again. We’ll make sure you’re back on
               track in no time!
            </Caption>
         )}
         {isSubmitting && (
            <div className="h-center">
               <CustomSpinner></CustomSpinner>
            </div>
         )}
         {errorResponse && (
            <Caption className="red-text"> {errorResponse}</Caption>
         )}

         <Btn_xl type="submit" className="login--btn">
            Send Email
         </Btn_xl>
      </form>
   );
}
