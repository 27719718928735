import React from 'react';
import QuaternaryTitle from "../../atoms/QuaternaryTitle/QuaternaryTitle";
import SecondarySubtitle from "../../atoms/SecondarySubtitle/SecondarySubtitle";
import "./HeroCFL_infoDeadline.css"

const HeroCFLInfoDeadline = ({ nameDeadline, initDate, endDate }) => (
  <div className="HeroCFLInfoDeadline_Season">
    <QuaternaryTitle>
      {nameDeadline}
    </QuaternaryTitle>
    <div className="HeroCFLInfoDeadline_status">
      <SecondarySubtitle className={"HeroCFLInfoDeadline_seasonType"}>
        {initDate}
      </SecondarySubtitle>
      <SecondarySubtitle className={"HeroCFLInfoDeadline_seasonType "}>
        {endDate}
      </SecondarySubtitle>
    </div>
  </div>
);

export default HeroCFLInfoDeadline;