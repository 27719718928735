import React from 'react';
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import SecondarySubtitle from "../../atoms/SecondarySubtitle/SecondarySubtitle";
import "./HeroCFL_SeasonInfo.css"

const HeroCFLSeasonInfo = ({ seasonNum, weekNum, SeasonType }) => (
  <div className="HeroCFLSeasonInfo_Season">
    <PrimaryTitle>
      {seasonNum}
    </PrimaryTitle>
    <div className="HeroCFLSeasonInfo_status">
      <SecondarySubtitle className={"HeroCFLSeasonInfo_seasonType"}>
        {SeasonType}
      </SecondarySubtitle>
      <SecondarySubtitle>
        WEEK {weekNum}
      </SecondarySubtitle>
    </div>
  </div>
);

export default HeroCFLSeasonInfo;