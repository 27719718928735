import React from "react";
import Upgames_Section from "../organisms/Upgames_Section/Upgames_Section";
import Button from "../atoms/Btn_xl/Button";
import { useNavigate } from "react-router-dom";

export default function StepOneNewLeague({ setstepCurrent }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="mt-5">
        <div className="header_form_new">
          <div className="step_number">
            <span>1</span>
          </div>
          <div className="step_text">
            <p> – Upcoming Games –</p>
          </div>
        </div>

        <Upgames_Section />
        <p className="notes_step">
          We will walk you through the process of placing bets on your favorite
          sports league. Please read each step’s instructions carefully. Here is
          the general information of the available and upcoming games, go to
          view more and see the calendar for detailed information.
        </p>
        <div className="footer_form_new">
          <Button
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Back «
          </Button>

          <Button
            onClick={() => {
              setstepCurrent(2);
            }}
          >
            Next »
          </Button>
        </div>
      </div>
    </>
  );
}
