import { useState } from "react";
import "./PickSelect.css";
import Overline from "../Overline/Overline";
import Caption from "../Caption/Caption";

export default function PickSelect({
  games,
  setGames,
  setValue,
  name,
  label,
  point,
  error,
  clearErrors,
  gameName,
  pick,
  isFlex
}) {
  const [open, setOpen] = useState(false);
  const [openGame, setOpenGame] = useState(false);

  const [gameSelected, setGameSelected] = useState(pick ? pick.game : undefined);
  const [teamSeleted, setTeamSeleted] = useState(pick ? pick.team : undefined);

  return (
    <>
      <div className="select_pick mt-2">
        <label className="ms-3"> {label} </label>
        <Overline
          className={"m-1 d-inline-block ms-4 text-green fs-5 float-end"}
        >
          {point && `${point}Pts`}
        </Overline>

        <div
          onClick={() => {
            setOpen(!open);
            try {

              if (teamSeleted) {
                const algo = games.find(({ id }) => id === gameSelected.id);
                if (!algo) {
                  setGames((state) => [...state, gameSelected]);
                }
              } else {
                /* setGameSelected(undefined); */
              }
            } catch (error) {
              console.log(error);
            }
          }}
          className="select_pick_btn"
        >
          {teamSeleted ? (
            <>
              {teamSeleted.name}
              <Overline className={"m-0 d-inline-block ms-4 text-green fs-5"}>
                {gameSelected?.type}
              </Overline>
              {

                isFlex && teamSeleted && <div className="form-check" onClick={(e) => {
                  e.stopPropagation()
                }}>
                  <input className="form-check-input me-3 p-0" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                  <label className="form-check-label m-0" for="flexSwitchCheckDefault"> is Flex? </label>
                </div>
              }
            </>
          ) : (
            "Select Game"
          )}
          <i
            className={
              !open
                ? "ms-2 fa-solid fa-chevron-down"
                : "ms-2 fa-solid fa-chevron-up"
            }
          ></i>
        </div>
        {open && games && (
          <div className="select_pick_content">
            {games.map((g) => (
              <div
                className={
                  g.id === gameSelected?.id
                    ? "selec_pick_item_selected"
                    : "selec_pick_item"
                }
                onClick={() => {
                  setGameSelected(g);
                  setValue(gameName, g.id);
                  console.log(gameName, g.id);
                  setOpen(false);
                  setOpenGame(true);
                }}
              >
                <i className="fa-solid fa-football me-2"></i>
                {`${g.home_team.name} VS ${g.away_team.name}`}
                <Overline
                  className={
                    "m-0 d-inline-block ms-4 text-green fs-5 fs-bold float-end "
                  }
                >
                  {`${g.type}`}
                </Overline>
              </div>
            ))}
          </div>
        )}
        {openGame && (
          <>
            <div className={"select_pick_content"}>
              <div
                onClick={() => {
                  setTeamSeleted(gameSelected.home_team);
                  setValue(name, gameSelected.home_team.id);
                  clearErrors(name);
                  try {
                    setGames((preGames) => {
                      return preGames.filter((g) => g.id !== gameSelected.id);
                    });
                  } catch (error) {
                    console.log(error);
                  }
                  setOpenGame(false);
                  setOpen(false);
                }}
                className={
                  teamSeleted === gameSelected?.home_team
                    ? "select_pick_item_team_selected"
                    : "select_pick_item_team"
                }
              >
                {gameSelected.home_team.name}
              </div>
              <div
                className={
                  teamSeleted === gameSelected.away_team
                    ? "select_pick_item_team_selected"
                    : "select_pick_item_team"
                }
                onClick={() => {
                  setTeamSeleted(gameSelected.away_team);
                  setValue(name, gameSelected.away_team.id);
                  clearErrors(name);

                  try {
                    setGames((preGames) => {
                      return preGames.filter((g) => g.id !== gameSelected.id);
                    });
                  } catch (error) {
                    console.log(error);
                  }
                  setOpenGame(false);

                  setOpen(false);
                }}
              >
                {gameSelected.away_team.name}
              </div>
            </div>
          </>
        )}
      </div>
      {/*  {
        isFlex && teamSeleted && <div className="mt-2 form-check ms-4 mb-2">
          <input className="form-check-input me-3 p-0" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
          <label className="form-check-label m-0" for="flexSwitchCheckDefault"> is Flex? </label>
        </div>
      } */}
      {error && (
        <Caption className="red-text error_text m-0 mt-2">{error}</Caption>
      )}
    </>
  );
}
