import React, { useContext, useEffect, useState } from "react";
import "./Aside_LastGame.css";
import Caption from "../../atoms/Caption/Caption";
import TertiarySubtitle from "../../atoms/TertiarySubtitle/TertiarySubtitle";
import Overline from "../../atoms/Overline/Overline";
import SixthTitle from "../../atoms/SixthTitle/SixthTitle";
import AuthContext from "../../../context/AuthContext";
import {
   getLastGameByUserCFLService,
   getLastGameByUserKFLService,
} from "../../../services/games.services";
import Modal from "../../atoms/Modal/Modal";
import {
   getCFLWeeksNowService,
   getKFLWeeksNowService,
} from "../../../services/weeks.services";

const AsideLastGame = ({ league }) => {
   const [lastGame, setlastGame] = useState(undefined);
   const [leagueL, setLeague] = useState(league === "division" ? "cfl" : "kfl");
   const [IsOpen, setIsOpen] = useState(undefined);

   const [errorLastGame, seterrorLastGame] = useState(undefined);

   const { user, modalManualPay, setModalManualPay, setIdMatch } =
      useContext(AuthContext);

   useEffect(() => {
      getLastGame();
   }, []);

   const getLastGame = async () => {
      try {
         if (leagueL === "cfl") {
            const week = (await getCFLWeeksNowService()).data.isOpen;
            console.log(week);
            if (week) {
               setIsOpen(true);
            } else {
               setIsOpen(false);
            }
            const res = await getLastGameByUserCFLService(user.user_id, league);
            setlastGame(res.data);
         }
         if (leagueL === "kfl") {
            const week = (await getKFLWeeksNowService()).data.isOpen;
            console.log(week);

            if (week) {
               setIsOpen(true);
            } else {
               setIsOpen(false);
            }
            const res = await getLastGameByUserKFLService(user.user_id, league);
            setlastGame(res.data);
         }
      } catch (error) {
         console.log(error);
         if (error.response.status >= 400) {
            seterrorLastGame(error.response.data.error);
         }
      }
   };

   return (
      <div
         onClick={() => {
            if (lastGame && IsOpen === false) {
               console.log("ssss");
               setModalManualPay(true);
               setIdMatch(lastGame.id);
            }
         }}
         style={{ cursor: "pointer" }}
         className="AsideLastGame_LastMatch"
      >
         <div className="AsideLastGame_GameResult">
            <SixthTitle className="negative-margin">
               Current Match-up
            </SixthTitle>
            {/* <Caption className={"AsideLastGame_Caption"}>YOU WON</Caption> */}
         </div>
         <div className="AsideLastGame_TeamsContainer">
            {errorLastGame && <p className="h-center">{errorLastGame}</p>}
            {lastGame && (
               <>
                  <div className="AsideLastGame_TeamA">
                     <TertiarySubtitle className="nickNameOverflow start text-capitalize">
                        {lastGame &&
                           lastGame.player1.first_name &&
                           `${
                              lastGame.player1?.first_name.split(" ")[0]
                           } ${lastGame.player1?.last_name.substring(0, 1)}`}
                     </TertiarySubtitle>
                     <TertiarySubtitle
                        className={
                           lastGame.player1_weekly_score >
                           lastGame.player2_weekly_score
                              ? "AsideLastGame_Caption"
                              : "AsideLastGame_Caption-red"
                        }
                     >
                        {lastGame.player1_weekly_score === null
                           ? "0"
                           : lastGame?.player1_weekly_score}{" "}
                        Pts
                     </TertiarySubtitle>
                  </div>
                  <Overline className={"AsideLastGame_Vs"}>VS</Overline>
                  <div className="AsideLastGame_TeamB">
                     <TertiarySubtitle className="nickNameOverflow end text-capitalize">
                        {lastGame &&
                           lastGame.player2.first_name &&
                           `${
                              lastGame.player2?.first_name.split(" ")[0]
                           } ${lastGame.player2?.last_name.substring(0, 1)}`}
                     </TertiarySubtitle>
                     <TertiarySubtitle
                        className={
                           lastGame.player2_weekly_score >
                           lastGame.player1_weekly_score
                              ? "AsideLastGame_Caption"
                              : "AsideLastGame_Caption-red"
                        }
                     >
                        {lastGame.player2_weekly_score === null
                           ? "0"
                           : lastGame.player2_weekly_score}{" "}
                        Pts
                     </TertiarySubtitle>
                  </div>
               </>
            )}
         </div>
      </div>
   );
};

export default AsideLastGame;
