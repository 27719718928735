import React, { useEffect, useState } from "react";
import TertiarySubtitle from "../../atoms/TertiarySubtitle/TertiarySubtitle";
import { Controller, useForm } from "react-hook-form";
import "./PlayerAssingForm.css";
import SelectReact from "../../atoms/SelectReact/SelectReact";
import DefaultCardContainer from "../../molecules/DefaultCardContainer/DefaultCardcontainer";
import PlayerCard from "../../molecules/playerCard/PlayerCard";
import { toast } from "react-hot-toast";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import Overline from "../../atoms/Overline/Overline";

const PlayerAssingForm = ({
  usersAll,
  conferenceAll,
  getAllPlayers,
  divisionAll,
  putDivisionToPlayer,
  divisionS
}) => {
  const { control, handleSubmit, reset } = useForm();
  const [players, setPlayers] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  console.log(divisionAll);

  const handleSetPlayer = (key, username) => {
    setPlayers(username);
    setSelectedId(key); // Update the selectedId to the clicked player's id
  };

  const onSubmit = async (data) => {
    const selectedPlayer = usersAll.find(
      (player) => player.username === players
    );
    console.log(selectedPlayer);
    if (selectedPlayer) {
      data.id = selectedPlayer.id;
    }

    console.log(data);
    try {
      // Make the POST request to the API endpoint
      await putDivisionToPlayer(data.id, data.division);
      toast.success("successfully asigned division");
      await getAllPlayers();
      reset();
      // Clear the form after successful submission
      // You can add additional logic here to show a success message, etc.
    } catch (error) {
      // Handle errors here if needed
      console.error("Error creating conference:", error);
    }
  };

  return (
    <div className="PlayerAssingForm">
      <div className="PlayerAssingForm_container">
        <form
          className="PlayerAssingForm_form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TertiarySubtitle>Select player</TertiarySubtitle>

          <Controller
            control={control}
            name="division"
            render={({ field: { onChange, name, ref } }) => (
              <SelectReact
                onChange={(value) => {
                  onChange(value);
                }}
                name={name}
                label={"Division"}
                options={
                  divisionAll &&
                  divisionAll.map((c) => {
                    return { ...c, label: c.name, value: c.id };
                  })
                }
              />
            )}
          />

          <div>player select: {players}</div>
          <button type="submit" className="CreateConferenceForm_Button">
            Assign
          </button>
        </form>
        <DefaultCardContainer title={"Players"}>
          <div className="content_user_division_asing">
            <Overline className={"fs-6 m-0"}>Users without Division</Overline>
            {usersAll
              .filter((u) => u[divisionS] === null)
              .map((card) => (
                <div
                  key={card.id}
                  onClick={() => handleSetPlayer(card.id, card.username)}
                  className="-click me-2"
                >
                  <PlayerCard
                    name={card.username}
                    division={card?.[divisionS]}
                    mod={`-slide-in-blurred-left ${card.id === selectedId ? "-selected" : ""
                      }`}
                  />
                </div>
              ))}
            <Overline className={"fs-6 m-0"}>Users in division</Overline>

            {usersAll
              .filter((u) => u[divisionS])
              .map((card) => (
                <div
                  key={card.id}
                  onClick={() => handleSetPlayer(card.id, card.username)}
                  className="-click me-2"
                >
                  <PlayerCard
                    name={card.username}
                    division={card?.[divisionS]}
                    mod={`-slide-in-blurred-left ${card.id === selectedId ? "-selected" : ""
                      }`}
                  />
                </div>
              ))}
          </div>
        </DefaultCardContainer>
      </div>
    </div>
  );
};

export default PlayerAssingForm;
