import { Link } from "react-router-dom";
import CardLeaguePack from "../../atoms/CardLeaguePack/CardLeaguePack";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/AuthContext";
import { getLeagueActivesByUserService } from "../../../services/user.services";
import add from "../../../assets/img/add_pack.svg";
import Overline from "../../atoms/Overline/Overline";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import { getAllLeagueService } from "../../../services/league.services";

export default function LeagueActive() {
   const [leagueActivesAll, setleagueActivesAll] = useState(undefined);
   const [IsLoading, setIsLoading] = useState(false);
   const { user } = useContext(AuthContext);

   useEffect(() => {
      getAlLeagueActives();
   }, []);

   const getAlLeagueActives = async () => {
      setIsLoading(true);
      try {
         const re = await getLeagueActivesByUserService(user.user_id);
         const result2 = await getAllLeagueService();
         const ligas = result2.data;
         const ligasActivas = re.data;
         setleagueActivesAll(
            ligasActivas.map((l) => {
               return {
                  ...l,
                  league: ligas.find((le) => le.name === l.league),
               };
            })
         );
         setIsLoading(false);
      } catch (error) {
         setIsLoading(false);
         console.log(error);
      }
   };

   return (
      <>
         <h3 className="mt-5 mb-3">Your active league packs</h3>
         <div className="container_packs_league">
            {IsLoading && (
               <div className="h-center no_league">
                  <CustomSpinner></CustomSpinner>
               </div>
            )}
            {leagueActivesAll &&
               !leagueActivesAll[0].message &&
               leagueActivesAll.map((l) => <CardLeaguePack league={l} />)}

            {leagueActivesAll && leagueActivesAll[0].message && (
               <Overline className="fs-5 no_league">
                  {" "}
                  {leagueActivesAll[0].message}
               </Overline>
            )}
            {!IsLoading && !leagueActivesAll && (
               <div className="no_league"></div>
            )}
            <Link to={"new"} className="card_packs_league_add h-center">
               <img src={add} alt="" />
               <div className="text_card_league mt-1">
                  GET STARTED: NEW LEAGUE
               </div>
            </Link>
            <div className="no_league"></div>
            {IsLoading && (
               <>
                  {/* <div className="no_league"></div> */}
                  {/* <div className="no_league"></div> */}
               </>
            )}
            {leagueActivesAll && leagueActivesAll[0].message && (
               <>
                  <div className="no_league"></div>
                  {/* <div className="no_league"></div> */}
               </>
            )}
         </div>
      </>
   );
}
