import Pharagraph from "../../atoms/Paragraph/Paragraph";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";

export default function CancelPage() {
  return (
    <>
      <div className="text-center">
        <PrimaryTitle className={"mt-5"}>PAYMENT CANCELED.</PrimaryTitle>
        <i class="fa-solid fa-ban fa-5x mb-3"></i>
        <Pharagraph>SOME ERROR HAS OCCURRED IN THE PAYMENT PROCESS.</Pharagraph>
      </div>
    </>
  );
}
