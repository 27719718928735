import { Controller, useForm } from "react-hook-form";
import Input from "../../atoms/Input/Input";
import SelectReact from "../../atoms/SelectReact/SelectReact";

import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";

const optionsType = [
  { label: "Mandatory", value: "Mandatory" },
  { label: "Wildcard", value: "Wildcard" },
  { label: "Open", value: "Open" },
  { label: "Tiebreaker", value: "Tiebreaker" },
];

export default function GamesCFCForm({
  game,
  getTeamsAll,
  getAllGame,
  setShow,
  update,
  create,
}) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const [teamsAll, setTeamsAll] = useState(undefined);


  useEffect(() => {
    getAllTeams();
  }, []);

  const getAllTeams = async () => {
    setTeamsAll(undefined);
    try {
      const result = await getTeamsAll();
      setTeamsAll(
        result.data.map((c) => {
          return { value: c.id, label: c.name };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    if (game) {
      if (data.type === undefined) data.type = game.type;
      if (data.home_team === undefined) {
        data.home_team = teamsAll.find(
          (t) => t.label === game.home_team.name
        ).value;
      }
      if (data.away_team === undefined) {
        data.away_team = teamsAll.find(
          (t) => t.label === game.away_team.name
        ).value;
      }
      data.id = game.id;
      console.log(data);
      try {
        await update(data);
        setShow(false);
        await getAllGame();
        toast.success("Game Successfully Updated");
      } catch (error) {
        console.log(error);
        toast.error(error.response.data?.Error[0]);
      }
    } else {
      try {
        await create(data);
        setShow(false);
        reset();
        await getAllGame();
        toast.success("Successfully Created Game");
      } catch (error) {
        console.log(error);
        toast.error(error.response.data?.Error[0]);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <div className="mb-2">
          <Input
            defaultValue={game && game.date_time}
            label={"Date Game"}
            id={"date_time"}
            name={"date_time"}
            register={register}
            placeholder={"game date"}
            type="datetime-local"
            rules={{ required: "Game date is required" }}
            error={errors.date_time?.message}
          />
        </div>
        {/* <Input
          label={"Time Game"}
          id={"game_time"}
          name={"game_time"}
          register={register}
          placeholder={"sdas"}
          type="time"
        /> */}

        {teamsAll && (
          <>
            <Controller
              control={control}
              name="home_team"
              rules={{ required: "Home team is required" }}
              defaultValue={
                game &&
                teamsAll &&
                teamsAll.find((o) => o.label === game.home_team.name).value
              }
              render={({ field: { onChange, name, ref } }) => (
                <SelectReact
                  inputRef={ref}
                  onChange={onChange}
                  name={name}
                  label={"Home Team"}
                  options={teamsAll && teamsAll}
                  error={errors.home_team?.message}
                  defaultValue={
                    game &&
                    teamsAll &&
                    teamsAll.find((o) => o.label === game.home_team.name)
                  }
                />
              )}
            />

            <Controller
              control={control}
              register
              name="away_team"
              defaultValue={
                game &&
                teamsAll &&
                teamsAll.find((o) => o.label === game.away_team.name).value
              }
              rules={{ required: "Away Team is required" }}
              render={({ field: { onChange, name, ref } }) => (
                <SelectReact
                  inputRef={ref}
                  error={errors.away_team?.message}
                  label={"Away Team"}
                  options={teamsAll}
                  onChange={onChange}
                  name={name}
                  defaultValue={
                    game &&
                    teamsAll &&
                    teamsAll.find((o) => o.label === game.away_team.name)
                  }
                />
              )}
            />
          </>
        )}

        <div className="row">
          <div className="col-6">
            <Input
              label={"Score home"}
              id={"score_home"}
              name={"score_home"}
              register={register}
              placeholder={"0"}
              type="number"
              defaultValue={game ? game.score_home : 0}
            />
          </div>
          <div className="col-6">
            <Input
              label={"Score Away"}
              id={"score_away"}
              name={"score_away"}
              register={register}
              placeholder={"0"}
              type="number"
              defaultValue={game ? game.score_away : 0}
            />
          </div>
        </div>
        <Controller
          control={control}
          name="type"
          defaultValue={
            game && optionsType.find((o) => o.value === game.type).value
          }
          rules={{ required: "Type game is required" }}
          render={({ field: { onChange, name, ref } }) => (
            <SelectReact
              inputRef={ref}
              error={errors.type?.message}
              label={"Type"}
              defaultValue={
                game && optionsType.find((o) => o.value === game.type)
              }
              options={optionsType}
              onChange={onChange}
              name={name}
            />
          )}
        />

        <div className="h-center">
          <button type="submit">Save</button>
        </div>
      </div>
    </form>
  );
}
