import ReactTimeAgo from "react-time-ago";

export default function CountDownPickWindow({ pick_window }) {


    return (<div className={"fs-5 text-capitalize"}>
        {pick_window && new Date(`${pick_window}`) > new Date() && (

            <ReactTimeAgo timeStyle={"round"} future={true} date={new Date(`${pick_window}`)} locale="en-US" />
        )}
    </div>)
}