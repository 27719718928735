import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { PasswordResetUserService } from "../../../services/user.services";
import Input from "../../atoms/Input/Input";
import Caption from "../../atoms/Caption/Caption";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import Btn_xl from "../../atoms/Btn_xl/Btn_xl";

export default function PasswordResetForm() {
   const [errorResponse, setErrorResponse] = useState(undefined);
   const [isSuccess, setIsSuccess] = useState(false);
   const { token, uid } = useParams();

   const {
      register,
      handleSubmit,

      watch,
      formState: { errors, isSubmitting },
   } = useForm();

   const password = useRef();
   password.current = watch("password", "");

   const onSubmit = async (data) => {
      data.token = token;
      data.uidb64 = uid;
      setErrorResponse(undefined);
      try {
         await PasswordResetUserService(data);
         setIsSuccess(true);
      } catch (error) {
         if (error.response.status === 400) {
            console.log(error.response.data);
            setErrorResponse(error.response.data.message);
         }
         if (error.response.status === 401)
            setErrorResponse(error.response.data.detail);
      }
   };

   return (
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
         <Input
            register={register}
            placeholder="Enter password"
            type="password"
            name="password"
            label="New Password"
            rules={{
               required: "password is required",
               minLength: {
                  value: 8,
                  message: "Password must have at least 8 characters",
               },
            }}
            error={errors.password?.message}
         />
         <Input
            register={register}
            placeholder="Enter password"
            type="password"
            name="password_confirm"
            label="Confirm New Password"
            rules={{
               validate: (value) =>
                  value === password.current || "The passwords do not match",
            }}
            error={errors.password_confirm?.message}
         />
         {/* <div className="mb-3">
            <label
               htmlFor="exampleFormControlInput1"
               className="form-label fw-semibold"
            >
               New Password
            </label>
            <input
               type="password"
               className="form-control"
               placeholder="Password"
               ref={password}
               {...register("password", {
                  required: "password is required",
               })}
            />
            <div className="ms-2 form-text text-danger">
               {errors.password?.message}
            </div>
         </div>
         <div className="mb-3">
            <label
               htmlFor="exampleFormControlInput1"
               className="form-label fw-semibold"
            >
               Confirm Password
            </label>
            <input
               type="password"
               className="form-control"
               placeholder="Password"
               {...register("password_confirm", {
                  validate: (value) =>
                     value === password.current || "The passwords do not match",
               })}
            />
            <div className="ms-2 form-text text-danger">
               {errors.password_confirm?.message}
            </div>
         </div> */}

         {errorResponse && (
            <Caption className="red-text">{errorResponse}</Caption>
         )}
         {isSubmitting && (
            <div className="h-center">
               <CustomSpinner />
            </div>
         )}

         {isSuccess && (
            <div>
               <Caption>Your password has been successfully changed</Caption>
            </div>
         )}

         <Btn_xl className="login--btn" type="submit">
            Change Password
         </Btn_xl>
      </form>
   );
}
