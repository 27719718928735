import { useForm } from "react-hook-form";
import PickSelect from "../../atoms/PickSelect/PickSelect";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
   getCreatePickCFLService,
   getUpdatePickCFLService,
} from "../../../services/picks.services";
import { getGamesOfWeekService } from "../../../services/games.services";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import Input from "../../atoms/Input/Input";
import AuthContext from "../../../context/AuthContext";
import Overline from "../../atoms/Overline/Overline";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";

export default function FormPickSelect({
   pick,
   getPicksCurrent,
   setshowModal,
}) {
   const [gamesAll, setGamesAll] = useState(null);
   const [gamesAllD, setGamesAllD] = useState(null);
   const [gamesAllMandatory, setGamesAllMandatory] = useState(null);
   const [isLoading, setIsLoading] = useState({ pick: true, games: true });
   const [TiebreakerGame, setTiebreakerGame] = useState(undefined);
   const { user } = useContext(AuthContext);

   const {
      register,
      handleSubmit,
      setValue,
      setError,
      clearErrors,
      formState: { errors },
   } = useForm();
   const array = [
      "pick1",
      "pick2",
      "pick3",
      "pick4",
      "pick5",
      "pick6",
      "pick7",
   ];

   const array2 = ["1", "2", "3", "4", "5", "6", "7"];

   useEffect(() => {
      getAllGames();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onSubmit = async (data) => {
      var err = 0;
      var er2 = false;

      var numeros = [];

      //Validacion Picks
      for (let p of array) {
         if (!data.hasOwnProperty(p)) {
            if (pick) {
               console.log(pick[p]);
               data[p] = pick[p].team.id;
               data[`game${p.substring(4, 5)}`] = pick[p].game.id;
            } else {
               setError(p, {
                  type: "required",
                  message: "This pick has not been selected",
               });
               err++;
            }
         }
         if (data[`game${p.substring(4, 5)}`]) {
            numeros.push(data[`game${p.substring(4, 5)}`]);
         }
      }
      const tempArray = [...numeros].sort();
      let duplicados = [];

      for (let i = 0; i < tempArray.length; i++) {
         if (tempArray[i + 1] === tempArray[i]) {
            duplicados.push(tempArray[i]);
         }
      }

      if (duplicados.length > 0) {
         toast.error(
            "There is one or more games selected more than once, please check your picks."
         );
         return false;
      }
      if (er2) return false;

      if (err > 0) {
         return false;
      }

      if (pick && !data.defensive_pick) {
         data.defensive_pick = pick.defensive_pick.id;
      }

      if (!data.defensive_pick) {
         setError("defensive_pick", {
            type: "required",
            message: "defensive pick is required",
         });
         return false;
      }

      if (data.tiebreaker === "") {
         setError("tiebreaker", {
            type: "required",
            message: "tiebreaker is required",
         });
         return false;
      }

      var v = gamesAllMandatory.map((c) => c.id);
      var nGameSM = 0;

      for (let p of array) {
         if (v.includes(data[`game${p.substring(4, 5)}`])) {
            nGameSM++;
         }
      }
      if (nGameSM < 3) {
         toast.error("select pick all mandated games ");
         return false;
      }

      /* if (gamesAll.find(({ type }) => type === "Mandatory")) {
      toast.error("select pick all mandated games ");
      return false;
    } */

      data.user = user.user_id;

      if (pick) {
         data.id = pick.id;
         await getUpdatePickCFLService(data);
         getAllGames();
         getPicksCurrent();
         toast.success("Pick has been successfully updated");
         setshowModal(false);
      } else {
         try {
            await getCreatePickCFLService(data);
            toast.success("Pick has been successfully saved");
            getAllGames();
            getPicksCurrent();
            setshowModal(false);
         } catch (error) {
            console.log(error);
         }
      }
   };

   const getAllGames = async () => {
      setGamesAll(undefined);
      try {
         setIsLoading((status) => {
            return { ...status, games: true };
         });
         const result = await getGamesOfWeekService();
         setGamesAll(result.data.filter((o) => o.type !== "Tiebreaker"));

         setTiebreakerGame(result.data.filter((o) => o.type === "Tiebreaker"));
         setGamesAllMandatory(
            result.data.filter((o) => o.type === "Mandatory")
         );
         setGamesAllD(
            result.data.filter(
               (o) => o.type !== "Tiebreaker" && o.type !== "Mandatory"
            )
         );
         setIsLoading((status) => {
            return { ...status, games: false };
         });
      } catch (error) {}
   };

   return (
      <form onSubmit={handleSubmit(onSubmit)} className="form">
         <PrimaryTitle>Choose your teams</PrimaryTitle>
         {/* <Overline>MAIN PICKS</Overline> */}
         <hr className="divider_picks"></hr>

         {isLoading.games && (
            <div className="h-center">
               <CustomSpinner></CustomSpinner>
            </div>
         )}
         {gamesAll && (
            <>
               <PickSelect
                  pick={pick && pick.pick1}
                  games={gamesAll}
                  setGames={setGamesAll}
                  setValue={setValue}
                  name="pick1"
                  gameName="game1"
                  label="Pick # 1"
                  point={16}
                  error={errors.pick1?.message}
                  clearErrors={clearErrors}
               />
               <PickSelect
                  pick={pick && pick.pick2}
                  games={gamesAll}
                  setGames={setGamesAll}
                  setValue={setValue}
                  name="pick2"
                  gameName="game2"
                  label="Pick # 2"
                  point={13}
                  error={errors.pick2?.message}
                  clearErrors={clearErrors}
               />
               <PickSelect
                  pick={pick && pick.pick3}
                  games={gamesAll}
                  setGames={setGamesAll}
                  setValue={setValue}
                  name="pick3"
                  gameName="game3"
                  label="Pick # 3"
                  point={10}
                  error={errors.pick3?.message}
                  clearErrors={clearErrors}
               />

               <PickSelect
                  pick={pick && pick.pick4}
                  games={gamesAll}
                  setGames={setGamesAll}
                  setValue={setValue}
                  name="pick4"
                  gameName="game4"
                  label="Pick # 4"
                  point={7}
                  error={errors.pick4?.message}
                  clearErrors={clearErrors}
               />
               <PickSelect
                  pick={pick && pick.pick5}
                  games={gamesAll}
                  setGames={setGamesAll}
                  setValue={setValue}
                  name="pick5"
                  gameName="game5"
                  label="Pick # 5"
                  point={6}
                  error={errors.pick5?.message}
                  clearErrors={clearErrors}
               />
               <PickSelect
                  pick={pick && pick.pick6}
                  games={gamesAll}
                  setGames={setGamesAll}
                  setValue={setValue}
                  name="pick6"
                  gameName="game6"
                  label="Pick # 6"
                  point={3}
                  error={errors.pick6?.message}
                  clearErrors={clearErrors}
               />
               <PickSelect
                  pick={pick && pick.pick7}
                  games={gamesAll}
                  setGames={setGamesAll}
                  setValue={setValue}
                  name="pick7"
                  gameName="game7"
                  label="Pick # 7"
                  point={2}
                  error={errors.pick7?.message}
                  clearErrors={clearErrors}
               />
               <PickSelect
                  pick={pick && { team: pick.defensive_pick }}
                  games={gamesAllD}
                  setGames={setGamesAllD}
                  setValue={setValue}
                  name="defensive_pick"
                  label="Defensive Pick"
                  error={errors.defensive_pick?.message}
                  clearErrors={clearErrors}
               />
               {TiebreakerGame && (
                  <>
                     <Overline className={"mt-3 text-center"}>
                        Tiebreaker game
                     </Overline>
                     <DefaultCard className={"px-3 py-2 text-center"}>
                        <Overline className={"m-0"}>
                           {TiebreakerGame &&
                              Array.isArray(TiebreakerGame) &&
                              TiebreakerGame.length === 0 && (
                                 <p className="m-0 fs-6">
                                    THERE IS NO TIEBREAKER YET
                                 </p>
                              )}
                           {TiebreakerGame[0]?.home_team.name &&
                              `${TiebreakerGame[0]?.home_team.name} VS ${TiebreakerGame[0]?.away_team.name}  `}
                        </Overline>
                     </DefaultCard>
                     <Input
                        defaultValue={pick && pick.tiebreaker}
                        label={"Tiebreaker"}
                        register={register}
                        name={"tiebreaker"}
                        type={"number"}
                        error={errors.tiebreaker?.message}
                     />
                  </>
               )}
            </>
         )}

         <div className="h-center">
            <button type="submit">Save</button>
         </div>
      </form>
   );
}
