import React from "react";
import SeasonSetForm from "../../molecules/SeasonSetForm/SeasonSetForm";
import "./InfoPanelCFL.css";
import ConferenceForm from "../../molecules/ConferenceForm/ConferenceForm";

import TeamsCFL from "../../molecules/TeamsCFL/TeamsCFL";
import GamesCFLList from "../GamesCFLList/GamesCFLList";
import WeeksCFL from "../WeeksCFL/WeeksCFL";
import PlayersMatchesCFL from "../../molecules/PlayersMatchesCFL/PlayersMatchesCFL";
import SeasonCFL from "../../molecules/SeasonCFL/SeasonCFL";
import CoferenceCFL from "../../molecules/CoferenceCFL/CoferenceCFL";

const InfoPanelCFL = () => {
  return (
    <div className="InfoPanelCFL">
      <div className="infoPanelCFL_Col">
        <SeasonCFL />

        <WeeksCFL />
        <CoferenceCFL />

      </div>
      <div className="infoPanelCFL_Col">
        <PlayersMatchesCFL />
        <TeamsCFL />
      </div>
      <div className="infoPanelCFL_Col">
        <GamesCFLList />
      </div>
    </div>
  );
};

export default InfoPanelCFL;
