import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import "./DefaultCardContainer.css"

const DefaultCardContainer = ({title, children, modifier}) => {
    return (
        <div className="DefaultCardContainer">
            <PrimaryTitle className={"DefaultCardContainer_Title"}>
                {title}
            </PrimaryTitle>
            <div className={`DeDefaultCardContainer_content ${modifier}`}>
                {children}
            </div>
        </div>
    )
}

export default DefaultCardContainer;