import React, { useEffect, useState } from "react";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import "./DinamicBody.css";
import { PrivacyPolicy } from "../../molecules/PrivacyPolicy/PrivacyPolicy";
import { TermsAndConditions } from "../../molecules/TermsAndConditions/TermsAndConditions";
import { RefundPolicy } from "../../molecules/RefundPolicy/RefundPolicy";
import QuaternaryTitle from "../../atoms/QuaternaryTitle/QuaternaryTitle";


export const DinamicBody = () => {
	const [option, setOption] = useState(0);
	const [openVisual, setOpenVisual] = useState(true);

	useEffect(() => {
		setOpenVisual(!openVisual);
	}, [option])

	return (
		<div className="DinamicBody">
			<div className={`DinamicBody_picker ${openVisual ? "-open" : ""}`}>
				<div onClick={() => option === 0 ? setOption(1) : setOption(0)}>
					<DefaultCard className={`DinamicBody_Btn ${option !== 1 ? "-selec" : "-noselec"}`}>
						<QuaternaryTitle>Privacy Policy</QuaternaryTitle>
					</DefaultCard>
				</div>
				<div onClick={() => option === 0 ? setOption(2) : setOption(0)}>
					<DefaultCard className={`DinamicBody_Btn ${option !== 2 ? "-selec" : "-noselec"}`}>
						<QuaternaryTitle>Terms & Conditions</QuaternaryTitle>
					</DefaultCard>
				</div>
				<div onClick={() => option === 0 ? setOption(3) : setOption(0)}>
					<DefaultCard className={`DinamicBody_Btn ${option !== 3 ? "-selec" : "-noselec"}`}>
						<QuaternaryTitle>Refund Policy</QuaternaryTitle>
					</DefaultCard>
				</div>

			</div>
			<div className={`DinamicBody_content ${openVisual ? "-scale-up" : "-scale-down"}`}>
				{option === 1 ? <PrivacyPolicy /> :
					option === 2 ? <TermsAndConditions /> :
						option === 3 ? <RefundPolicy/> : <></>}
			</div>
		</div>
	)
}