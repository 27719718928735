import React from "react";
import TertiarySubtitle from "../../atoms/TertiarySubtitle/TertiarySubtitle";
import Input from "../../atoms/Input/Input";
import { Controller, useForm } from "react-hook-form";
import "./CreateDivisionForm.css";
import SelectReact from "../../atoms/SelectReact/SelectReact";
import { createCFLDivisionService } from "../../../services/divisions.services";
import { toast } from "react-hot-toast";
import { Await } from "react-router-dom";

const CreateDivisionForm = ({
   conferenceData,
   getAllDivisions,
   getAllConference,
   setActualConference,
   create,
   update,
   division,
   filterDivision,
   setShow,
}) => {
   const {
      control,
      register,
      reset,
      handleSubmit,
      formState: { errors },
   } = useForm();

   const onSubmit = async (data) => {
      /* const selectedConference = conferenceData.find(
      (conference) => conference.name === data.conference
    );

    if (selectedConference) {
      data.id = selectedConference.id;
    } */
      if (division) {
         /* await update(data.name, data.id); */
         try {
            // Make the POST request to the API endpoint
            data.id = division.id;
            const s = await update(data);
            division = s.data;
            filterDivision(data.conference);
            toast.success("Division created successfully");
            setShow(false);
            reset();
            // Clear the form after successful submission
            // You can add additional logic here to show a success message, etc.
         } catch (error) {
            // Handle errors here if needed
            console.error("Error creating division:", error);
         }
      } else {
         try {
            // Make the POST request to the API endpoint
            await create(data);
            filterDivision(data.conference);
            toast.success("Division created successfully");
            reset();
            // Clear the form after successful submission
            // You can add additional logic here to show a success message, etc.
         } catch (error) {
            // Handle errors here if needed
            console.error("Error creating division:", error);
         }
      }
   };

   return (
      <>
         <form className="CreateDivisionForm" onSubmit={handleSubmit(onSubmit)}>
            <TertiarySubtitle>Name Division</TertiarySubtitle>
            <div className="line_game-h"></div>
            <Controller
               control={control}
               name="conference"
               rules={{ required: "Conference is required" }}
               defaultValue={
                  division &&
                  conferenceData &&
                  conferenceData.find((c) => c.value === division.conference)
                     .value
               }
               render={({ field: { onChange, name, ref } }) => (
                  <SelectReact
                     defaultValue={
                        division &&
                        conferenceData &&
                        conferenceData.find(
                           (c) => c.value === division.conference
                        )
                     }
                     onChange={onChange}
                     error={errors.conference?.message}
                     name={name}
                     label={"Conference"}
                     options={conferenceData && conferenceData}
                  />
               )}
            />
            <Input
               defaultValue={division && division.name}
               label={"Division"}
               id={"name"}
               name={"name"}
               register={register}
               placeholder={"name"}
               type="text"
               rules={{
                  required: "Name is required",
               }}
               error={errors.name?.message}
            />
            <button className="CreateConferenceForm_Button" type="submit">
               Create
            </button>
         </form>
      </>
   );
};

export default CreateDivisionForm;
