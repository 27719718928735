import { useContext, useState } from "react";
import "./AsideAdmin.css";
import { NavIcons } from "../../molecules/Nav_Icons";
import { NavLink, useNavigate } from "react-router-dom";
import AdminUserCard from "../../molecules/Admin_user/AdminUserCard";
import ManualPayment from "../../molecules/Manual Payment/ManualPayment";
import AuthContext from "../../../context/AuthContext";

export default function AsideAdmin({ sendDataToParent }) {
   const [isOpen, setOpen] = useState(false);
   const { logoutUser } = useContext(AuthContext);
   const navigate = useNavigate();

   const handleOpen = () => {
      sendDataToParent(isOpen);
      setOpen(!isOpen);
   };

   return (
      <>
         <div className={`AsideAdmin ${isOpen ? "-open" : "-close "}`}>
            <div className={`AsideAdmin_AsideTop`}>
               <div
                  style={{ cursor: "pointer", color: "var(--main-green)" }}
                  className={`Aside_navlink arrow ${
                     isOpen ? "slide-out-left" : "slide-in-left"
                  }`}
                  onClick={() => {
                     navigate("/dashboard");
                  }}
               >
                  <i className="fa-solid fa-arrow-left"></i>
               </div>
               <div
                  className={`AsideAdmin_Logo ${
                     isOpen ? "slide-out-left" : "slide-in-left"
                  }`}
               />
               <div
                  className={`Aside_ico ${isOpen ? "mar-e" : ""}`}
                  onClick={handleOpen}
               />
            </div>
            <div
               className={`AsideAdmin_AsideMid ${
                  isOpen ? "slide-out-left" : "slide-in-left"
               }`}
            >
               <AdminUserCard />
               <div className="AsideAdmin_Nav">
                  <ManualPayment />
                  <NavLink className="AsideAdmin_navlink " to="CFL">
                     CFL
                  </NavLink>
                  <NavLink className="AsideAdmin_navlink " to="KFL">
                     KFL
                  </NavLink>
               </div>
            </div>
            <div
               className={`AsideAdmin_AsideBot ${
                  isOpen ? "slide-out-left" : "slide-in-left"
               }`}
            >
               {/* <NavIcons className={"AsideAdmin_navIcons"} /> */}

               {/* <nav_btn className="AsideAdmin_navlink">Log Out</nav_btn> */}
               <div
                  style={{ cursor: "pointer" }}
                  className="AsideAdmin_navlink"
                  onClick={() => {
                     logoutUser();
                     navigate("/Login");
                  }}
               >
                  Log Out
               </div>
            </div>
         </div>
      </>
   );
}
