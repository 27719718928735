import axios from "axios";


export const createCFLConferenceService = async (data) => {
    return await axios.post("/cfl/conferences/", data);
};

export const getAllCFLConferenceService = async () => {
    return await axios.get("/cfl/conferences/")
}

export const getAllKFLConferenceService = async () => {
    return await axios.get("/kfl/conferences/")
}

export const createKFLConferenceService = async (data) => {
    return await axios.post("/kfl/conferences/", data);
};

