import { useContext, useEffect, useState } from "react";
import {
  getAllPlayerMatchesKFLServices,
  getAllPlayerMatchesServices,
  getMatchUpDetailHistoryKFLService,
  getMatchUpDetailKFLService,
} from "../../../services/matches.services";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import Overline from "../../atoms/Overline/Overline";
import AuthContext from "../../../context/AuthContext";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import Pharagraph from "../../atoms/Paragraph/Paragraph";
import {
  getAllCFLWeeksService,
  getKFLWeeksNowService,
} from "../../../services/weeks.services";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import MatchUpDetailKFL from "../../molecules/MatchUpDetail/MatchUpDetailKFL";
import Modal from "../../atoms/Modal/Modal";
import MatchUpDetailKFLHistory from "../../molecules/MatchUpDetailHistory/MatchUpDetailKFLHistory";

export default function PlayersMatchesKFL() {
  const [allMatchups, setAllMatchups] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [Weeks, setWeeks] = useState(undefined);
  const [weekFilter, setweekFilter] = useState(undefined);
  const [weekNow, setWeekNow] = useState(undefined);
  const [modalV, setmodalV] = useState(false);
  const [modalVHistory, setmodalVHistory] = useState(false);
  const [IdMatch, setIdMatch] = useState(undefined);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    getAllWeek();
  }, []);

  useEffect(() => {
    getAllMatchups();
  }, [weekFilter]);

  const getAllMatchups = async () => {
    setAllMatchups(undefined);
    try {
      setIsLoading(true);
      const re = await getAllPlayerMatchesKFLServices();
      if (weekFilter) {
        console.log(re.data.filter((m) => m.week === weekFilter));
        setAllMatchups(re.data.filter((m) => m.week === weekFilter));
      } else {
        setAllMatchups(re.data);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllWeek = async () => {
    try {
      const result = await getAllCFLWeeksService();
      const items = result.data;
      setWeekNow((await getKFLWeeksNowService()).data);

      items.sort(function (a, b) {
        if (a.week > b.week) {
          return 1;
        }
        if (a.week < b.week) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      setWeeks(items);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={`container_team_page`}>
        <PrimaryTitle>Matches List</PrimaryTitle>
        <Pharagraph>Filter By Week:</Pharagraph>
        <div className="row mb-3">
          <div
            className="col-auto"
            onClick={() => {
              setweekFilter(undefined);
            }}
          >
            <DefaultCard
              className={`filter--btn ${
                weekFilter === undefined ? "white-border" : ""
              }`}
            >
              All Weeks
            </DefaultCard>
          </div>
          {Weeks &&
            Weeks.map((w) => (
              <div
                className="col-auto"
                onClick={() => {
                  console.log(w.week);
                  setweekFilter(w.week);
                }}
              >
                <DefaultCard
                  className={`filter--btn ${
                    weekFilter === w.week ? "white-border" : ""
                  }`}
                >
                  Week {w.week}
                </DefaultCard>
              </div>
            ))}
        </div>
        <hr />
        <div className="row g-2">
          {isLoading && (
            <div className="h-center">
              <CustomSpinner></CustomSpinner>
            </div>
          )}
          {allMatchups &&
            allMatchups.map((m) => (
              <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 text-center justify-content-between">
                <DefaultCard className={"p-3 mx-3 my-1 ms-0"}>
                  <Overline
                    className={`Overline-4 ${
                      user.username === m.player1.username ||
                      user.username === m.player2.username
                        ? "m-0 text-green"
                        : "m-0"
                    }`}
                  >{`${
                    m.player1?.first_name?.split(" ")[0]
                  } ${m.player1?.last_name.substring(0, 1)}  VS ${
                    m.player2?.first_name?.split(" ")[0]
                  } ${m.player2?.last_name.substring(0, 1)}`}</Overline>
                  <Overline className={"m-0"}>{`Week ${m.week}`}</Overline>
                  {weekNow &&
                    weekNow.isOpen !== true &&
                    weekNow.week === m.week && (
                      <button
                        style={{ fontSize: "0.7em", padding: "0.4em" }}
                        className="m-0"
                        onClick={() => {
                          setIdMatch(m.id);
                          setmodalV(true);
                        }}
                      >
                        <i class="fa-regular fa-eye"></i>
                      </button>
                    )}

                  {weekNow && weekNow.week > m.week && (
                    <button
                      style={{ fontSize: "0.7em", padding: "0.4em" }}
                      className="m-0"
                      onClick={() => {
                        setmodalVHistory(true);
                        setIdMatch(m.id);
                      }}
                    >
                      <i class="fa-regular fa-eye"></i>
                    </button>
                  )}

                </DefaultCard>
              </div>
            ))}
        </div>
      </div>

      <Modal show={modalVHistory} setShow={setmodalVHistory}>
        <MatchUpDetailKFLHistory
          id={IdMatch && IdMatch}
          getMatchUp={getMatchUpDetailHistoryKFLService}
        />
      </Modal>
      <Modal show={modalV} setShow={setmodalV}>
        <MatchUpDetailKFL
          id={IdMatch && IdMatch}
          getMatchUp={getMatchUpDetailKFLService}
        />
      </Modal>
    </>
  );
}
