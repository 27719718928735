import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import Overline from "../../atoms/Overline/Overline";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import SixthTitle from "../../atoms/SixthTitle/SixthTitle";
import PlayersMatches from "../../organisms/PlayersMatches/PlayersMatches";

export default function History() {
   return (
      <>
         <div className={`banner_header_dasboard_cfl`}>
            {/* <img src={iomg} alt="" className="img_banner_dasboard" /> */}
            <div className="banner_text_dasboard">
               <PrimaryTitle>History</PrimaryTitle>
               <Overline className={"text-green"}>REGULAR</Overline>

               <DefaultCard className={"p-2"}>
                  <SixthTitle className={"m-0"}>Overall </SixthTitle>
               </DefaultCard>
            </div>
         </div>

         <PlayersMatches />
      </>
   );
}
