import Caption from "../../atoms/Caption/Caption";
import SecondarySubtitle from "../../atoms/SecondarySubtitle/SecondarySubtitle";
import Upgames_Title from "../../atoms/Upgames_Title/Upgames_Title";
import "./Upgames.css";

const Upgames = ({ title, subtitle, sport }) => {
   return (
      <section className="upgames">
         <Upgames_Title>{title}</Upgames_Title>
         <div className="upgames--content">
            <SecondarySubtitle>{subtitle}</SecondarySubtitle>
         </div>
         <div className="upgames--subcontent h-center">
            <Caption>{sport}</Caption>
         </div>
      </section>
   );
};

export default Upgames;
