import { useEffect, useState } from "react"
import { getStandingKFLService } from "../../../services/user.services";
import ConferenceList from "./ConferenceList";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";

export default function StandingsKFL() {

    const [Standings, setStandings] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        getStandi()
    }, [])

    const getStandi = async () => {
        setIsLoading(true)
        try {
            const result = (await getStandingKFLService()).data
            delete result.unasigned
            setStandings(result)
            setIsLoading(false)

        } catch (error) {
        }
    }

    return (
        <>
            {isLoading && (
                <div className="h-center">
                    <CustomSpinner></CustomSpinner>
                </div>
            )}
            <ConferenceList data={Standings && Standings} />
        </>
    )
}