import { useEffect, useState } from "react";
import Accordion from "../../atoms/Accordion/Accordion";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import Pharagraph from "../../atoms/Paragraph/Paragraph";
import DefaultCardContainer from "../DefaultCardContainer/DefaultCardcontainer";
import {
  deletePlayerMatchesServices,
  getAllPlayerMatchesServices,
  getGeneratePlayersMatchesServices,
} from "../../../services/matches.services";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import { toast } from "react-hot-toast";

export default function PlayersMatchesCFL() {
  const [allMatchups, setAllMatchups] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllMatchups();
  }, []);

  const getAllMatchups = async () => {
    try {
      setIsLoading(true);
      const re = await getAllPlayerMatchesServices();
      console.log(re.data);
      setAllMatchups(re.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  async function handleClickGenerateMatches() {
    try {
      const result = await getGeneratePlayersMatchesServices();
      await getAllMatchups();
      toast.success(`${result.data.message}`);
    } catch (error) {
      console.log(error);
      if (error.response.status > 400 && error.response.status < 499)
        toast.error(error.response.data.message);
    }
  }
  return (
    <>
      <DefaultCardContainer title={"CFL Matches"}>
        <button onClick={handleClickGenerateMatches}>
          Generate the Matches
        </button>

        <Accordion title={"Matches List"} className="w-100">
          <div className="mt-2">
            <div className="row g-2">
              {isLoading && (
                <div className="h-center">
                  <CustomSpinner></CustomSpinner>
                </div>
              )}
              {allMatchups && allMatchups.length === 0 && (
                <Pharagraph className={"my-2 text-center"}>
                  no matches
                </Pharagraph>
              )}
              {allMatchups &&
                allMatchups.map((m) => (
                  <div className="col-12">
                    <DefaultCard className={"p-2"}>
                      <div className="d-flex justify-content-between align-items-center">
                        <Pharagraph
                          className={"m-0"}
                        >{`${m.player1.first_name} VS ${m.player2.first_name}`}</Pharagraph>
                        <Pharagraph
                          className={"m-0"}
                        >{`Week : ${m.week}`}</Pharagraph>
                        <button
                          className="m-0"
                          onClick={async () => {
                            try {
                              console.log(m);
                              await deletePlayerMatchesServices(m.id);
                              await getAllMatchups();
                              toast.success("Player Match Deleted ");
                            } catch (error) {}
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </DefaultCard>
                  </div>
                ))}
            </div>
          </div>
        </Accordion>
      </DefaultCardContainer>
    </>
  );
}
