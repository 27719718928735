import { useForm } from "react-hook-form";
import PickSelect from "../../atoms/PickSelect/PickSelect";
import PrimaryTitle from "../../atoms/PrimaryTitle/PrimaryTitle";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
   getCreatePickKFLService,
   getUpdatePickCFLService,
   getUpdatePickKFLService,
} from "../../../services/picks.services";
import { getGamesOfWeekKFLService } from "../../../services/games.services";
import CustomSpinner from "../../atoms/CustomSpinner/CustomSpinner";
import Input from "../../atoms/Input/Input";
import AuthContext from "../../../context/AuthContext";
import Overline from "../../atoms/Overline/Overline";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";

export default function FormPickSelectKFL({
   pick,
   getPicksCurrent,
   setshowModal,
}) {
   const [gamesAll, setGamesAll] = useState(null);
   const [isLoading, setIsLoading] = useState({ pick: true, games: true });
   const [gamesAllMandatory, setGamesAllMandatory] = useState(null);
   const [TiebreakerGame, setTiebreakerGame] = useState(undefined);

   const { user } = useContext(AuthContext);

   const array = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

   const {
      register,
      handleSubmit,
      setValue,
      setError,
      clearErrors,
      formState: { errors },
   } = useForm();

   useEffect(() => {
      getAllGames();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onSubmit = async (data) => {
      data.user = user.user_id;

      console.log(data);
      //Validacion Picks
      var err = 0;

      for (let p of array) {
         if (!data.hasOwnProperty(`pick${p}`)) {
            if (pick) {
               console.log(pick);
               data[`pick${p}`] = pick[`pick${p}`].team.id;
               data[`game${p}`] = pick[`pick${p}`].game.id;
            } else {
               setError(`pick${p}`, {
                  type: "required",
                  message: "This pick has not been selected",
               });
               err++;
            }
         }
      }
      if (err > 0) {
         return false;
      }

      if (pick) {
         data.id = pick.id;
         console.log(data);

         await getUpdatePickKFLService(data);
         toast.success("Pick has been successfully edit");
         getAllGames();
         getPicksCurrent();
         setshowModal(false);
      } else {
         try {
            console.log(data);
            await getCreatePickKFLService(data);
            toast.success("Pick has been successfully saved");
            getAllGames();
            getPicksCurrent();
            setshowModal(false);
         } catch (error) {
            console.log(error);
         }
      }
   };

   const getAllGames = async () => {
      setGamesAll(undefined);
      try {
         setIsLoading((status) => {
            return { ...status, games: true };
         });
         const result = await getGamesOfWeekKFLService();
         setGamesAll(result.data.filter((o) => o.type !== "Tiebreaker"));
         setTiebreakerGame(result.data.filter((o) => o.type === "Tiebreaker"));
         setGamesAllMandatory(
            result.data.filter((o) => o.type === "Mandatory")
         );
         setIsLoading((status) => {
            return { ...status, games: false };
         });
      } catch (error) {}
   };

   return (
      <form onSubmit={handleSubmit(onSubmit)} className="form">
         <PrimaryTitle>Choose your teams</PrimaryTitle>
         <hr className="divider_picks"></hr>
         <Overline className={"text-center fs-6"}>Mandated Games</Overline>
         {isLoading.games && (
            <div className="h-center">
               <CustomSpinner></CustomSpinner>
            </div>
         )}

         {gamesAllMandatory && (
            <>
               <PickSelect
                  pick={pick && pick.pick1}
                  games={gamesAllMandatory}
                  setGames={setGamesAllMandatory}
                  setValue={setValue}
                  name="pick1"
                  gameName="game1"
                  label="Pick # 1"
                  point={17}
                  error={errors.pick1?.message}
                  clearErrors={clearErrors}
               />

               <PickSelect
                  pick={pick && pick.pick2}
                  games={gamesAllMandatory}
                  setGames={setGamesAllMandatory}
                  setValue={setValue}
                  name="pick2"
                  gameName="game2"
                  label="Pick # 2"
                  point={14}
                  error={errors.pick2?.message}
                  clearErrors={clearErrors}
               />

               <PickSelect
                  pick={pick && pick.pick3}
                  games={gamesAllMandatory}
                  setGames={setGamesAllMandatory}
                  setValue={setValue}
                  name="pick3"
                  gameName="game3"
                  label="Pick # 3"
                  point={10}
                  error={errors.pick3?.message}
                  clearErrors={clearErrors}
               />
            </>
         )}

         <hr className="divider_picks"></hr>
         <Overline className={"text-center fs-6"}>Open Picks</Overline>
         {isLoading.games && (
            <div className="h-center">
               <CustomSpinner></CustomSpinner>
            </div>
         )}
         {gamesAll && (
            <>
               <PickSelect
                  pick={pick && pick.pick4}
                  games={gamesAll}
                  setGames={setGamesAll}
                  setValue={setValue}
                  name="pick4"
                  gameName="game4"
                  label="Pick # 4"
                  point={8}
                  error={errors.pick4?.message}
                  clearErrors={clearErrors}
               />

               <PickSelect
                  pick={pick && pick.pick5}
                  games={gamesAll}
                  setGames={setGamesAll}
                  setValue={setValue}
                  name="pick5"
                  gameName="game5"
                  label="Pick # 5"
                  point={7}
                  error={errors.pick5?.message}
                  clearErrors={clearErrors}
               />

               <PickSelect
                  pick={pick && pick.pick6}
                  games={gamesAll}
                  setGames={setGamesAll}
                  setValue={setValue}
                  name="pick6"
                  gameName="game6"
                  label="Pick # 6"
                  point={6}
                  error={errors.pick6?.message}
                  clearErrors={clearErrors}
               />

               <PickSelect
                  pick={pick && pick.pick7}
                  games={gamesAll}
                  setGames={setGamesAll}
                  setValue={setValue}
                  name="pick7"
                  gameName="game7"
                  label="Pick # 7"
                  point={3}
                  error={errors.pick7?.message}
                  clearErrors={clearErrors}
               />
               <PickSelect
                  pick={pick && pick.pick8}
                  games={gamesAll}
                  setGames={setGamesAll}
                  setValue={setValue}
                  name="pick8"
                  gameName="game8"
                  label="Pick # 8"
                  point={2}
                  error={errors.pick8?.message}
                  clearErrors={clearErrors}
               />
               <PickSelect
                  pick={pick && pick.pick9}
                  games={gamesAll}
                  setGames={setGamesAll}
                  setValue={setValue}
                  name="pick9"
                  gameName="game9"
                  label="Pick # 9"
                  point={2}
                  error={errors.pick9?.message}
                  clearErrors={clearErrors}
               />
            </>
         )}

         {TiebreakerGame && (
            <>
               <Overline className={"mt-3 text-center"}>
                  Tiebreaker game
               </Overline>
               <DefaultCard className={"px-3 py-2 text-center"}>
                  <Overline className={"m-0"}>
                     {TiebreakerGame &&
                        Array.isArray(TiebreakerGame) &&
                        TiebreakerGame.length === 0 && (
                           <p className="m-0 fs-6">
                              THERE IS NO TIEBREAKER YET
                           </p>
                        )}
                     {TiebreakerGame[0]?.home_team.name &&
                        `${TiebreakerGame[0]?.home_team.name} VS ${TiebreakerGame[0]?.away_team.name}  `}
                  </Overline>
               </DefaultCard>
            </>
         )}

         <Input
            defaultValue={pick && pick.tiebreaker}
            label={"Tiebreaker"}
            register={register}
            name={"tiebreaker"}
            type={"number"}
            rules={{ required: "Tiebreaker is required" }}
            error={errors.tiebreaker?.message}
         />

         <div className="h-center">
            <button type="submit">Save</button>
         </div>
      </form>
   );
}
