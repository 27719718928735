import React, { useEffect, useState } from "react";
import PlayerCard from "../../molecules/playerCard/PlayerCard";
import DefaultCardContainer from "../../molecules/DefaultCardContainer/DefaultCardcontainer";
import CardNew from "../../molecules/NewCard/CardNew";
import Modal from "../../atoms/Modal/Modal";
import PlayerAssingForm from "../../molecules/PlayerAssingForm/PlayerAssingForm";
import { getAllUsersService, putDivisionToPlayerKFL } from "../../../services/user.services";

const PlayerKFL = ({ getAllConferences, divisionAll }) => {
    const [PlayersDivision, setPlayersDivision] = useState(false);
    const [usersAll, setUsersAll] = useState(undefined);

    useEffect(() => {
        getAllUsers();
    }, []);

    const getAllUsers = async () => {
        try {
            const result = await getAllUsersService();
            setUsersAll(result.data);
        } catch (error) { }
    };

    return (
        <div className="playerCFL">
            <DefaultCardContainer title={"KFL Players"} modifier={""}>
                {usersAll && (
                    <div
                        className="playerCFL__modal"
                        onClick={() => setPlayersDivision(true)}
                    >
                        <CardNew />
                    </div>
                )}

                {/* {isLoading && (
          <div className="h-center">
            <CustomSpinner></CustomSpinner>
          </div>
        )}
        {showPlayers &&
          showPlayers.map((card) => <PlayerCard name={card.username} />)} */}
            </DefaultCardContainer>
            <Modal show={PlayersDivision} setShow={setPlayersDivision}>
                <PlayerAssingForm
                    usersAll={usersAll && usersAll}
                    conferenceAll={getAllConferences}
                    getAllPlayers={getAllUsers}
                    divisionAll={divisionAll}
                    putDivisionToPlayer={putDivisionToPlayerKFL}
                    divisionS={"division_kfl"}

                />
            </Modal>
        </div>
    );
};

export default PlayerKFL;
