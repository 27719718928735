import axios from "axios";

export const getCreateSeasonService = async (data) => {
  return await axios.post("/cfl/season/", data);
};

export const getAllSeasonService = async () => {
  return await axios.get("/cfl/season/");
};

export const UpdateCFLSeasonService = async (data) => {
  return await axios.put(`/cfl/season/${data.id}/`, data);
};



export const getAllKFLSeasonService = async () => {
  return await axios.get("/kfl/season/");
};

export const getCreatekflSeasonService = async (data) => {
  return await axios.post("/kfl/season/", data);
};

export const UpdateKFLSeasonService = async (data) => {
  return await axios.put(`/kfl/season/${data.id}/`, data);
};
