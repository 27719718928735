import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import Overline from "../../atoms/Overline/Overline";
import TertiarySubtitle from "../../atoms/TertiarySubtitle/TertiarySubtitle";

export default function PicksDetailByUserKFL({ pickDetails, cardStyle }) {
   return (
      <>
         {pickDetails && (
            <>
               <Overline className={"text-center"}>Mandatory Picks</Overline>
               {[
                  { id: 1, p: 17 },
                  { id: 2, p: 14 },
                  { id: 3, p: 10 },
                  { id: 4, p: 8 },
                  { id: 5, p: 7 },
                  { id: 6, p: 6 },
                  { id: 7, p: 3 },
                  { id: 8, p: 2 },
                  { id: 9, p: 2 },
               ].map((p) => (
                  <>
                     <DefaultCard className={`${cardStyle}`}>
                        <div className="card_pick_content">
                           {/* <div className=""> */}
                           <TertiarySubtitle className={"mb-1 text-center"}>
                              {pickDetails[`pick${p.id}`].team.name}
                           </TertiarySubtitle>
                           <div className="d-flex justify-content-center">
                              <Overline className={"mb-1"}>
                                 {" "}
                                 {pickDetails[`pick${p.id}`].game.type}
                              </Overline>
                              <Overline className={"m-0 text-green ms-2"}>
                                 {p.p}Pts
                              </Overline>
                              {/* </div> */}
                           </div>
                           {/* <div className="av_pick_team">NAV</div> */}
                        </div>
                     </DefaultCard>
                     {p.id === 3 && (
                        <>
                           <div className="separator"></div>
                           <Overline className={"text-center"}>
                              Open Picks
                           </Overline>
                        </>
                     )}
                  </>
               ))}

               <DefaultCard className={cardStyle}>
                  <div className="card_pick_content">
                     <div className="">
                        <div className="d-flex">
                           <Overline className={" m-0 text-green ms-2"}>
                              tiebreaker : {pickDetails.tiebreaker}
                           </Overline>
                        </div>
                     </div>
                  </div>
               </DefaultCard>
            </>
         )}
         <div className="separator"></div>
      </>
   );
}
