import React from "react";
import InfoPanelCFL from "../../organisms/InfoPanelCFL/InfoPanelCFL";






const CFLPanel = () => {
    return (
    <>
        <InfoPanelCFL/>
    </>
  );
}

export default CFLPanel;