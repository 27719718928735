import React from "react";
import { DinamicBody } from "../../organisms/DinamicBody/DinamicBody";
import "./TermsAndCondition.css";


export const TermsAndConditions = () => {
    return(
        <div className="TermsAndConditions">
            <DinamicBody/>
        </div>
    )
}