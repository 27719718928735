import React, { useContext, useEffect, useState } from "react";

import QuaternaryTitle from "../../atoms/QuaternaryTitle/QuaternaryTitle";
import DefaultCard from "../../atoms/DefaultCard/DefaultCard";
import "./Aside_User.css";
import Overline from "../../atoms/Overline/Overline";
import TertiarySubtitle from "../../atoms/TertiarySubtitle/TertiarySubtitle";
import { getUsersByIdService } from "../../../services/user.services";
import AuthContext from "../../../context/AuthContext";
import NameUserAside from "../../atoms/NameUserAside/NameUserAside";

const AsideUser = ({ division }) => {
   const [userC, setUserC] = useState(undefined);

   const { user } = useContext(AuthContext);

   useEffect(() => {
      getUser();
   }, []);

   const getUser = async () => {
      try {
         const result = await getUsersByIdService(user.user_id);
         setUserC(result.data);
      } catch (error) {
         console.log(error);
      }
   };

   return (
      <>
         <DefaultCard className={"AsideUser_Card"}>
            <div className="AsideUser_UserInfo">
               <NameUserAside firstName={userC && userC.first_name} lastName={userC && userC.last_name}>
               </NameUserAside>
               {/* <QuaternaryTitle className={"AsideUser_h4"}>
                  {userC && userC.username}
               </QuaternaryTitle> */}
               {/* <div className="AsideUser_UserStatus">
                  <Overline className={"AsideUser_pts m-0"}>0 Pts</Overline>
                  <Overline className={" m-0"}>Active</Overline>
               </div> */}
            </div>
            <div className="AsideUser_Membership">
               <TertiarySubtitle className={"AsideUser_TertiarySubtitle"}>
                  {userC && userC[division] && userC[division].name}
               </TertiarySubtitle>
            </div>
         </DefaultCard>
      </>
   );
};

export default AsideUser;
