import axios from "axios";

export const getAllTeamsService = async () => {
  return await axios.get("/cfl/teams/");
};

export const createCFLTeamsService = async (data) => {
  return await axios.post("/cfl/teams/", data);
};

export const updateCFLTeamsService = async (data) => {
  return await axios.put(`/cfl/teams/${data.id}/`, data);
};


export const getAllKFLTeamsService = async () => {
  return await axios.get("/kfl/teams/");
};

export const createKFLTeamsService = async (data) => {
  return await axios.post("/kfl/teams/", data);
}

export const updateKFLTeamsService = async (data) => {
  return await axios.put(`/kfl/teams/${data.id}/`, data);
};
