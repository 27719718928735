import "./Btn_xl.css";

const Btn_xl = ({ children, className, type, onClick }) => {
   return (
      <button className={className} type={type} onClick={onClick}>
         {children}
      </button>
   );
};

export default Btn_xl;
