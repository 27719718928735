import React from "react";
import Button from "../atoms/Btn_xl/Button";

export default function StepThreeNewLeague({ setstepCurrent }) {

  return (
    <>
      <div className="mt-5">
        <div className="header_form_new">
          <div className="step_number">
            <span>3</span>
          </div>
          <div className="step_text">
            <p>– What’s it all about? –</p>
          </div>
        </div>

        <div className="m-5"> 
          <div className="row g-3">
            <div className="col-md-4">
              <div className="card_about">
                <p>How To Play</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card_about">
                <p>Prizes</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card_about">
                <p>CFL Rules</p>
              </div>
            </div>
          </div>
        </div>

        <p className="notes_step">
          We will walk you through the process of placing bets on your favorite
          sports league. Please read each step’s instructions carefully. Here’s
          the essential information so you can start betting. Remember that you
          can always find this information even if you are not logged in or in
          the general dashboard.
        </p>
        <div className="footer_form_new">
          <Button
            onClick={() => {
              setstepCurrent(2);
            }}
          >
            Back «
          </Button>

          <Button
            onClick={() => {
              setstepCurrent(4);
            }}
          >
            Next »
          </Button>
        </div>
      </div>
    </>
  );
}
