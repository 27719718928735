import "./Modal.css";
export default function Modal({ children, show, setShow }) {
  return (
    <>
      {show && (
        <div className="overlay">
          <div className="container_modal">
            <div className="header_modal">
              <div
                onClick={() => {
                  setShow(!show);
                }}
                className="btn_close_modal"
              >
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>
            <div className="modal_body">{children}</div>
          </div>
        </div>
      )}
    </>
  );
}
